import React from "react";
import PropTypes from "prop-types";
import "./HorizontalBar.css";
import { SportsBodyData } from "../../../../theme/helpers";

type Props = {
  chartID: string;
  title: string;
  colors: string[];
  data: SportsBodyData[];
  height: string;
};

const HorizontalBar = (props: Props) => {
  const { chartID, title, data, colors, height } = props;
  const total = data?.reduce((sum, item) => sum + item.value, 0);

  return (
    <div
      className="card p-4 rounded "
      style={{
        backgroundColor: "#EAF3F9",
        width: chartID === "drillstack-bar-3" ? "98%" : "100%",
        height: chartID === "drillstack-bar-3" ? "" : " 300px",
        marginLeft: chartID === "drillstack-bar-3" ? "10px" : "",
      }}
    >
      <div
        className="text-start mb-4 mt-6 "
        style={{ color: "#769495", fontSize: "20px", width: "70%" }}
      >
        <h4>{title}</h4>
      </div>
      {data?.map((item, index) => {
        const percentage = Math.round((item.value / total) * 100);

        return (
          <div className="mb-3 mt-5" key={index}>
            <div className="d-flex text-black align-items-center justify-content-between">
              <span>{item.category}</span>
              {chartID === "drillstack-bar-3" ? (
                <span>
                  No. of Participants ({item.value}){percentage}%
                </span>
              ) : (
                <span>{percentage}%</span>
              )}
            </div>
            <div
              className="progress mt-2"
              style={{ backgroundColor: "#EAF2F2" }}
            >
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${percentage}%`,
                  backgroundColor: colors[index],
                }}
                aria-valuenow={percentage}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalBar;
