/** @format */

import { FC, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import {
	isNotEmpty,
	stringifyRequestQuery,
	toAbsoluteUrl,
} from "../../../../../../theme/helpers";
import clsx from "clsx";
import { createUser, getAllUsersEmail, getUserRoles, updateUser } from "../core/_requests";
import {
	QueryClient,
	useMutation,
	useQuery,
	useQueryClient,
} from "react-query";
import { useListView } from "../core/ListViewProvider";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { FaEye, FaEyeSlash } from "react-icons/fa";


// import { useQueryViewResponseData } from "../core/QueryResponseProvider";

const AddUserModalForm: FC<any> = ({ user, isUserLoading, onCancel }) => {
	console.log(createUser,"hiiiii")

	const {
		isFetching: isFetchingOrg,
		refetch: refetchOrg,
		data: userData,
	} = useQuery(
		[`ORGANIZATION`],
		() => {
			return getAllUsersEmail();
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);

	const find = (array: any[], email: any) => {
		return array.some((el) => {
			if (user) {
				return false
			} else {
				return el === email;
			}
		});
	};

	const editUserSchema = Yup.object().shape({
		role: Yup.string().label("").required("Please select a role"),
		username: Yup.string()
			.trim()
			.required("Name is required")
			.matches(/^([^0-9]*)$/, " Name should not contain any number")
			.min(3, "Enter at least 3 characters")
			.max(60, "Maximum 60 characters allowed"),
		email: Yup.string()
			.email("Invalid Email")
			.required("Email is required")
			.test('Unique Email', 'Email has already been used',
				(value) => {
					return !find(userData, value)
				}
			),
		password: Yup.string()
			.matches(
				/^(?=.*[a-z_A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,15}$/,
				"Password should be between 6 to 15 characters with a mix of letters, numbers & symbols"
			)
			.label("")
			.max(15, "maximum 15 characters allowed")
			.required("Password is required"),
		confirmpassword: Yup.string()
			.required("Confirm your password")
			.oneOf([Yup.ref("password")], "Password must be same"),
	});
	// const [showError, setShowError] = useState(false)
	// const [showErrorMsg, setShowErrorMsg] = useState('')
	const queryClient = useQueryClient();
	const { setItemIdForUpdate } = useListView();
	const { setToastMessage } = useListView();
	// const animatedComponent=makeAnimated();
	const cancel = (withRefresh?: boolean) => {
		setItemIdForUpdate(undefined);
	};
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const [type, settype] = useState("password");

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);
	console.log(user,"hiiii")

	const formik = useFormik({
		initialValues: {
			username: user?.username ? `${user.username}` : "",
			password: user?.password ? `${user.password}` : "",
			confirmpassword: user?.password ? `${user.password}` : "",
			mobile: user?.phone ? `${user.phone}` : "",
			email: user?.email ? `${user.email}` : "",
			role: user?.roles ? `${user?.roles}` : "",
		},
		validationSchema: editUserSchema,
		// validateOnMount:true,
		// validateOnBlur: false,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);

			const temp = {
				username: values.username,
				email: values.email,
				mobile: values.mobile || null,
				password:
					values.password === values.confirmpassword ? values.password : "",
				roles: [values.role],
			};

			try {
				if (user) {
					await updateUser(temp, user?.email);
					setToastMessage("User Updated Successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					queryClient.invalidateQueries([`USER_LIST_VIEW-${query}`]);
				} else {
					const response = await createUser(temp);
					if (response) {
						// setShowError(true)
						// setShowErrorMsg('Email has already been used')
						formik.setErrors({ email: "Email has already been used" });
					} else {
						setToastMessage("User added successfully");
						cancel(true);
					}
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					queryClient.invalidateQueries([`USER_LIST_VIEW-${query}`]);
				}
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				// cancel(true);
			}
		},
	});

	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					{/* <div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Select Role</label>
						<select
							{...formik.getFieldProps("role")}
							aria-label="Select example"
							placeholder="Choose Role"
							name="role"
							id="role"
							className={clsx(
								"form-select form-select-solid",
								{
									"is-invalid": formik.touched.role && formik.errors.role,
								},
								{
									"is-valid": formik.touched.role && !formik.errors.role,
								}
							)}
							disabled
							value="ADMIN"
						>
							<option  value="ADMIN" >
								ADMIN
							</option>
						</select> */}
					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Select Role</label>
						{/* <input
							placeholder="Role"
							{...formik.getFieldProps("role")}
							type="text"
							name="role"
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid": formik.touched.role && formik.errors.role,
								},
								{
									"is-valid": formik.touched.role && !formik.errors.role,
								}
							)}
							autoComplete="off"
							// maxLength={10}
							// disabled
						/> */}
						<select
							// className="form-select form-select-light"

							className={clsx(
								"form-select form-select-light mb-3 mb-lg-0",
								{
									"is-invalid": formik.touched.role && formik.errors.role,
								},
								{
									"is-valid": formik.touched.role && !formik.errors.role,
								}
							)}
							aria-label="Select role"
							{...formik.getFieldProps("role")}
						>
							<option hidden={true} value="">
								Select Role
							</option>
							<option value={"ADMIN"}>ADMIN</option>
							<option value={"VIEWER"}>VIEWER</option>
						</select>
						{formik.touched.role && formik.errors.role && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.role}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Name</label>
						<input
							placeholder="Name"
							{...formik.getFieldProps("username")}
							type="text"
							name="username"
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.username && formik.errors.username,
								},
								{
									"is-valid":
										formik.touched.username && !formik.errors.username,
								}
							)}
							autoComplete="off"

						// maxLength={10}
						/>
						{formik.touched.username && formik.errors.username && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.username}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Email</label>
						<input
							placeholder="Email"
							{...formik.getFieldProps("email")}
							type="email"
							name="email"
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid": formik.touched.email && formik.errors.email,
								},
								{
									"is-valid": formik.touched.email && !formik.errors.email,
								}
							)}
							autoComplete="off"
							disabled={user?.email ? true : false}
						/>

						{/* {showError &&
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{showErrorMsg}</span>
								</div>
							</div>
						} */}
						{formik.touched.email && formik.errors.email && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.email}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Password</label>
						<div className="d-flex flex-row">
							<input
								placeholder="Password"
								{...formik.getFieldProps("password")}
								type={type}
								name="password"
								className={clsx(
									"form-control form-control-light mb-3 mb-lg-0",
									{
										"is-invalid":
											formik.touched.password && formik.errors.password,
									},
									{
										"is-valid":
											formik.touched.password && !formik.errors.password,
									}
								)}
								autoComplete="new-password"
							/>
							{/* <i className="far fa-eye fa-3x"  style={{position:'absolute',right:30,marginTop:15}}></i> */}
							<div
								style={{
									position: "absolute",
									right: 38,
									marginTop: 11,
									cursor: "pointer",
								}}
								onClick={() =>
									type === "text" ? settype("password") : settype("text")
								}
							>
								{type === "password" ? (
									<FaEyeSlash title="Show" size={20} />
								) : (
									<FaEye title="Hide" size={20} />
								)}
							</div>
						</div>
						{formik.touched.password && formik.errors.password && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.password}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">
							Confirm Password
						</label>
						<input
							placeholder="Confirm Password"
							{...formik.getFieldProps("confirmpassword")}
							type="password"
							name="confirmpassword"
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.confirmpassword &&
										formik.errors.confirmpassword,
								},
								{
									"is-valid":
										formik.touched.confirmpassword &&
										!formik.errors.confirmpassword,
								}
							)}
							autoComplete="off"
						/>
						{formik.touched.confirmpassword && formik.errors.confirmpassword && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.confirmpassword}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex justify-content-center">
						<button
							title="Discard"
							type="button"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
						>
							Discard
						</button>

						<button
							title={user ? `Update` : `Submit`}
							type="submit"
							className="btn text-white mx-10 btn-custom"
							data-kt-users-modal-action="submit"
						// style={{ backgroundColor: "#333E58" }}
						>
							<span className="indicator-label">
								{user ? `Update` : `Submit`}
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export { AddUserModalForm };
