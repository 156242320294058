/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { KTCard, KTSVG, toAbsoluteUrl } from "../../../theme/helpers";
import { Link } from "react-router-dom";
import { Dropdown1 } from "../../../theme/partials";
import { useLocation } from "react-router-dom";
import { Button, Col, FloatingLabel, Row } from "react-bootstrap";
import { number, string } from "yup/lib/locale";
import { TablesWidget13 } from "../../../theme/partials/widgets";
import { CustomTable } from "../../../theme/partials/widgets/tables/CustomTable";
import { UserSelectionHeader } from "../apps/user-management/users-list/table/columns/UserSelectionHeader";
import { UserCustomHeader } from "../apps/user-management/users-list/table/columns/UserCustomHeader";
import { UsersTable } from "../apps/user-management/users-list/table/UsersTable";
import {
  QueryResponseProvider,
  useQueryResponsePagination,
} from "../apps/user-management/users-list/core/QueryResponseProvider";
import { UserActionsCell } from "../apps/user-management/users-list/table/columns/UserActionsCell";
import { UserSelectionCell } from "../apps/user-management/users-list/table/columns/UserSelectionCell";
import { ListViewProvider, useListView } from "../apps/user-management/users-list/core/ListViewProvider";
import { QueryRequestProvider } from "../apps/user-management/users-list/core/QueryRequestProvider";
import { UsersListHeader } from "../apps/user-management/users-list/components/header/UsersListHeader";
import { UserEditModal } from "../apps/user-management/users-list/user-edit-modal/UserEditModal";
import { useState } from "react";
import { UserEditModalForm } from "../apps/user-management/users-list/user-edit-modal/UserEditModalForm";
import { DeleteModal } from "../widgets/components/DeleteModal";

type Props = any;

const columns:any = (handleDelete:any, handleAddSubmission:any) => [
  {
    Header: (props: any) => <UserCustomHeader tableProps={props} title="SL" />,
    accessor: "id",
  },
  {
    Header: (props: any) => (
      <UserCustomHeader
        tableProps={props}
        title="Subject"
        className="min-w-125px"
      />
    ),
    accessor: "subject",
  },
  {
    Header: (props: any) => (
      <UserCustomHeader
        tableProps={props}
        title="File Name"
        className="min-w-125px"
      />
    ),
    accessor: "fileName",
  },
  {
    Header: (props: any) => (
      <UserCustomHeader
        tableProps={props}
        title="No of Submissions"
        className="min-w-125px"
      />
    ),
    accessor: "noOfSubmission",
  },
  {
    Header: (props: any) => (
      <UserCustomHeader
        tableProps={props}
        title="Assigned To"
        className="min-w-125px"
      />
    ),
    accessor: "assignedTo",
  },
  {
    Header: (props: any) => (
      <UserCustomHeader
        tableProps={props}
        title="Assigned By"
        className="min-w-125px"
      />
    ),
    accessor: "assignedBy",
  },
  {
    Header: (props: any) => (
      <UserCustomHeader
        tableProps={props}
        title="Uploaded On"
        className="min-w-125px"
      />
    ),
    accessor: "uploadedOn",
  },
  {
    Header: (props: any) => (
      <UserCustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <UserActionsCell
        id={props.data[props.row.index].id}
        onDelete={handleDelete}
        onEdit={handleAddSubmission}
      />
    ),
  },
];

export interface IShowModal{
  showModal: undefined | null
}
const AddSubmission: React.FC<Props> = ({ currentPath }) => {
  const { setItemIdForAddSubmission } = useListView();
  const { setItemIdForDelete, itemIdForDelete, itemIdForUpdate } = useListView();
  const [showModal, setShowModal] = useState<undefined | null>(undefined);
  const location = useLocation();
  const { user }: any = location?.state;
  
  const pagination = {
    page: 1,
    first_page_url: "/?page=1",
    from: 1,
    last_page: 3,
    links: [
      {
        url: null,
        label: "&laquo; Previous",
        active: false,
        page: null,
      },
      {
        url: "/?page=1",
        label: "1",
        active: true,
        page: 1,
      },
      {
        url: "/?page=2",
        label: "2",
        active: false,
        page: 2,
      },
      {
        url: "/?page=3",
        label: "3",
        active: false,
        page: 3,
      },
      {
        url: "/?page=2",
        label: "Next &raquo;",
        active: false,
        page: 2,
      },
    ],
    next_page_url: "/?page=2",
    items_per_page: "10",
    prev_page_url: null,
    to: 10,
    total: 21,
  };
  const handleAddSubmission = () => {
    setShowModal(null)
  };

  const handleDelete = () =>{
    setItemIdForDelete(null)
  }
  const ggcolumns = useMemo(() => columns(handleDelete, handleAddSubmission), []);

  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <KTCard>
              <div className="card-header border-0">
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Submission</h3>
                </div>
                <div className="card-title">
                  <Button onClick={handleAddSubmission}>Add Submission</Button>
                </div>
              </div>
              {/* <UsersListHeader /> */}
              {/* <UsersTable
                columns={ggcolumns}
                users={[
                  {
                    id: "1",
                    subject: "Social",
                    name: "Draft Local",
                    fileName: "xls file",
                    noOfSubmission: "2",
                    assignedTo: "Jon",
                    assignedBy: "Jacob",
                    uploadedOn: "12 Dec",
                  },
                  {
                    id: "2",
                    subject: "Social",
                    name: "Draft Local",
                    fileName: "xls file",
                    noOfSubmission: "2",
                    assignedTo: "Jon",
                    assignedBy: "Jacob",
                    uploadedOn: "12 Dec",
                  },
                  {
                    id: "3",
                    subject: "Social",
                    name: "Draft Local",
                    fileName: "xls file",
                    noOfSubmission: "2",
                    assignedTo: "Jon",
                    assignedBy: "Jacob",
                    uploadedOn: "12 Dec",
                  },
                  {
                    id: "4",
                    subject: "Social",
                    name: "Draft Local",
                    fileName: "xls file",
                    noOfSubmission: "2",
                    assignedTo: "Jon",
                    assignedBy: "Jacob",
                    uploadedOn: "12 Dec",
                  },
                ]}
                isLoading={() => {}}
                onNextPage={() => {}}
                pagination={pagination}
                onDelete={() => setItemIdForDelete(null)}
              /> */}
            </KTCard>
            {(showModal !== undefined || itemIdForUpdate !== undefined) && <UserEditModal modalText="Add Submission" content="add_submission" onCloseEditModal={()=>setShowModal(undefined)}/>}
            {itemIdForDelete !== undefined && <DeleteModal onDelete={handleDelete} onCancel={()=>setItemIdForDelete(undefined)}/>}

          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  );
};

export default AddSubmission;
