import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useThemeMode } from "../../../../theme/partials";
import { getCSSVariableValue } from "../../../../theme/assets/ts/_utils";
import "./HorizontalBar.css";
interface DataPoint {
  x: number;
  y: number;
}
type Props = {
  className: string;
  title: string;
  data;
  extremeParticipantsByPages;
};
const EngagementCluster: React.FC<Props> = ({
  data,
  className,
  extremeParticipantsByPages,
}) => {
  const chartRef = useRef<any>(null);
  const lastData =
    data?.length > 0 ? data[data.length - 1]?.data[0]?.[0] : null;
  useEffect(() => {
    const chart =
      chartRef.current && data && data.length > 0
        ? new ApexCharts(chartRef.current, getChartOptions())
        : null;
    if (chart) {
      chart.render();
      return () => {
        chart.destroy();
      };
    }
  }, [chartRef, data]);

  function getChartOptions(): ApexOptions {
    const markerColors = data.map((point) => point.color);
    // Customize the x-axis options
    const xAxisOptions: ApexXAxis = {
      type: "numeric",
      tickAmount: data.length - 10,
      min: 0,
      offsetY: 10,
      max: lastData + 50,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: true,
        trim: true,
        style: {
          colors: getCSSVariableValue("--bs-gray-500"),
          fontSize: "0px",
        },
      },
    };
    // Customize the y-axis options
    const yAxisOptions = {
      tickAmount: 14,
      trim: true,
      min: 0,
      max: 5 + greatestValue,
      labels: {
        show: true,
        style: {
          colors: getCSSVariableValue("--bs-gray-500"),
          fontSize: "13px",
        },
        offsetY: 0,
      },

      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
      },
      title: {
        text: "Length of Submission",
        style: {
          color: getCSSVariableValue("--bs-dark-500"),
          fontSize: "16px",
          fontWeight: 600,
        },
      },
    };

    // Customize the tooltip
    const tooltipOptions = {
      enabled: true,
      custom: ({ seriesIndex }: any) => {
        const sentimentData = data[seriesIndex];
        const name = sentimentData.name;
        const dataValue = sentimentData.data[0][1];

        return (
          '<div class="apexcharts-tooltip-custom" style="padding: 10px; text-align: left;">' +
          "<strong> Participant Name:</strong> " +
          name +
          "<br>" +
          "<strong>Number of Pages:</strong> " +
          dataValue +
          "</div>"
        );
      },
    };

    // Customize the grid
    const gridOptions = {
      borderColor: getCSSVariableValue("--kt-gray-300"),
      strokeArray: 14, // Use an array for strokeArray
      padding: {
        right: 50,
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    };

    // Create the final options object
    const options: ApexOptions = {
      series: data,
      chart: {
        fontFamily: "inherit",
        height: 500,
        type: "bubble",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bubble: {
          maxBubbleRadius: 10,
          minBubbleRadius: 8,
        },
      },
      stroke: {
        show: false,
        width: 0,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: xAxisOptions,
      yaxis: yAxisOptions,
      tooltip: tooltipOptions,
      colors: markerColors,
      fill: {
        opacity: 1,
      },
      markers: {
        strokeWidth: 2,
        strokeColors: markerColors,
        fillOpacity: 0,
      },
      grid: gridOptions,
      legend: {
        show: false,
      },
      forecastDataPoints: {
        count: 3,
        dashArray: 3,
      },
    };

    return options;
  }

  let greatestValue = data && data.length > 0 ? data[0].data[0][1] : undefined;
  let greatestXValue = data && data.length > 0 ? data[0].data[0][0] : undefined;

  // Iterate through the data array and find the greatest value and corresponding x-value
  data?.forEach((item) => {
    if (item?.data && item.data[0] && item.data[0][1] > greatestValue) {
      greatestValue = item?.data[0][1];
      greatestXValue = item?.data[0][0];
    }
  });
  // ... (previous code)
  const customCardStyle: React.CSSProperties = {
    position: "relative",
    top: -100 + "px",
    left: greatestXValue ? `${greatestXValue}px` : 0,
    backgroundColor: "#fff",
    width: "100px",
    padding: "10px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    zIndex: 100,
  };

  useEffect(() => {
    // After the chart is mounted and rendered, display the custom card
    if (chartRef.current && Array.isArray(data) && data.length >= 1) {
      const customCard = document.getElementById("customCard");
      if (customCard) {
        customCard.style.display = "block";
      }
    }
  }, [customCardStyle, data]);

  return (
    <div className="h-xl-100  card bg-white pt-6 w-100">
      <div className="d-flex align-items-start justify-content-start mt-4 ms-10" style={{ color: "#769495", fontSize: "18px" }}>
       <h4>Engagement Overall</h4> 
      </div>
      {/* Put the div with the three custom cards here */}
      <div
        className="row d-flex align-items-center justify-content-center mt-5"
        style={{ color: "#4E79A7", fontSize: "18px", marginLeft: "150px" }}
      >
        <div className="row">
          {extremeParticipantsByPages?.map((item, index) => (
            <div className="col-md-6 col-lg-4 mb-20" key={index}>
              <div className="custom-card p-3 border rounded">
                <div className="d-flex align-items-center">
                  <div
                    className="circle-icon"
                    style={{ backgroundColor: "red" }}
                  ></div>
                  <div>
                    <p className="">
                      <span className="wrapped-name">{item.name}</span>
                    </p>
                    <p className="text-muted mb-0">{item.value}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="card-body pt-6">
        <div className="tab-content">
          <div
            className="tab-pane fade active show pb-10"
            id="kt_chart_widget_8_month_tab"
            role="tabpanel"
            ref={chartRef}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default EngagementCluster;
