import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const MoreCategoryParticipantTable = (props) => {
  const category = window?.location?.href;
  const a = category.split("x=");
  const word: any = a.pop()
  const newword = word.replace("%20", " ");
  let title = props.title;
  let chartID = props.chartID;
  let data = props.data
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const xValue = queryParams.get("x");

  useEffect(() => {
    // Your chart initialization and rendering code here
    // It will run whenever chartID changes
  }, [chartID]);

  const handleParticipantClick = (participantName) => {
    // Navigate to the detail page with the participant's name as a parameter
    navigate(`/dashboard/${id}/${newword}/${encodeURIComponent(participantName)}`);
  };

  const handleParticipantClick1 = (participantName) => {
    // Navigate to the detail page with the participant's name as a parameter
    navigate(`/dashboard/${id}/${encodeURIComponent(participantName)}`);
  };

  return (
    <div className="card  bg-transparent py-4 px-6 d-flex " style={{ height: "",width:"auto",marginTop:"-35px" }}>
      <div>
        <div className="d-flex align-items-center justify-content-center " style={{ color: "#769495", fontSize: "18px" }}>
          {title}
        </div>
        <div
          className="p-3 pt-4 pb-4"
          style={{
            //background: "linear-gradient(to bottom right, #BBD5D7, #FAFBF8)",
            backgroundSize: "200% 200%",
            backgroundPosition: "0% 0%",
            animation: `gradientAnimation 10s ease infinite`,
            height: "auto",
            marginTop: "21px",
            width:"100%"
          }}
        >
          <div className="row gy-4 gx-xl-12 d-flex align-items-center font-weight-bold">
            <b className="col-xl-3 text-fw-semibold ">Participants</b>
            <b className="col-xl-4 text-fw-semibold ">License category</b>
            <b className="col-xl-2 text-fw-semibold ">Country</b>
            <b className="col-xl-3 text-fw-semibold ">Sector</b>
          </div>
          <hr
            className="d-flex align-items-center"
            style={{
              marginTop: "2px",
              border: "0.5px solid black",
              width: "100%",
            }}
          />

          {data?.map((item, index) => (
            <div className="row gy-4 gx-xl-12 d-flex align-items-center" key={index}>
              {/* Use the handleParticipantClick function to navigate when participant name is clicked */}
              {newword && xValue ? (
                <div
                  className="col-xl-3 text-fw-semibold mb-4 text-hover-primary "
                  onClick={() => handleParticipantClick(item.name)}
                  style={{ cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  <b>{item.name}</b>
                  
                </div>
              ) : (
                <div
                  className="col-xl-3 text-fw-semibold mb-4 text-hover-primary "
                  onClick={() => handleParticipantClick1(item.name)}
                  style={{ cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  <b>{item.name}</b>
                </div>
              )}

              {/* Replace 'licenseCategory', 'country', and 'sector' with your actual data keys */}
              <div className="col-xl-4 fs- mb-4 px-2" style={{ color: "#909495", cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.licenseCategory}
              </div>
              <div className="col-xl-2 fs-7 mb-4 px-2" style={{ color: "#909495", cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.country}
              </div>
              <div className="col-xl-3 fs-7 mb-4 px-2" style={{ color: "#909495", cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.sector}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreCategoryParticipantTable;
