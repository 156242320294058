import { type } from "os";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { ChartData } from "../../../../theme/helpers";
import "./TreeMap.css";
type props={
  title:string;
  chartID:string;
  data:ChartData[]
}

const GradientTable = (props:props) => {
  const category = window?.location?.href;
  const a = category.split("x=");
  const word:any= a.pop()
  const newword = word.replace("%20", " ")
  let title = props.title;
  let chartID = props.chartID;
  let data = props.data?.slice(0, 10);
  const navigate = useNavigate();
  const { id } = useParams()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const xValue = queryParams.get("x");
  useEffect(() => {
    // Your chart initialization and rendering code here
    // It will run whenever chartID changes
  }, [chartID]);
  const handleParticipantClick = (participantName, category) => {
    // Navigate to the detail page with the participant's name as a parameter
    navigate(`/dashboard/${id}/${newword}/${encodeURIComponent(participantName)}`);
  };
  const handleParticipantClick1 = (participantName) => {
    // Navigate to the detail page with the participant's name as a parameter
    navigate(`/dashboard/${id}/${encodeURIComponent(participantName)}`);
  };



  return (
    <div className="card   py-4  d-flex " style={{ height: "auto",backgroundColor:"#004053" }}>
      <div>
        <div className="d-flex   ms-3 " style={{ color: "#769495", fontSize: "" }}>
          <h4 className="text-white fs-6">{title}</h4>
        </div>
        <div
          className="p-3 pt-4"
          style={{
            background: "#004053",
            backgroundSize: "200% 200%",
            backgroundPosition: "0% 0%",
            animation: `gradientAnimation 10s ease infinite`,
            marginTop: "14px",
            minHeight: "370px",
          }}
        >
          <div className="row text-white gy-4 gx-xl-12 d-flex align-items-center font-weight-bold">
            <b className="col-xl-6 text-fw-semibold mb-4">Participants</b>
            <b className="col-xl-6 text-fw-semibold mb-4">Category</b>
          </div>
          <hr
            className="d-flex align-items-center"
            style={{
              marginTop: "2px",
              border: "0.5px solid white",
              width: "100%",
            }}
          />

          {data?.map((item, index) => (
            <div className="row text-white gy-4 gx-xl-12 d-flex align-items-center" key={index}>
              {/* Use the handleParticipantClick function to navigate when participant name is clicked */}
              {newword && xValue ? (<div
                className="  text-hover-primary mb-3"
                onClick={() => handleParticipantClick(item.name, category.includes(newword))}
                style={{
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "12px"
                }}
              >
                <b>{item.name}</b>
              </div>) : <div
                className="col-xl-6  text-hover-white mb-3"
                onClick={() => handleParticipantClick1(item.name)}
                style={{
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "12px",
                  color: "#F8F770",
              
                  
                }}
              >
                <b>{item.name}</b>
              </div>}

              <div className="col-xl-6 mb-3 "
                style={{ color: "white" }}>
                {item.generalCategory}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GradientTable;
