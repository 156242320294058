/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import React, { useState, useEffect, useMemo } from "react";
import { useLocation} from "react-router-dom";
import {
  AddParticipantModal,
  getResponseHeadingsList,
  processResponse,
} from "../apps/user-management/users-list/core/_requests";
import { Toast } from "../apps/user-management/users-list/Toast";
import { IoMdArrowDropdown } from "react-icons/io";

import { QueryResponseHeadingsProvider, useQueryResponseHeading, useQueryResponseHeadingData, useQueryResponseHeadingParticipantData } from "../apps/user-management/users-list/core/QueryResponseProvider";
import { UserEditModal } from "../apps/user-management/users-list/user-edit-modal/UserEditModal";
import { ListViewProvider, useListView } from "../apps/user-management/users-list/core/ListViewProvider";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { QueryRequestProvider, useQueryRequest } from "../apps/user-management/users-list/core/QueryRequestProvider";
import { responseData } from "../../../theme/helpers";


type Props = {
response:responseData,
query:string,
responseId:string};

const ResponseDetails: React.FC<Props> = ({ response, query }) => {
  const [loading, setLoading] = useState(false);
  const { refetch } = useQueryResponseHeading();
  const queryClient = useQueryClient();
  const location = useLocation();
  const responseData:any= location?.state;
  const  response1 = useMemo(() => response, []);
  const [loader, setLoader] = useState(false);
  const [annotate, setAnnotate] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedValue, setSelectedValue] = useState<any>("");
  const [resdata,setResdata]=useState<any>()
  const [clicked, setClicked] = useState(false);
  const participant1 = useQueryResponseHeadingParticipantData();
  const [participant, setParticipant] = useState(0)
  const participantname = response?.participant || [];
  const {
    setItemIdForUpdate,
    itemIdForUpdate,
    toastMessage,
  } = useListView();
  const handleValueSelect = (value) => {
    // event.preventDefault
    setAnnotate(true);
    setSelectedValue(value);
    // setAnnotate(false);
  };
  const openAddUserModal = () => {
    setItemIdForUpdate(response);

  };
  
  const temp:any={participantName:selectedValue,}
  const handleSave = async () => {
    // setItemIdForUpdate(response);
   const result = await AddParticipantModal(temp,respid);
   console.log(result,"nnnnnnnnnnnnnn")
    // cancel(true);

    setClicked(true);
    
  };
  const handleProcessResponse = async (
    responseId: string | undefined,
    query: string,
    action: string
  ) => {
    setLoader(true);
    setMessage("");

    try {
      const result = await processResponse(responseData?.responseId, responseData?.query, responseData?.action);
      if (result !== null) {
        setMessage("processed successfully");
        refetch()
        await queryClient.invalidateQueries([`GETRESPONSEHEADINGlIST-${query}`])
        setAnnotate(true);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      } else {
        setMessage("Sorry document could not be processed");
        setAnnotate(false);

        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    } catch (error) {
      console.log(`An error occurred while processing file ${responseId}.`, error);
      setMessage("Sorry, document could not be processed");
      setTimeout(() => {
        setMessage("");
      }, 3000);
      setAnnotate(false);
    } finally {
      setLoader(false);
      setLoading(true);
    }
  };

  useEffect(() => {
  }, [annotate] || [loader]);
  const { id } = useParams();
  const{respid}=useParams();
  interface ResponseData {
    data: {
      participants: string[];
      sentiments: {
        sectionHead: string;
        sentiment: string;
      }[];
    };
    payload: {
      pagination: {
        page: number;
        from: number;
        to: number;
        total: number;
        links: {
          url: string | null;
          label: string;
          active: boolean;
          page: number | null;
        }[];
        last_page: number;
        first_page_url: string;
        next_page_url: string | null;
        prev_page_url: string | null;
        items_per_page: string;
      };
    };
  }

  const {
    isLoading,
    isFetching,
    data: resp,
  } = useQuery<ResponseData>(
    [`GETRESPONSEHEADINGlIST-${query}`],
    async () => {
      const response = await getResponseHeadingsList(respid, query, "COMPLETE");
      return response as unknown as ResponseData;
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
  console.log(resp,"vvvvvvvv")

  useEffect(() => {
    const count: number | any = resp?.data?.participants?.length ?? 0;

    setParticipant(count);
  }, [resp, participant]);
  useEffect(() => {
    queryClient.invalidateQueries([`response-list-${query}`]);
  }, [response, refetch]);
  return (
    <>
      {message !== "" && ((annotate) ? (
        <div style={{ position: "absolute", top: 0, width: "100%", textAlign: "center" }}>
          <div style={{ display: "inline-block", marginTop: "20px" }}>
            <Toast title={message} color="alert-success" />
          </div>
        </div>
      ) : (<div style={{ position: "absolute", top: 0, width: "100%", textAlign: "center" }}>
        <div style={{ display: "inline-block", marginTop: "20px" }}>
          <Toast title={message} color="alert-danger" />
        </div>
      </div>))}
      <div className="card mb-5 mb-xl-10 mt-0" id="kt_profile_details_view">
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bold m-0">Response Details</h3>
          </div>
          {
            <div className="card-title me-0">
              {loader ? (
                <button
                  title={annotate ? "Processed" : "Process to AI/ML"}
                  className={`btn ${response?.status !== "ACTIVE" ? "btn-success" : "btn-custom"
                    } text-white ${annotate ? "btn-success" : "btn-custom"}`}
                  style={{ backgroundColor: "#333E58" }}
                  disabled={
                    loading || response?.status === "COMPLETE" || annotate
                  }
                >
                  Processing...
                </button>
              ) : (
                <button
                  title={annotate ? "Processed" : "Process to AI/ML"}
                  className={`btn ${response?.status !== "ACTIVE" ? "btn-success" : "btn-custom"
                    } text-white ${annotate ? "btn-success" : "btn-custom"}`}
                  onClick={() =>
                    handleProcessResponse(responseData?.draftId, responseData?.query, responseData?.action)
                  }
                  disabled={
                    response?.status === "COMPLETE" || annotate
                  }
                >
                  {response?.status === "COMPLETE" || annotate
                    ? "Processed"
                    : "Process to AI/ML"}
                </button>
              )}
            </div>
          }
        </div>

        <div className="card-body  mt-0">
          <div className="row mb-7">
            <div className="col-12 col-sm-12">
              <div className="row">
                <label className="col-lg-2 fw-bold text-black h5">
                  Response Name
                </label>
                <div className="col-lg-10 fv-row">
                  <span className="h8">{ response1?.fileName ||response?.fileName || "N/A"}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-12 col-sm-6">
              <div className="row">
                <label className="col-lg-4 fw-bold text-black h5">
                  Uploaded By
                </label>
                <div className="col-lg-8">
                  <span className="h8">{response1?.uploadedBy || response?.uploadedBy|| "N/A"}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="row">
                <label className="col-lg-4 fw-bold text-black h5">
                  Uploaded On
                </label>
                <div className="col-lg-8">
                  <span className="h8">{response1?.uploadedOn ||response?.uploadedOn|| "N/A"}</span>
                </div>
              </div>
            </div>
          </div>

          {participant1?.length>=0 &&  <div className="row gx-xl-12 d-flex">
            <div className=" col-sm-6 ">
              <div className="row d-flex align-items-center">
                <div className=" col-sm-4 ">
                  <label className="fw-bold text-black h5">Participants</label>
                </div>
                {clicked ? (
                  <div className="col-lg-8">
                    <span className="h8">{selectedValue}</span>
                  </div>
                ) : (
                  <div className="col-sm-8 d-flex align-items-center" style={{ marginLeft: "-5px" }}>
                    {(participant1?.length || participant) > 1 ? (
                      <>

                        <div
                          className="dropdown"
                          id={`add`}
                          style={{
                            width: "150px",
                            textAlign: "left",
                            fontSize: "13px",
                            border: "1px solid black",
                            borderRadius: "5px",
                            marginLeft: "5px",
                            height: "30px",
                          }}
                        >
                          <div
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              width: "100%",
                              textAlign: "left",
                              fontSize: "13px",
                              display: "flex",
                              alignItems: "center",
                              padding: "5px",
                            }}
                          >
                            <div style={{}}>
                              {selectedValue || "Select"}
                              <IoMdArrowDropdown
                                style={{
                                  marginLeft: "80px",
                                  color: "#5f6368",
                                }}
                              />
                            </div>
                          </div>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby={`start`}
                            style={{
                              width: "100%",
                              minWidth: "unset",
                            }}
                          >
                            {participant1?.map((item, index) => (
                              <a
                                className="dropdown-item"
                                key={index}
                                href="#"
                                onClick={() => handleValueSelect(item)}
                              >
                                {item}
                              </a>
                            ))}
                          </ul>
                        </div>
                        <div className="col-sm-5 px-3">
                          <button
                            type="button"
                            style={{
                              fontSize: "14px",
                              color: "white",
                              backgroundColor: "#1E1E2D",
                            }}
                            className="nav-link btn btn-sm btn-active fw-bold px-4 me-1"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) :(participant1?.length === 1 )? (
                      <span className="h8">{participant1[0] || participantname[0] ||  "NA"}</span>
                    ) : (

                      <button
                        type="button"
                        style={{
                          fontSize: "14px",
                          color: "white",
                          backgroundColor: "#1E1E2D",
                        }}
                        className="nav-link btn btn-sm btn-active fw-bold px-4 me-1 d-flex align-items-center"
                        onClick={openAddUserModal}
                      >
                        Add Participant
                      </button>
                    )}
                  </div>
                )}


                {itemIdForUpdate !== undefined && (
                  <UserEditModal
                    content="add_participant"
                    modalText={
                      itemIdForUpdate !== undefined ? "Add Participant" : "Edit Participant"
                    }
                    user={itemIdForUpdate}
                    onCloseEditModal={() => setItemIdForUpdate(undefined)}
                  />
                )}
                {toastMessage !== undefined && <Toast title={toastMessage} color="alert-success" />}
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
};
const ResponsedetailInfoWrapper: React.FC<Props> = ({ response, responseId, query }) => (
  <QueryRequestProvider>
    <QueryResponseHeadingsProvider>
      <ListViewProvider>
        <ResponseDetails response={response} responseId={responseId} query={query} />
      </ListViewProvider>
    </QueryResponseHeadingsProvider>
  </QueryRequestProvider>

);
export default ResponsedetailInfoWrapper;
