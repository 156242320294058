import React from 'react';
import background from "../../../../assets/NeithRegistration.jpg";
import neithlogo from "../../../../assets/neith.png";
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../core/Auth';

const WelcomeComponent = () => {
    const navigate = useNavigate();
    const {accept,setAccept}=useAuth()
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        minHeight: "100vh",
        backgroundImage: `url(${background})`,
        justifyContent: "center",
        alignItems: "center",
        color: "white"
      }}
    ><div style={{position:"absolute",top:20,left:20,}}>
    <img
    src={neithlogo}
    height="120px"
    width="230px"
    alt="logo"
    loading="lazy"
    
  />
  </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', textAlign: 'start', whiteSpace: 'pre-wrap', height: '100vh', marginTop: "-40px", color: 'white'}}>
        <h1>
          <div className='text-white'style={{width: '750px'}}><span style={{ color: '#BF36FF' }}>Congratulations!</span> You have been nominated by Neith to participate<br />
            in early stage testing and evaluation of its pilot service. <br />
            {/* <span style={{ marginRight: '150px' }}>of its pilot service.</span> */}
            </div>
        </h1>
        <p style={{ width: '600px', marginTop: "22px", fontSize: "14px",color: "#95BEC1", }}>Please read and accept the pilot terms and conditions <a onClick={()=>{navigate("/terms")}}   className='' style={{color:"#D3FD35",cursor:"pointer"}}>here</a>, then register or sign-in below:</p>
        <div className='gap-5' style={{ display: 'flex', marginTop: '20px' }}>
          <button className="btn rounded-pill"
           style={{ backgroundColor: "#D3FD35", color: "#31684D" }} 
           disabled={accept}
           onClick={()=>navigate("/register")}>Register</button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeComponent;
