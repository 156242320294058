import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { getUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { getId } from "../../apps/user-management/users-list/core/_requests";
import './Login.css'

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.label("")
		.email("Invalid Email")
		.min(3, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Email is required"),
	password: Yup.string()
		.max(50, "Maximum 50 symbols")
		.required("Password is required"),
});


const initialValues = {
	email: "",
	password: "",
};

export function Login() {
	const [isHovered, setIsHovered] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { saveAuth, setCurrentUser, logout } = useAuth();
	const [type, settype] = useState("password");
	const [documents, setDocuments] = useState<any>([]);
	const [selectedDocument, setSelectedDocument] = useState<any>(null);
	const { username, setUsername } = useAuth();
	const { userPassword, setUserPassword } = useAuth();
	const { show, setShow } = useAuth()
	console.log(show,"show")
	useEffect(() => {
		const fetchData = async () => {
			try {
				const draft = await getId();
				setDocuments(draft);

				// const selectedDoc = documents.find((doc) => doc.documentId === selectedDocId);
				// Do something with the 'draft' value here

				setSelectedDocument(selectedDocument);

				// ... rest of your code ...
			} catch (error) {
				console.error('Error:', error);
			}
		};

		fetchData(); // Call the async function inside useEffect

		// If you want to perform any cleanup, you can return a function
		// The returned function will be called when the component unmounts
		return () => {
			// Perform cleanup if needed
		};
	}, []);


	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			try {
				let logoutTime: string | any = process.env.REACT_APP_LOGOUT_TIME;
				const res = await login(values.email, values.password);
				const showDropdown = res?.data?.data?.showDropdown
					;
				setShow(showDropdown)
				const draft = await getId();
				console.log(draft, "okkkkk")
				setDocuments(draft);
				setUsername(values.email);
				setUserPassword(values.password);

				const auth = {
					...res?.data.data,

				};


				{
					!(showDropdown) &&
						saveAuth(auth);
				}
				const { data: user } = await getUserByToken(auth.api_token);
				{ !(showDropdown) && setCurrentUser(auth); }

				setTimeout(() => {
					logout();
				}, parseInt(logoutTime));
			} catch (error: any) {
				saveAuth(undefined);

				let statusFunc = () => {
					if (error.response.status === 403) {
						return `${error.response.data.data}` || "Invalid Credentials";
					} else if (error.response.status === 404) {
						return `${error.response.data.data}` || "User does not exist!";
					}
				};

				setStatus(statusFunc());
				setSubmitting(false);
				setLoading(false);
			}
		},
	});
	const handleDocumentChange = async (selectedDocId) => {
		// const selectedDocId = event.target.value;
		const selectedDoc = documents.find((doc) => doc.documentId === selectedDocId);
		const res = await login(username, userPassword);
		const auth = {
			...res?.data.data,
			documentId: selectedDocId,
		};
		console.log(auth, "bbbbbbbbb")
		saveAuth(auth);
		
		setSelectedDocument(selectedDoc);
		const { data: user } = await getUserByToken(auth.api_token);
		setCurrentUser(auth);
		setUsername(username);
		setUserPassword(userPassword);
	}





	return (
		<>
			{show ? (
				<div className="fv-row mt-10">
					<label className="form-label fs-6 fw-bolder mb-4" style={{ color: "#95BEC1" }}>
						Please Select a Process:
					</label>

					{documents?.some(doc => doc.documentName.includes("Bill")) && (
						<><h4 style={{ color: "#95BEC1" }}>BILLS:</h4><div style={{ border: '1px solid #ccc', marginBottom: '10px' }}>

							<table style={{ width: '600px', borderCollapse: 'collapse' }}>
								<thead>
									<tr className="" style={{backgroundColor:"#93BDC1"}}>
										<th style={{ padding: '12px', textAlign: 'left', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>DOCUMENT NAME</th>
										<th style={{ padding: '12px', textAlign: 'left', borderBottom: '1px solid #ccc', }}>ISSUING AUTHORITY</th>
									</tr>
								</thead>
								<tbody>
									{documents
										.filter(doc => doc.documentName.includes("Bill"))
										.map(doc => (
											<tr


												style={{
													borderBottom: '1px solid #ccc',
													transition: 'background-color 0.3s',
													userSelect: 'none',
												}}
											>
												<td key={doc.documentId} 
												onClick={() => handleDocumentChange(doc.documentId)} 
												className="text-hover" style={{
													padding: '12px',
													color: 'white',
													WebkitBoxOrient: 'vertical',
													overflow: 'hidden',
													WebkitLineClamp: 2,
													maxWidth: '300px',
													textAlign: 'left',
													borderRight: '1px solid #ccc'
												}}>
													{doc.documentName}
												</td>
												<td style={{
													padding: '12px',
													color: 'white',
													WebkitBoxOrient: 'vertical',
													overflow: 'hidden',
													WebkitLineClamp: 2,
													maxWidth: '200px',
													textAlign: 'left',
												}}>{doc.nameOfIssuingAuthority}</td>
											</tr>
										))}
								</tbody>
							</table>
						</div></>
					)}

					{documents?.some(doc => doc.documentName.includes("Regulations")) && (
						<><h4 style={{ color: "#95BEC1", marginTop: '30px' }}>REGULATIONS:</h4><div style={{ border: '1px solid #ccc' }}>

							<table style={{ width: '100%', borderCollapse: 'collapse' }}>
								<thead>
									<tr style={{backgroundColor:"#93BDC1"}}>
										<th style={{ padding: '12px', textAlign: 'left', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc' }}>DOCUMENT NAME</th>
										<th style={{ padding: '12px', textAlign: 'left', borderBottom: '1px solid #ccc', }}>ISSUING AUTHORITY</th>
									</tr>
								</thead>
								<tbody>
									{documents
										.filter(doc => doc.documentName.includes("Regulations"))
										.map(doc => (
											<tr
												
												style={{
													borderBottom: '1px solid #ccc',
													transition: 'background-color 0.3s',
													userSelect: 'none',
												}}
											>
												<td 
												key={doc.documentId} 
												onClick={() => handleDocumentChange(doc.documentId)} 
												className="text-hover" style={{
													padding: '12px',
													color: 'white',
													WebkitBoxOrient: 'vertical',
													overflow: 'hidden',
													WebkitLineClamp: 2,
													maxWidth: '235px',
													textAlign: 'left',
													borderRight: '1px solid #ccc'
												}}>
													{doc.documentName}
												</td>
												<td style={{
													padding: '12px',
													color: 'white',
													WebkitBoxOrient: 'vertical',
													overflow: 'hidden',
													WebkitLineClamp: 2,
													maxWidth: '200px',
													textAlign: 'left',
												}}>{doc.nameOfIssuingAuthority}</td>
											</tr>
										))}
								</tbody>
							</table>
						</div></>
					)}
				</div>





			) : (
				(<div className=" p-10 cardSize">
					<form
						className="form w-100"
						onSubmit={formik.handleSubmit}
						noValidate
						id="kt_login_signin_form"
					>

						<div className="text-start mb-5">
							<h1 className=" fw-bolder mb-1" style={{ color: "#95BEC1" }}>Sign-In</h1>

						</div>

						{formik.status ? (
							<div className="mb-lg-11 alert alert-danger text-center">
								<div className="alert-text font-weight-bold">{formik.status}</div>
							</div>
						) : null}

						{/* begin::Form group */}
						<div className="fv-row mb-8">
							<label className="form-label fs-6 fw-bolder " style={{ color: "#95BEC1" }}>Email:</label>
							<input style={{ width: "300px" }}
								placeholder="Email"
								{...formik.getFieldProps("email")}
								className={clsx(
									"form-control form-control-light mb-3 mb-lg-0",
									{
										"is-invalid": formik.touched.email && formik.errors.email,
									},
									{
										"is-valid": formik.touched.email && !formik.errors.email,
									}
								)}
								type="email"
								name="email"
								autoComplete="off" />
							{formik.touched.email && formik.errors.email && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{formik.errors.email}</span>
									</div>
								</div>
							)}
						</div>
						{/* end::Form group */}

						{/* begin::Form group */}
						<div className="fv-row mb-3" style={{ position: 'relative' }}>
							<label className="form-label fw-bolder  fs-6 mb-2" style={{ color: "#95BEC1" }}>
								Password:
							</label>
							<input
								style={{ width: "300px" }}
								placeholder="Password"
								type={type}
								autoComplete="off"
								{...formik.getFieldProps("password")}
								className={clsx(
									"form-control form-control-light mb-3 mb-lg-0",
									{
										"is-invalid": formik.touched.password && formik.errors.password,
									},
									{
										"is-valid": formik.touched.password && !formik.errors.password,
									}
								)} />
							<div
								style={{
									position: "absolute",
									right: 0,
									marginTop: 0,
									top: 39,
									// left:20,
									cursor: 'pointer',
								}}
								onClick={() => type == "text" ? settype("password") : settype("text")}
							>
								{type == "password" ? (
									<FaEyeSlash size={20} title='Show' />
								) : (
									<FaEye size={20} title="Hide" />
								)}
							</div>
							{formik.touched.password && formik.errors.password && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{formik.errors.password}</span>
									</div>
								</div>
							)}
						</div>
						{/* end::Form group */}

						{/* begin::Wrapper */}
						{/* <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
        <div />

        
        <Link to="/auth/forgot-password" className="link-dark link-hover" title="Forgot Password?">
            Forgot Password?
        </Link>
        
    </div> */}
						{/* end::Wrapper */}

						{/* begin::Action */}
						<div className="d-grid mb-2 mt-14">
							<button
								title="Sign In"
								type="submit"
								id="kt_sign_in_submit"
								className="btn   rounded-pill"
								style={{ backgroundColor: "#D3FD35", color: "#31684D", width: "50%" }}
								disabled={formik.isSubmitting || !formik.isValid}
							>
								{!loading && <span className="indicator-label">Sign In</span>}
								{loading && (
									<span className="indicator-progress" style={{ display: "block" }}>
										Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2 "></span>
									</span>
								)}
							</button>
						</div>
					</form>
				</div>)
			)}
		</>
	);
}
