import { FC, useState, useMemo, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";


import clsx from "clsx";
import {
  AddParticipantModal,
} from "../core/_requests";
import { useListView } from "../core/ListViewProvider";
import { useQueryClient } from "react-query";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { Participant, stringifyRequestQuery } from "../../../../../../theme/helpers";
import { useParams } from "react-router-dom";
import { useQueryResponse, useQueryResponseHeading, useSourceQueryResponse } from "../core/QueryResponseProvider";

const editUserSchema = Yup.object().shape({
  participantName: Yup.string()
    .trim()
    .required("Name is required")
    .min(3, "Enter at least 3 characters")
    .max(60, "Maximum 60 characters allowed")
    .matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),
  address: Yup.string().trim().required("Address is required").min(3, "Enter at least 3 characters")
    .max(60, "Maximum 60 characters allowed")
    .matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),
  country: Yup.string().trim().required("Country is required").min(3, "Enter at least 3 characters")
    .max(60, "Maximum 60 characters allowed")
    .matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),
  sector: Yup.string().trim().required("Sector is required").min(3, "Enter at least 3 characters")
    .max(60, "Maximum 60 characters allowed")
    .matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),
  genCat: Yup.string().trim().required("General Category is required"),
  license: Yup.string().trim().required("Licensing Category is required"),
});

const AddParticipantModalForm: FC<any> = ({
  user,
  isUserLoading,
  onCancel,
}) => {
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const queryClient = useQueryClient();
  const { setItemIdForUpdate, setToastMessage } = useListView();
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const { id } = useParams();


  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate(undefined);
  };

  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);
  const { testId } = useParams();
  const { refetch } = useQueryResponseHeading();

  const formik = useFormik({
    initialValues: {
      participantName: user?.participantName || "",
      address: user?.address || "",
      country: user?.country || "",
      sector: user?.sector || "",
      genCat: user?.genCat || "",
      license: user?.license || "",
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const temp:Participant = {
        participantName: values.participantName,
        address: values.address,
        country: values.country,
        sector: values.sector,
        regNo: "67",
        date: "27/08/2023",
        title: "esports law",
        categoryGeneral: values.genCat,
        licensingCategory: values.license,
        supportingDocuments: "passport",
        natureOfService: "natureOfService",
      };


      try {
        if (user) {
          await AddParticipantModal(temp, user?.responseId);
          setToastMessage("Participant added Successfully");

          await refetch()
          queryClient.invalidateQueries(`GETRESPONSEHEADINGlIST-${query}`);
          cancel(true);
          setTimeout(() => {
            setToastMessage(undefined);
          }, 2000);

        } else {
          await AddParticipantModal(temp);
          setToastMessage("Participant Created Successfully");
          cancel(true);
          setTimeout(() => {
            setToastMessage(undefined);
          }, 2000);
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
  } = formik;

  return (
    <form className="form-horizontal fv-row mb-3 " style={{ marginTop: "-20px" }} onSubmit={handleSubmit}>
      <div className="modal-body ">
        <div className="form-group fw-bold fs-6 mb-7">
          <label htmlFor="participantName " className="required fw-bold fs-6 mb-2">Name of Participant</label>
          <input
            placeholder="Name of Participant"
            type="text"
            id="participantName"
            name="participantName"
            value={values.participantName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={clsx("form-control form-control-light mb-7 mb-lg-0", {
              "is-invalid":
                touched.participantName && errors.participantName,
            })}
            autoComplete="off"
          />
          {touched.participantName && errors.participantName && (
            <div className="invalid-feedback">
              {String(errors.participantName)}
            </div>
          )}
        </div>

        <div className="form-group fw-bold fs-6 mb-7 ">
          <label htmlFor="address" className="required fw-bold fs-6 mb-2">Address</label>
          <input
            placeholder="Address"
            type="text"
            id="address"
            name="address"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            className={clsx("form-control", {
              "is-invalid": touched.address && errors.address,
            })}
            autoComplete="off"
          />
          {touched.address && errors.address && (
            <div className="invalid-feedback">{String(errors.address)}</div>
          )}
        </div>

        <div className="form-group fw-bold fs-6 mb-7">
          <label htmlFor="country" className="required fw-bold fs-6 mb-2">Country</label>
          <input
            placeholder="Country"
            type="text"
            id="country"
            name="country"
            value={values.country}
            onChange={handleChange}
            onBlur={handleBlur}
            className={clsx("form-control", {
              "is-invalid": touched.country && errors.country,
            })}
            autoComplete="off"
          />
          {touched.country && errors.country && (
            <div className="invalid-feedback">{String(errors.country)}</div>
          )}
        </div>

        <div className="form-group fw-bold fs-6 mb-7">
          <label htmlFor="sector" className="required fw-bold fs-6 mb-2">Sector</label>
          <input
            placeholder="Sector"
            type="text"
            id="sector"
            name="sector"
            value={values.sector}
            onChange={handleChange}
            onBlur={handleBlur}
            className={clsx("form-control", {
              "is-invalid": touched.sector && errors.sector,
            })}
            autoComplete="off"
          />
          {touched.sector && errors.sector && (
            <div className="invalid-feedback">{String(errors.sector)}</div>
          )}
        </div>

        <div className="form-group fw-bold fs-6 mb-7">
          <label htmlFor="genCat" className="required fw-bold fs-6 mb-2">General Category</label>
          <select
            id="genCat"
            name="genCat"
            value={values.genCat}
            onChange={handleChange}
            onBlur={handleBlur}
            className={clsx("form-select form-select-light mb-3 mb-lg-0", {
              "is-invalid": touched.genCat && errors.genCat,
            })}
            autoComplete="off"

          >
            <option hidden={true} value="">General category</option>
            <option value="Private Company">Private Company</option>
            <option value="Private Individual">Private Individual</option>
            <option value="Academic Institution">Academic Institution</option>
            <option value="Government Department">Government Department</option>
            <option value="Regulatory Authority">Regulatory Authority</option>
            <option value="Civil Society Organisation">Civil Society Organisation</option>
            <option value="Industry Association">Industry Association</option>
          </select>
          {touched.genCat && errors.genCat && (
            <div className="invalid-feedback">{String(errors.genCat)}</div>
          )}
        </div>

        <div className="form-group fw-bold fs-6 mb-7 ">
          <label htmlFor="license " className="required fw-bold fs-6 mb-2">Licensing Category</label>
          <select
            id="license"
            name="license"
            value={values.license}
            onChange={handleChange}
            onBlur={handleBlur}
            className={clsx("form-select form-select-light mb-3 mb-lg-0", {
              "is-invalid": touched.license && errors.license,
            })}
            autoComplete="off"
          >
            <option hidden={true} value="">Licensing category</option>
            <option value="Broadcasting">Broadcasting</option>
            <option value="Individual ECS and ECNS">Individual ECS and ECNS</option>
            <option value="Class ECS">Class ECS</option>
            <option value="Class ECNS">Class ECNS</option>
            <option value="Not licensed by issuing authority">Not licensed by issuing authority</option>
          </select>
          {touched.license && errors.license && (
            <div className="invalid-feedback">{String(errors.license)}</div>
          )}
        </div>

      </div>

      <div className=" d-flex justify-content-center align-item-center ">
        <button
          type="button"
          title="Discard"
          className="btn btn-secondary mx-10"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Discard
        </button>
        <button
							title="Add"
							ref={btnRef}
							type="submit"
							className="btn me-10 text-white btn-custom"
							id="kt_button_1"
							data-kt-users-modal-action="submit"
              style={{width:"92px"}}
						>
							<span className="indicator-label">Add</span>
							<span className="indicator-progress">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
      </div>
    </form>
  );
};

export default AddParticipantModalForm;

