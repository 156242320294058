import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getStaticdata, tagSource } from "./users-list/core/_requests";
import { processSource } from "./users-list/core/_requests";
import { ListViewProvider, } from "./users-list/core/ListViewProvider";
import { Toast } from "./users-list/Toast";
import { QuerySectionHeadingsProvider, useQueryHeading, useQueryHeadingData } from "./users-list/core/QueryResponseProvider";
import { useQuery, useQueryClient } from "react-query";
import { QueryRequestProvider } from "./users-list/core/QueryRequestProvider";
import { FaQrcode, FaTag } from "react-icons/fa";
import { Draft } from "./users-list/core/_models";
import { useAuth } from "../../auth";

const ResponseSourceInfo = () => {
  const {draft,setDraft}=useAuth()
  const queryClient = useQueryClient();
  const location = useLocation();
  const results = useQueryHeadingData();
  const draftData:Draft | any = location?.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [annotate, setAnnotate] = useState(false);
  const [color, setColor] = useState('');
  const { refetch } = useQueryHeading();

  const handleProcessSource = async (draftId, query, action) => {
    setLoader(true);
    setMessage("");

    try {
      const result = await processSource(draftId, query, action);
      if (result !== null) {
        setMessage("Processed successfully");
        refetch();
        await queryClient.invalidateQueries([`GETHEADINGlIST-${query}`]);
        setAnnotate(true);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      } else if (draftData?.status !== "ACTIVE") {
        setMessage("Already processed");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      } else {
        setMessage("Sorry, document could not be processed");
        setAnnotate(false);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    } catch (error) {
      console.error(`An error occurred while processing file ${draftId}.`, error);
      setMessage("Sorry, the document could not be processed");
      setTimeout(() => {
        setMessage("");
      }, 3000);
      setAnnotate(false);
      setLoader(false);
    } finally {
      setLoader(false);
      setLoading(true);
    }
  };

  const { data: tagdata } = useQuery(
    [`page`],
    () => {
      return tagSource(draftData.draftId);
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: true }
  );

  const handleNavigate = async () => {
    setColor("alert-warning");
    setMessage("Checking Dashboard Eligibility...");
    setAnnotate(false);

    try {
      const newData = await getStaticdata(draftData.draftId, null, null);
      if (newData && newData.code && newData.code === 5027) {
        setColor("alert-danger");
        setMessage('Please process and fill all the participants');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      } else {
        setColor("alert-success");
        setAnnotate(true);
        setMessage('Checked ✓ Welcome');
        setTimeout(() => {
          setMessage('');
          navigate(`/dashboard/${draftData.draftId}`);
          setDraft(draftData.draftId)
        }, 2000);
      }
    } catch (error) {
      console.error('API request error:', error);
      setColor('alert-danger');
      setMessage('An error occurred while checking eligibility');
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }
  };

  return (
    <>
      {message !== "" && (
        <div style={{ position: "absolute", top: 0, width: "100%", textAlign: "center" }}>
          <div style={{ display: "inline-block", marginTop: "20px" }}>
            <Toast title={message} color={color ? color : "alert-success"} />
          </div>
        </div>
      )}

      <div className="card mb-5 mb-xl-10 mt-0" id="kt_profile_details_view">
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{draftData?.docName}</h3>
          </div>
          <div className="card-title me-0">
            {(loader) ? (
              <button
                title={annotate ? "Processed" : "Process to AI/ML"}
                className={`btn ${results.length > 0 ? 'btn-white' : "btn-custom"} text-white ${results.length > 0 ? "btn-white" : "btn-custom"
                  }`}
                style={{ backgroundColor: "#333E58" }}
                disabled={loading || (results.length > 0 || annotate)}
              >
                Processing...
              </button>
            ) : (
              <div className="gap-5 d-flex ">
                {results?.length > 0 ? (
                  <>
                    <FaTag
                      className="svg-icon1 mt-4"
                      size={18}
                      color="white"
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      title="Tag"
                      onClick={() => navigate('tagging', { state: tagdata })}
                    />
                    <FaQrcode
                      className="svg-icon1 mt-4 "
                      size={18}
                      style={{ cursor: "pointer", marginRight: "5px", color: "green" }}
                      title="Dashboard"
                      onClick={handleNavigate}
                    />
                  </>
                ) : (draftData?.status=="ACTIVE" && <button
                  title={annotate ? "Processed" : "Process to AI/ML"}
                  className={`btn   ${"btn-custom"} text-white ${"btn-custom"
                    }`}
                  onClick={() => handleProcessSource(draftData?.draftId, draftData?.query, draftData?.action)}
                  disabled={(results.length > 0 || annotate)}
                >
                  {"Process to AI/ML"}
                </button>)}
              </div>
            )}
          </div>
        </div>
        <div className="card-body ">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 mb-3">
                <div className="row pe-5">
                  <label className="col-lg-2 fw-bold text-dark p-0 h5">File Name</label>
                  <div className="col-lg-9 offset-lg-1 fv-row p-0">
                    <span className="h8">{draftData?.fileName || "N/A"}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 mb-3">
                <div className="row pe-2">
                  <label className="col-lg-4 fw-bold text-dark p-0 h5">Subject</label>
                  <div className="col-lg-7 offset-lg-1 fv-row p-0">
                    <span className="h8">{draftData?.draftSubject || "N/A"}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-8 mb-3">
                <div className="row pe-2">
                  <label className="col-lg-2 fw-bold text-dark p-0 h5">Uploaded By</label>
                  <div className="col-lg-9 offset-lg-1 fv-row p-0">
                    <span className="h8">{draftData?.uploadedBy || "N/A"}</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 mb-3">
                <div className="row pe-2">
                  <label className="col-lg-4 fw-bold text-dark p-0 h5">Uploaded On</label>
                  <div className="col-lg-7 offset-lg-1 fv-row p-0">
                    <span className="h8 me-2">{draftData?.uploadedOn || "N/A"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const ResponseSourceInfoWrapper = () => (
  <QueryRequestProvider>
    <QuerySectionHeadingsProvider >
      <ListViewProvider>
        <ResponseSourceInfo />
      </ListViewProvider>
    </QuerySectionHeadingsProvider>
  </QueryRequestProvider>
);
export default ResponseSourceInfoWrapper;
