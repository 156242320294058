import React from 'react'

export const Toast = (props: any) => {
    return (
      <div className={`alert ${props.color} align-items-center fixed-top w-25 m-64-auto text-center`} style={{ margin: "64px auto" }}>
      <div className="d-flex flex-column">
        <h5 className="mb-1 justify-content-center">{props.title}</h5>
      </div>
    </div>
      
    )
}