/** @format */

import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../theme/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../theme/assets/ts/_utils";
import { WithChildren } from "../../theme/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import SourceBoard from "../modules/apps/user-management/SourceBoard";
import { MainDashboard } from "../pages/dashboard/MainDashboard";
import { useAuth } from "../modules/auth";
import { SectionHeadingWrapper } from "../modules/apps/user-management/users-list/SectionHeadingList";
import { ParticipantDashboard } from "./ParticipantDetailPage";
import ParticipantTable from "../modules/profile/ParticipantModel";
import TreeMap from "../pages/dashboard/NewDashboardComponents/TreeMap";
import { CategoryDashboard } from "../pages/dashboard/NewDashboardComponents/CategoryDrilledPage";
import ViewDoc from "../modules/apps/user-management/ViewDoc";

const PrivateRoutes = () => {
	const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
	const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
	const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
	const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
	const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
	const UsersPage = lazy(
		() => import("../modules/apps/user-management/UsersPage")
	);
	const ProfileDetails = lazy(
		() => import("../modules/profile/ProfileDetails")
	);

	const { currentUser } = useAuth();
	const ROLE: any | undefined = currentUser?.roles;
	
	

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{/* Redirect to Dashboard after success login/registartion */}

				{currentUser && (currentUser.roles[0] ==='PILOT_USER') && <Route path="auth/*" element={<Navigate to={`/dashboard/${currentUser?.documentId}`} />}/>}
				{currentUser && (currentUser.roles[0] === 'DASHBOARD'|| currentUser.roles[0] ==='VIEWER' || currentUser.roles[0] ==='PILOT_USER') ? (
					<Route
						path="auth/*"
						element={
							<Navigate
								to={`/dashboard/${currentUser?.sourceId}`}
								 
							/>
						}
					/>
				) : (
					<Route
						path="auth/*"
						element={
							<Navigate
								to={`/dashboard/${currentUser?.sourceId}`}
								
							/>
						}
					/>
				)}
				{/* Pages */}
				<Route path="dashboard/" element={<MainDashboard />} />
				<Route path="/dashboard" element={<ParticipantTable />} />
				<Route path="/dashboard/:id/:participantName" element={<ParticipantDashboard />} />
				<Route path="/dashboard/:id/:newword/:participantName" element={<ParticipantDashboard />} />
				<Route path="/tree-map" element={<TreeMap className={""} title={""} data={undefined} chartID={""} />} />
				<Route path="/drilled-page/:id/" element={<CategoryDashboard />} />
				<Route path="dashboard/:id" element={<MainDashboard />} />
				<Route path="builder" element={<BuilderPageWrapper />} /> 
				<Route path="menu-test" element={<MenuTestPage />} />
				{/* Lazy Modules */}
				
				

				{ROLE[0] == "ADMIN" && (
					<>
						<Route
							path="/user-management/*"
							element={
								<SuspensedView>
									<UsersPage />
								</SuspensedView>
							}
						/>
						<Route
							path="/source-management/*"
							element={
								<SuspensedView>
									<SourceBoard />
								</SuspensedView>
							}
						/>
						<Route
							path="/Heading/*"
							element={
								<SuspensedView>
									<SectionHeadingWrapper />
								</SuspensedView>
							}
						/>

						
					</>
				)}
				<Route
					path="/dashboard/*"
					element={
						<SuspensedView>
							<ParticipantDashboard />
						</SuspensedView>
					}
				/>
				<Route
					path="/viewDoc/*"
					element={
						<>
							<ViewDoc />
						</>
					}
				/>

				{/* Page Not Found */}
				<Route path="*" element={<Navigate to="/error/404" />} />
			</Route>
		</Routes>
	);
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue("--kt-primary");
	TopBarProgress.config({
		barColors: {
			"0": baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	});
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
