import React, { useEffect } from "react";

interface Word {
  title: string;
  coordinates: { x: number; y: number }[];
}

interface ParagraphProps {
  data: any;
  handleMouseUp: any;
}

const Tagging: React.FC<ParagraphProps> = ({ data, handleMouseUp }) => {
  

  const renderWords = () => {
    if (!data) {
      return null; // Handle case when data is null or undefined
    }
    let currentX = 0; // Initial X position
    let currentY = 0; // Initial Y position
    const wordMargin = 5; // Adjust the word margin as needed

    return data?.map((word, index) => {
      const { title, coordinates } = word;

      const minX = Math.min(...coordinates.map((coord) => coord.x));
      const minY = Math.min(...coordinates.map((coord) => coord.y));
      const maxX = Math.max(...coordinates.map((coord) => coord.x));
      const maxY = Math.max(...coordinates.map((coord) => coord.y));
      const width = maxX - minX;
      const height = maxY - minY;

      // console.log("COORDINATES", {
      //   minX,
      //   minY,
      //   maxX,
      //   maxY,
      //   width,
      //   height,
      //   test: [...coordinates],
      // });
      // Check if the current word exceeds the available width
      if (currentX - width < 0) {
        currentX = 20; // Reset X position
        currentY += height + wordMargin; // Move to the next row
      }

      const translatedCoordinates = coordinates
        .map(
          (coord) =>
            `${coord.x - minX + currentX}cm ${coord.y - minY + currentY}cm`
        )
        .join(",");

      // Update currentX for the next word
      currentX -= width + wordMargin;

      return (
        <div
          key={index}
          style={{
            // position: 'absolute',
            // fontWeight: "bold",
            // border: '1px solid black',
            padding: "0.2cm",
            // whiteSpace: 'nowrap',
            transform: `translate(${translatedCoordinates})`,
            flexWrap: "wrap",
          }}
        >
          {title}
        </div>
      );
    });
  };

  return (
    <div
      style={{
        // position: "relative",
        width: "21cm",
        height: "29.7cm",
        // border: "1px dotted grey",
        flexWrap: "wrap",
        overflowY: "auto",
        backgroundColor: "white",
        padding: "1cm",
      }}
      onClick={() => {
        handleMouseUp();
      }}
    >
      {renderWords()}
    </div>
  );
};

export default Tagging;
