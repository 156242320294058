/** @format */

// /** @format */

import { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../core/_requests";
import arrowBack from "../../../../assets/image (3).png";
import "./OTPForm.css";
const initialValues = {
	email: "admin@demo.com",
	otp: "",
};

const forgotPasswordSchema = Yup.object().shape({
	//   email: Yup.string()
	//     .email('Wrong email format')
	//     .min(3, 'Minimum 3 symbols')
	//     .max(50, 'Maximum 50 symbols')
	//     .required('Please enter email'),
});

export function Verification() {
	const [loading, setLoading] = useState(false);
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
	const formik = useFormik({
		initialValues,
		// validationSchema: forgotPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			console.log(values, "FORMIK OTP");
			// setLoading(true);
			// setHasErrors(undefined);
			// setTimeout(() => {
			// 	requestPassword(values.email)
			// 		.then(({ data: { result } }) => {
			// 			setHasErrors(false);
			// 			setLoading(false);
			// 		})
			// 		.catch(() => {
			// 			setHasErrors(true);
			// 			setLoading(false);
			// 			setSubmitting(false);
			// 			setStatus("The login detail is incorrect");
			// 		});
			// }, 1000);
		},
	});

	const otpFields = useRef<HTMLInputElement[]>([]);

	const handleOtpChange = (index: number, value: string) => {
		if (value && !isNaN(parseInt(value)) && value.length === 1) {
			otpFields.current[index].value = value;

			if (index < 5) {
				otpFields.current[index + 1]?.focus();
			} else {
				otpFields.current[index]?.blur();
				// formik.handleSubmit();
			}
			const otp = formik.values.otp.split("");
			otp[index] = value;
			formik.setFieldValue("otp", otp.join(""));
		} else {
			otpFields.current[index].value = "";
		}
	};

	const handleOtpKeyDown = (index: number, e: React.KeyboardEvent) => {
		const prevField = otpFields.current[index - 1];
		if (e.key === "Backspace" && !otpFields.current[index]?.value) {
			prevField?.focus();
		}
	};

	const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
		event.currentTarget.value = event.currentTarget.value
			.replace(/[^0-9]/g, "")
			.slice(0, 1);
	};
	const [timer, setTimer] = useState(45);
	const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

	useEffect(() => {
		timer > 0 && setTimeout(timeOutCallback, 1000);
	}, [timer, timeOutCallback]);

	console.log(timer);

	const resetTimer = function () {
		if (!timer) {
			setTimer(45);
		}
	};

	return (
		<div className="card text-center px-16 cardSize" style={{paddingTop:'54px', paddingBottom:'54px'}}>
			<form
				className="form  fv-plugins-bootstrap5 fv-plugins-framework"
				noValidate
				id="kt_login_password_reset_form"
				onSubmit={formik.handleSubmit}
			>
				<div className="text-center mb-10">
					<h1 className="text-dark fw-bolder mb-3 mt-5 ">Verification </h1>
				</div>

				{/* begin::Form group */}
				<div className="fv-row my-11 text-center ">
					<label className="form-label fw-bolder text-gray-900 fs-6 ">
						Enter Verification Code
					</label>
					<div className="d-flex text- justify-content-center">
						<div className="otp-input mt-5">
							{[...Array(6)].map((_, index) => (
								<input
									className="field"
									key={index}
									ref={(el) =>
										el && (otpFields.current[index] = el as HTMLInputElement)
									}
									name="otp"
									type="number"
									inputMode="numeric"
									size={1}
									onChange={(e) => handleOtpChange(index, e.target.value)}
									onKeyDown={(e) => handleOtpKeyDown(index, e)}
									onInput={handleInput}
								/>
							))}
						</div>
					</div>
					{formik.touched.email && formik.errors.email && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.email}</span>
							</div>
						</div>
					)}
				</div>

				{/* end::Form group */}

				{/* begin::Form group */}
				<div className="d-grid  mb-4 justify-content-center">
					<Link to="/auth/forgot-password1">
						<button
							title="Verify"
							type="submit"
							id="kt_password_reset_submit"
							className="btn text-white mb-5 btn-custom "
							style={{ backgroundColor: "#333E58", width: "252px" }}
						>
							<span className="indicator-label justify-content-center ">
								Verify
							</span>
							{loading && (
								<span className="indicator-progress">
									Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</Link>{" "}

					<div className="d-flex justify-content-between mb-3 mt-2">
						<span className=" fs-8">Didn't receive the OTP? </span>
					</div>

					<div className="d-flex justify-content-between mb-5 mt-0">
						<Link  to='/auth/verification' onClick={resetTimer} className={clsx("fs-7" , timer && 'text-muted nav-link disabled')} title='Resend'>Resend</Link> <span className={clsx("fs-7" , !timer && "d-none")}>{timer} secs</span>
					</div>

					<div className="d-flex justify-content-center align-items-center">
						<Link to="/auth/forgot-password" className="link-dark fs-7 " title="Back">
							<span>
								<img
									src={arrowBack}
									style={{ height: "30px", width: "25px" }}
								/>
								Back
							</span>
						</Link>
					</div>
				</div>
				{/* end::Form group */}
			</form>
		</div>
	);
}
