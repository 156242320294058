/** @format */

import React from "react";
import tags from "./../Assets/papernew.png";

const EngagementCard = ({ data }) => {
  // Extract engagement value from data if it exists
  const engagement = data && data[7] && data[7].value;

  return (
    <div className="card card-lg p-5" style={{ backgroundColor: "#bac1fb", height:"100%" }}>
      <div className="ps-5 mb-3 d-flex align-items-center">
        <img src={tags} height="35px" width="35px" alt="logo" loading="lazy"  />
        <div className="ps-6 d-flex ms-4">
          <h2 className="text-black pt-2" style={{ fontWeight: "bold", fontSize: "17px"  }}>
            ENGAGEMENT
          </h2>
        </div>
      </div>

      <div className="text-white" style={{ marginTop: "" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 0.2" width="100%">
          <line x1="0" y1="1" x2="36" y2="1" stroke="grey" strokeWidth="2" />
        </svg>
      </div>

      <div className="ps-6 pt-10">
        <span className="fs-1 text-black" style={{  fontSize: "25px", color: "#C6F404" }}>
          {engagement} pages
        </span>
      </div>
    </div>
  );
};

export default EngagementCard;
