/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import {
	createResponseContext,
	initialQueryResponse,
	initialQueryState,
	PaginationState,
	responseData,
	stringifyRequestQuery,
	WithChildren,
} from "../../../../../../theme/helpers";
import {
	getResponse,
	getUsersView,
	getSourceList,
	getHeadingsList,
	getResponseHeadingsList,
} from "./_requests";
import { Responses, User, Source } from "./_models";
import { useQueryRequest } from "./QueryRequestProvider";
import { useParams } from "react-router-dom";

//Initializing reponse-list query
const QueryResponseContext =
	createResponseContext<Responses>(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const { id } = useParams();

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery(
		[`response-list-${query}`],
		() => {
			return getResponse(id, query);
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: true, refetchOnMount: true, }
	);

	return (
		<QueryResponseContext.Provider
			value={{ isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</QueryResponseContext.Provider>
	);
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
	const { response } = useQueryResponse();
	if (!response) {
		return [];
	}
	return response.data || [];
};

const useQueryResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useQueryResponse();

	if (!response || !response.payload || !response.payload.pagination) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
	const { isLoading } = useQueryResponse();
	return isLoading;
};

//Initializing section-head-list query
const QuerySectionHeadingsContext =
	createResponseContext<Responses>(initialQueryResponse);

const QuerySectionHeadingsProvider: FC<WithChildren> = ({ children }) => {
	const queryClient = useQueryClient();
	const location = useLocation();
	const draftData: any = location.state;
	const [action, setAction] = useState<string>(draftData?.status);
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const { id } = useParams();
	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);
	useEffect(() => {
		setAction(draftData?.status);
	}, [draftData?.status]);
	useEffect(() => {
		const fetchData = async () => {

			if (action === "COMPLETE") {
				try {
					// Call the API to get the headings list
					await getHeadingsList(id, query, "COMPLETE");

					// Invalidate the query to trigger a refetch
					queryClient.invalidateQueries(`GETHEADINGlIST-${query}`);
				} catch (error) {
					console.error("Error:", error);
				}
			}
		};

		fetchData();
	}, [action, query, queryClient, id]);
	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery(
		[`GETHEADINGlIST-${query}`],
		() => {
			const updatedAction = "COMPLETE";

			return getHeadingsList(id, query, updatedAction);
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, refetchOnMount: true, }
	);
	return (
		<QuerySectionHeadingsContext.Provider
			value={{ isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</QuerySectionHeadingsContext.Provider>
	);
};

const useQueryHeading = () => useContext(QuerySectionHeadingsContext);

const useQueryHeadingData = () => {
	const { response } = useQueryHeading();
	if (!response) {
		return [];
	}
	return response.data || [];
};
const useQueryHeadingPagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useQueryHeading();

	if (!response || !response.payload || !response.payload.pagination) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
};

const useQueryHeadingLoading = (): boolean => {
	const { isLoading } = useQueryHeading();
	return isLoading;
};

//responseheading
const QueryResponseHeadingsContext =
	createResponseContext<Responses>(initialQueryResponse);

const QueryResponseHeadingsProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const location = useLocation();
	const responseData:responseData|any = location?.state;
	const [action, setAction] = useState<string>(responseData?.status);
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	const { respid } = useParams();
	const queryClient = useQueryClient();

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);
	useEffect(() => {
		setAction(responseData?.status);
	}, [responseData?.status]);
	useEffect(() => {
		const fetchData = async () => {

			if (action === "COMPLETE") {
				try {
					// Call the API to get the headings list
					await getResponseHeadingsList(respid, query, "COMPLETE");

					// Invalidate the query to trigger a refetch
					queryClient.invalidateQueries(`GETRESPONSEHEADINGlIST-${query}`);
				} catch (error) {
					console.error("Error:", error);
				}
			}
		};

		fetchData();
	}, [action, query, queryClient, respid]);
	interface ResponseData {
		data: {
			participants: string[];
			sentiments: {
				sectionHead: string;
				sentiment: string;
			}[];
		};
		payload: {
			pagination: {
				page: number;
				from: number;
				to: number;
				total: number;
				links: {
					url: string | null;
					label: string;
					active: boolean;
					page: number | null;
				}[];
				last_page: number;
				first_page_url: string;
				next_page_url: string | null;
				prev_page_url: string | null;
				items_per_page: string;
			};
		};
	}

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery<ResponseData>(
		[`GETRESPONSEHEADINGlIST-${query}`],
		async () => {
			const response = await getResponseHeadingsList(respid, query, "COMPLETE");
			return response as unknown as ResponseData;
		},
		{
			cacheTime: 0,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			refetchOnMount: true, // or use refetchOnWindowFocus: true
		}
	);

	return (
		<QueryResponseHeadingsContext.Provider
			value={{ isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</QueryResponseHeadingsContext.Provider>
	);
};

const useQueryResponseHeading = () => useContext(QueryResponseHeadingsContext);

const useQueryResponseHeadingData = () => {
	const { response } = useQueryResponseHeading();
	if (!response?.data?.sentiments) {
		return [];
	}
	return response?.data?.sentiments || [];
};
const useQueryResponseHeadingParticipantData = () => {
	const { response } = useQueryResponseHeading();
	if (!response?.data?.participants) {
		return 
	}
	return response?.data?.participants || [];
};
const useQueryResponseHeadingPagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useQueryResponseHeading();

	if (!response || !response.payload || !response.payload.pagination) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
};

const useQueryResponseHeadingLoading = (): boolean => {
	const { isLoading } = useQueryResponseHeading();
	return isLoading;
};


//Initializing User's Table query
const UserQueryResponseContext =
	createResponseContext<User>(initialQueryResponse);
const UserQueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,
	} = useQuery(
		[`USER_LIST_VIEW-${query}`],
		() => {
			return getUsersView(query);
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	);
	return (
		<UserQueryResponseContext.Provider
			value={{ isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</UserQueryResponseContext.Provider>
	);
};

const useUserQueryResponse = () => useContext(UserQueryResponseContext);

const useQueryViewResponseData = () => {
	const { response } = useUserQueryResponse();
	if (!response) {
		return [];
	}
	return response.data || [];
};

const useQueryUserResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useUserQueryResponse();

	if (!response || !response.payload || !response.payload.pagination) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
};

const useQueryUserResponseLoading = (): boolean => {
	const { isLoading } = useUserQueryResponse();
	return isLoading;
};

//Initializing source query
const SourceQueryResponseContext =
	createResponseContext<Source>(initialQueryResponse);
const SourceQueryResponseProvider: FC<WithChildren> = ({ children }) => {
	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const {
		isLoading,
		isFetching,
		refetch,
		data: response,

	} = useQuery([`GET_SOURCE_LIST-${query}`], () => {
		return getSourceList(query);

	},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }

	);
	return (
		<SourceQueryResponseContext.Provider
			value={{ isLoading: isLoading, isFetching, refetch, response, query }}
		>
			{children}
		</SourceQueryResponseContext.Provider>
	);
};
const useSourceQueryResponse = () => useContext(SourceQueryResponseContext);
const useQuerySourceData = () => {
	const { response } = useSourceQueryResponse();
	if (!response) {
		return [];
	}
	return response.data || [];
};
const useSourceResponsePagination = () => {
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	};

	const { response } = useSourceQueryResponse();

	if (!response) {
		return defaultPaginationState;
	}
	return response.payload.pagination;
};
const useQuerySourceResponseLoading = (): boolean => {
	const { isLoading } = useSourceQueryResponse();
	return isLoading;
};

export {
	QueryResponseProvider,
	useQueryResponse,
	useQueryResponseData,
	useQueryResponsePagination,
	useQueryResponseLoading,
	useQueryViewResponseData,
	useQueryUserResponsePagination,
	UserQueryResponseProvider,
	useQueryUserResponseLoading,
	useUserQueryResponse,
	SourceQueryResponseProvider,
	useQuerySourceData,
	useSourceResponsePagination,
	useSourceQueryResponse,
	useQuerySourceResponseLoading,
	QuerySectionHeadingsProvider,
	useQueryHeadingData,
	useQueryHeading,
	useQueryHeadingPagination,
	useQueryHeadingLoading,
	QueryResponseHeadingsProvider,
	useQueryResponseHeadingData,
	useQueryResponseHeading,
	useQueryResponseHeadingPagination,
	useQueryResponseHeadingLoading,
	useQueryResponseHeadingParticipantData


};
