import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./TreeMap.css";

const CategoryParticipantTable = (props) => {
  const category = window?.location?.href;
  const a = category.split("x=");
  const word: any = a.pop()
  const newword = word.replace("%20", " ");
  // console.log("NAB", window?.location?.href);
  let title = props.title;
  let chartID = props.chartID;
  let data = props.data?.slice(0, 10);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const xValue = queryParams.get("x");

  useEffect(() => {
    // Your chart initialization and rendering code here
    // It will run whenever chartID changes
  }, [chartID]);

  const handleParticipantClick = (participantName) => {
    // Navigate to the detail page with the participant's name as a parameter
    navigate(`/dashboard/${id}/${newword}/${encodeURIComponent(participantName)}`);
  };

  const handleParticipantClick1 = (participantName) => {
    // Navigate to the detail page with the participant's name as a parameter
    navigate(`/dashboard/${id}/${encodeURIComponent(participantName)}`);
  };

  return (
    <div className="card card-lg bg-transparent pt-2  d-flex " style={{ height: "auto",width:"auto" }}>
      <div>
        <div className="d-flex align-items-start text-white mt-4 justify-content-start " style={{ color: "#769495", fontSize: "18px" }}>
          <h4 className="text-white">{title}</h4>
        </div>
        <div
          className="p-3  pt-4 pb-2"
          style={{
            background: "#004053",
            backgroundSize: "200% 200%",
            backgroundPosition: "0% 0%",
            animation: `gradientAnimation 10s ease infinite`,
            height: "380px",
            marginTop: "21px",
            width:"100%"
          }}
        >
          <div className="row gy-4 text-white gx-lg-12 d-flex align-items-center font-weight-bold">
            <b className="col-lg-3 text-fw-semibold ">Participants</b>
            <b className="col-lg-4 text-fw-semibold ">License category</b>
            <b className="col-lg-2 text-fw-semibold ">Country</b>
            <b className="col-lg-3 text-fw-semibold ">Sector</b>
          </div>
          <hr
            className="d-flex align-items-center"
            style={{
              marginTop: "2px",
              border: "0.5px solid white",
              width: "100%",
            }}
          />

          {data?.map((item, index) => (
            <div className="row gy-4 text-white gx-lg-12 d-flex align-items-center" key={index}>
              {/* Use the handleParticipantClick function to navigate when participant name is clicked */}
              {newword && xValue ? (
                <div
                  className="col-lg-3 text-fw-semibold mb-4 text-hover-white "
                  onClick={() => handleParticipantClick(item.name)}
                  style={{ cursor: "pointer", whiteSpace: "nowrap", color: "#D8FE25",overflow: "hidden", textOverflow: "ellipsis" }}
                  title={item.name}
                >
                  <b>{item.name}</b>
                  
                </div>
              ) : (
                <div
                  className="col-lg-3 text-fw-semibold mb-4 text-hover-primary "
                  onClick={() => handleParticipantClick1(item.name)}
                  style={{ cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden",color: "#8DBD1E", textOverflow: "ellipsis" }}
                  title={item.name}
                >
                  <b>{item.name}</b>
                </div>
              )}

              {/* Replace 'licenseCategory', 'country', and 'sector' with your actual data keys */}
              <div className="col-lg-4 fs- mb-4 px-2" style={{ color: "white", cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.licenseCategory}
              </div>
              <div className="col-lg-2 fs-7 mb-4 px-2" style={{ color: "white", cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.country}
              </div>
              <div className="col-lg-3 fs-7 mb-4 px-2" style={{ color: "white", cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.sector}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryParticipantTable;
