import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { ApexOptions } from "apexcharts";

type Props = {
  defaultsection: any;
  data: any;
};

const SentimentBarChart: React.FC<Props> = (props) => {
  const defaultsection = props.defaultsection;
  const getDefaultSelectedSection = () => {
    const defaultsection = localStorage.getItem("defaultsection");
    return defaultsection;
  };

  const transformData = (chartData) => {
    const transformedData = {};
    chartData?.forEach((item) => {
      const sectionName = Object.keys(item)[0];
      const values = item[sectionName].map(Number);
      transformedData[sectionName] = [values];
    });
    return transformedData;
  };

  const transformedData = transformData(props.data);
  const chartRef = useRef<any>(null);
  const sectionNames = Object.keys(transformedData);
  const firstKey = sectionNames.length > 0 ? sectionNames[0] : null;
  const [selectedSection, setSelectedSection] = useState<string | null>(
    getDefaultSelectedSection()
  );
  const categories = [
    ["Agree"],
    ["Agree With", "Amendments"],
    ["Disagree"],
    ["Disagree with ", "Amendments"],
    ["No", "Comments"],
    ["Request", "Clarification"],
  ];

  useEffect(() => {
    if (defaultsection) {
      setSelectedSection(defaultsection);
      localStorage.setItem("defaultsection", defaultsection);
    }
  }, [defaultsection]);

  const sectionNamesFiltered = sectionNames.filter(
    (section) => section !== defaultsection
  );
  if (defaultsection) {
    sectionNamesFiltered.unshift(defaultsection);
  }

  useEffect(() => {
    if (!selectedSection) return;

    const colors =  ["#004053", "#D4FC03", "#006D9B", "#56BAB9", "#3247F5", "#19247B"];

    const options: ApexOptions = {
      series: [
        { name: 'No of times expressed', data: transformedData[selectedSection]?.[0] ?? [] }
      ],
      chart: {
        height: 400,
        type: "bar",
        toolbar: { show: false },
      },
      colors,
      plotOptions: {
        bar: {
          columnWidth: "40%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories,
        labels: {
          style: {
            fontSize: "10px",
          },
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        tickAmount: 7,
        labels: {
          show: true,
          style: {
            fontSize: "13px",
          },
        },
        title: {
          text: "Counts",
          style: {
            fontSize: "16px",
            fontWeight: 600,
          },
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [defaultsection, transformedData, selectedSection, firstKey]);

  return (
    <div className=" p-5  mt-4 " style={{width:"100%"}}>
      <div
        className="d-flex  align-items-start justify-content-start mt-2"
        style={{ color: "#769495", fontSize: "18px" }}
      >
        <h4>Sentiments by Sections</h4>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          // marginTop: '25px'
        }}
      >
        <select
          className="form-select"
          style={{ width: "40%", height: "8%" }}
          onChange={(e) => setSelectedSection(e.target.value)}
          value={selectedSection || ""}
        >
          {sectionNamesFiltered?.map((section) => (
            <option key={section} value={section}>
              {section}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginBottom: "20px" }} id="chart1" ref={chartRef}></div>
    </div>
  );
};

export default SentimentBarChart;
