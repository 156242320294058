import React, { useEffect, useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface CategoryPieChartProps {
    chartID: string;
    data: Array<{ name: string; value: number }>;
    title: string;
}

function CategoryPieChart(props: CategoryPieChartProps) {
    let chartID = props.chartID;
    var data = props.data || [];
    let title = props.title;

    useLayoutEffect(() => {
        // Create root element
        var root = am5.Root.new(chartID);

        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        var chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                radius: am5.percent(70),
                endAngle: 270,
            })
        );

        // Create series
        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: 'name',
                endAngle: 270,
            })
        );

        series.get("colors")?.set("colors", [
            am5.color(0x2330FE),
            am5.color(0xD2FF00),
            am5.color(0x4E79A7),
            am5.color(0x006D9B),
            am5.color(0x19247B),
            am5.color(0x56BAB9)
            
        ]);

        series.labels.template.setAll({
            text: "name:{name}",
            inside: false,
        });

        series.states.create("hidden", {
            endAngle: -90,
        });

        // Customize the series tooltip
        let tooltip: any = am5.Tooltip.new(root, {
            getFillFromSprite: true,
            labelText: "License category :{color}{name}\nNo. of Participant: {value}",
            text: am5.color(0x000000),
        });
        series.set("tooltip", tooltip);

        var labelsTemplate = series.labels.template;

        labelsTemplate.setAll({
            text: "{name}:({value})",
            oversizedBehavior: "wrap",
            maxWidth: 138,
            inside: false,
            
        });



        // Set data
        series.data.setAll(data);

        series.appear(1000, 100);

        // Dispose of the logo
        root._logo?.dispose();

        // Clean up when the component unmounts
        return () => {
            root.dispose();
        };
    }, [chartID, data]);

    return (
        <div className="">
            <div className="d-flex align-items-start justify-content-start mt-6" style={{ color: "#769495", fontSize: "18px" }}>
                <h4 className=" ms-3">{title}</h4>
            </div>

            <div
                id={chartID}
                style={{ width: "100%", height: "440px" }}
            ></div>
        </div>
    );
}

export default CategoryPieChart;
