/** @format */

import axios, { AxiosResponse } from "axios";
// import  * as cheerio  from "cheerio";
import { DocumentData, ID, Response, Staticdata, TagData } from "../../../../../../theme/helpers";
// import { useStorage } from "../../../../../hooks/useStorage";
import {
	Doc,
	ResponseQuery,
	User,
	UsersQueryResponse,
	Auth,
	HeadingData,
	DataSchema,
} from "./_models";
import * as authHelper from "../../../../auth/core/AuthHelpers";


const API_URL = process.env.REACT_APP_THEME_API_URL;
const NEITH_API_URL = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/user`;

const GET_USERS_URL = `${API_URL}/users/query`;
const GET_USERS_VIEW = `${NEITH_API_URL}/users/view`;
const GET_ID = `${NEITH_API_URL}/users/getpilotsource`;

const REGISTER_USERS_VIEW = `${NEITH_API_URL}/users/register`;
const DELETE_USERS_VIEW = `${NEITH_API_URL}/users/delete`;
const EDIT_USERS_VIEW = `${NEITH_API_URL}/users/edit`;
const GET_MASTER_ROLE = `${NEITH_API_URL}/fetchMasterRoles`;
const ADD_RESPONSE = `${NEITH_API_URL}/document/upload`;
const GET_CATEGORY = `${NEITH_API_URL}/fetchCategory`;
const ADD_SOURCE = `${NEITH_API_URL}/document/upload`;
const GET_SOURCE_LIST = `${NEITH_API_URL}/document/sourceList`;
const GET_RESPONSES = `${NEITH_API_URL}/document/responseList`;
const DELETE_SOURCE = `${NEITH_API_URL}/document/deleteSource`;
const DELETE_RESPONSE = `${NEITH_API_URL}/document/deleteResponse`;
const ADD_PARTICIPANT = `${NEITH_API_URL}/participant/create`;
const DELETE_PARTICIPANT = `${NEITH_API_URL}/participant/delete`;
const EDIT_PARTICIPANT = `${NEITH_API_URL}/document/response/edit`;
const TAG_SOURCE = `${NEITH_API_URL}/document/viewTag`;
const ADD_HEADING = `${NEITH_API_URL}/document/addSectionHead`;
const EDIT_HEADING = `${NEITH_API_URL}/document/editSectionHead`;
const DELETE_HEADING = `${NEITH_API_URL}/document/deleteSectionHead`;
const EDIT_SOURCE = `${NEITH_API_URL}/document/source/edit`;
const EDIT_RESPONSE = `${NEITH_API_URL}/document/response/edit`;
const USER_LIST = `${NEITH_API_URL}/users/list`; //needs to be changed
const USER_EMAIL_LIST = `${NEITH_API_URL}/users/existingEmails`; //needs to be changed
const GETHEADINGlIST = `${NEITH_API_URL}/document/viewSource`
const PROCESS_SOURCE = `${NEITH_API_URL}/document/processSource`
const PROCESS_RESPONSE = `${NEITH_API_URL}/document/processResponse`
const GETRESPONSEHEADINGlIST = `${NEITH_API_URL}/document/viewResponse`
const SAVETAG = `${NEITH_API_URL}/document/updateTag`
const GETPAGEDATA = `${NEITH_API_URL}/document/fetchPageData`
const GETSTATICDATA = `${NEITH_API_URL}/dashboard/staticData`
const GETDynamicSportingData = `${NEITH_API_URL}/dashboard/dynamicSportingData`
const GETdynamicKeyThemesData = `${NEITH_API_URL}/dashboard/dynamicKeyThemesData`
const GETkeyWordCount = `${NEITH_API_URL}/dashboard/keyWordCount`
const GET_DOC = `${NEITH_API_URL}/document/viewDocument`;

// const auth = useStorage.get("kt-auth-react-v");

// https://apps.trisysit.com/nasbackendapi/document/viewDocument/_1673371782803prakash.pdf


const auth = authHelper.getAuth();

const getUsers = (query: string): Promise<UsersQueryResponse> => {
	return axios
		.get(`${GET_USERS_URL}?${query}`)
		.then((d: AxiosResponse<UsersQueryResponse>) => d.data);
};
const processSource = async (draftId: string | undefined, query: string, action: string | undefined): Promise<void | ResponseQuery> => {


	if (action === 'COMPLETE') {
		await getHeadingsList(draftId, query, "COMPLETE");
		return; // Return early after calling getHeadingsList
	} else {
		try {
			const response = await axios.post(`${PROCESS_SOURCE}/${draftId}`, null, {
				headers: { Authorization: `Bearer ${auth?.token}` },
			});

			if (response.data.status === 'OK') {
				const updatedAction = "COMPLETE";
				await getHeadingsList(draftId, query, action);

				// Call the second API with the updated action value
				return;
			} else {
				console.log(`Error processing file ${draftId}.`);
				throw new Error('API error'); // Throw an error to indicate failure
			}
		} catch (error) {
			console.log(error, "API error");
			console.log(`An error occurred while processing file ${draftId}.`);
			throw new Error('API error'); // Throw an error to indicate failure
		}
	}
};
const getId = () => {
	console.log("idurl",GET_ID)
	return axios
		.get(GET_ID, {
			
		})
		.then((res) => res.data)
		.catch((e) => console.log(e, "api error"));
};

const getHeadingsList = (draftId: ID | string | undefined, query: string, action: string | undefined): Promise<ResponseQuery> => {
	const url = `${GETHEADINGlIST}/${draftId}?${query}`;

	const headers = {
		Authorization: `Bearer ${auth?.token}`,
	};

	const params = {
		action: action, // Include the action parameter in the request
	};

	return axios
		.get(url, { headers, params })
		.then((res) => res.data)
		.catch((e) => {
			console.log(e, "API error");
			throw new Error('API error'); // Throw an error to indicate failure
		});
};



const processResponse = async (responseId: ID | string | undefined, query: string, action: string | undefined): Promise<void | ResponseQuery> => {
	if (action == 'COMPLETE') {
		await getResponseHeadingsList(responseId, query, "COMPLETE");
		return;
	}
	else {
		try {
			const response = await axios.post(`${PROCESS_RESPONSE}/${responseId}`, null, {
				headers: { Authorization: `Bearer ${auth?.token}` },
			});
			if (response.data.status == 'OK') {
				await getResponseHeadingsList(responseId, query, action);
				return;
			} else {

				console.log(`Error processing file ${responseId}.`);
				throw new Error('API error'); // Throw an error to indicate failure
			}
		}
		catch (error) {
			console.log(error, "API error");
			console.log(`An error occurred while processing file ${responseId}.`);
			throw new Error('API error');
		}
	}
};


const getResponseHeadingsList = (responseId: ID | string | undefined, query: string, action: string | undefined): Promise<ResponseQuery> => {

	const url = `${GETRESPONSEHEADINGlIST}/${responseId}?${query}`;

	const headers = {
		Authorization: `Bearer ${auth?.token}`,
	};

	const params = {
		action: action, // Include the action parameter in the request
	};

	return axios
		.get(url, { headers, params })
		.then((res) => res.data)
		.catch((e) => {
			console.log(e, "API error");
			throw new Error('API error'); // Throw an error to indicate failure
		});
};
const getUsersView = (query: string, auth?: Auth | null): Promise<User> => {
	return axios

		.get(`${GET_USERS_VIEW}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})

		.then((response: AxiosResponse<User>) => response.data)
		.catch((error) => {
			console.error("API error:", error);
			throw error; // Re-throw the error to handle it at the calling site if needed
		});
};

const getDoc = (query: string, auth: any) => {
	return fetch(
	  new Request(`${GET_DOC}?documentId=${query}`, {
		method: "GET",
		headers: {
		  Authorization: `Bearer ${auth}`,
		},
		mode: "cors",
		cache: "default",
	  })
	)
	  .then((response) => {
		return response.blob();
	  })
	  .then((blob) => {
		const file = window.URL.createObjectURL(blob);
		console.log("Response Blob:", blob); // Log the blob data if needed
  
		// Optionally return the blob or file URL
		// return blob;
  
		const iframe = document.querySelector("iframe");
		if (iframe?.src) iframe.src = file;
	  })
	  .catch((err) => {
		console.log("FETCH ERROR...", err);
	  });
  };
  

const getAllUsersEmail = () => {
	return axios
		.get(`${USER_EMAIL_LIST}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};
const getUserById = (id: ID): Promise<User | undefined> => {
	return axios
		.get(`${USER_URL}/${id}`)
		.then((response: AxiosResponse<Response<User>>) => response.data)
		.then((response: Response<User>) => response.data);
	// .catch((e)=>console.log(e))
};
const createUser = (user: User): Promise<User | undefined |any> => {
	return axios
		.post(REGISTER_USERS_VIEW, user, {
			// headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: AxiosResponse<any>) => response)
		.catch((e) => console.log(e, "api error"));
};

// const createUser = (user: User): Promise<User | undefined> => {
// 	return axios
// 		.post(REGISTER_USERS_VIEW, user, {
// 			headers: { Authorization: `Bearer ${auth?.token}` },
// 		})
// 		.then((response: AxiosResponse<any>) => response.data.message)
// 		.catch((e) => console.log(e, "api error"));
// };

const createParticipant = (user: User): Promise<User | undefined> => {
	return axios
		.post(ADD_PARTICIPANT, user, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response) => response.data.message)
		.catch((e) => console.log(e, "api error"));
};

const updateUser = (user: User, id?: string): Promise<User | undefined> => {
	return axios
		.post(`${EDIT_USERS_VIEW}/${id}`, user, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response) => response.data)
		.catch((e) => console.log(e, "api error"));
};

const deleteUser = (userId: ID): Promise<void> => {
	return axios
		.get(`${DELETE_USERS_VIEW}/${userId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then(() => { })
		.catch((e) => console.log(e, "api error"));
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
	const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
	return axios
		.all(requests)
		.then(() => { })
		.catch((e) => console.log(e, "api error"));
};

const getUserRoles = () => {
	return axios
		.get(GET_MASTER_ROLE, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res) => res.data)
		.catch((e) => console.log(e, "api error"));
};

const AddResponse = async (docRequest: Doc | any, file) => {
	const formData = new FormData();
	let l = file.length;
	for (var x = 0; x < l; x++) {
		formData.append("file", file[x]);
	}

	formData.append("docRequest", JSON.stringify(docRequest));
	return axios
		.post(ADD_RESPONSE, formData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response) => response)
		.catch((e) => console.log(e, "api error"));
};

const AddSource = async (docRequest: Doc | any, file) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("docRequest", JSON.stringify(docRequest));
	return axios
		.post(ADD_SOURCE, formData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response) => response)
		.catch((e) => console.log(e, "api error"));
};

const deleteSource = (draftId: ID): Promise<Doc> => {
	return axios
		.delete(`${DELETE_SOURCE}?id=${draftId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response) => response.data)
		.catch((e) => console.log(e, "api error"));
};

const deleteResponse = (responseId: ID): Promise<void> => {
	return axios
		.delete(`${DELETE_RESPONSE}?id=${responseId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then(() => { })
		.catch((e) => console.log(e, "api error"));
};

const deleteSectionHead = (sectionIndex: Number, draftId: ID): Promise<void> => {
	return axios
		.delete(`${DELETE_HEADING}?sectionHeadIndex=${sectionIndex}&draftId=${draftId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then(() => { })
		.catch((e) => console.log(e, "api error"));
};

const getCategory = () => {
	return axios
		.get(GET_CATEGORY, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res) => res.data)
		.catch((e) => console.log(e, "api error"));
};

const getSourceList = (query: string) => {
	return axios
		.get(`${GET_SOURCE_LIST}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((d) => d.data)
		.catch((e) => {
			console.log(e, "api error");
		});
};

const deleteParticipant = (participantId: ID): Promise<void> => {
	return axios
		.delete(`${DELETE_PARTICIPANT}?participantId=${participantId}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then(() => { })
		.catch((e) => console.log(e, "api error"));
};

const editParticipant = (user: User, id?: ID): Promise<User | undefined> => {
	return axios
		.post(`${EDIT_PARTICIPANT}/${id}`, user, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response) => response.data)
		.catch((e) => console.log(e, "api error"));
};

const getResponse = async (
	id: string | undefined,
	query: string
): Promise<ResponseQuery> => {
	return axios
		.get(`${GET_RESPONSES}/${id}?${query}`, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((res) => res.data)
		.catch((e) => console.log(e, "api error"));
};

const addHeading = async (HeadingData: HeadingData, auth?: Auth | null): Promise<HeadingData> => {
	try {
		const response: AxiosResponse<HeadingData> = await axios.post(
			ADD_HEADING,
			HeadingData,
			{
				headers: {
					Authorization: `Bearer ${auth?.token}`,
				},
			}
		);
		return response.data; // Return the data from the response
	} catch (error) {
		console.error("API error:", error);
		throw error; // Re-throw the error to handle it at the calling site if needed
	}
};
const editHeading = (sectionHeadData: HeadingData, id?: ID): Promise<HeadingData | undefined> => {

	return axios
		.post(`${EDIT_HEADING}`, sectionHeadData, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response) => {

			return response.data;
		})

		.catch((e) => console.log(e, "api error"));
};


const editSource = async (temp: DocumentData, id: ID) => {
	return axios
		.post(`${EDIT_SOURCE}/${id}`, temp, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: AxiosResponse<DocumentData>) => response)
		.catch((e) => console.log(e, "api error"));
};

const editResponse = async (temp: Doc, id: ID) => {
	return axios
		.post(`${EDIT_RESPONSE}/${id}`, temp, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: AxiosResponse<Doc>) => response)
		.catch((e) => console.log(e, "api error"));
};

const AddParticipantModal = (temp: DataSchema, id?: ID) => {
	return axios
		.post(`${EDIT_PARTICIPANT}/${id}`, temp, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		})
		.then((response: AxiosResponse<DataSchema>) => {
			console.log("Response data:", response.data); // Log the response data
			return response.data;
		})
		.catch((e) => console.log(e, "API error"));
};
const tagSource = async (sourceID: ID,) => {
	return axios
		.get(`${TAG_SOURCE}/${sourceID}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: AxiosResponse<TagData>) => response)
		.catch((e) => console.log(e, "api error"));
}
const updateTag = async (tagData: TagData,) => {

	return axios
		.post(SAVETAG, tagData, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})
		.then((response: AxiosResponse<TagData>) => response)
		.catch((e) => console.log(e, "api error"));
};
const getPageData = async (sourceID: ID,) => {
	return axios
		.get(`${GETPAGEDATA}/${sourceID}`, {
			headers: {
				Authorization: `Bearer ${auth?.token}`,
			},
		})

		.then((response) => response)
		.catch((e) => console.log(e, "api error"));
};
const getStaticdata = async (id: ID, participant?: string | null, category?: string | null): Promise<Staticdata> => {

	let URL = `${GETSTATICDATA}/${id}`;

	// Check if participant is not null and add the "participant" query parameter
	if (participant !== null && participant != '' && participant != undefined) {
		//const encodedParticipant = encodeURIComponent(participant);
		URL = `${URL}?participant=${participant}`;
	}
	if (category !== null && category != '' && category != undefined) {
		//const encodedParticipant = encodeURIComponent(participant);
		URL = `${URL}?category=${category}`;
	}
	try {
		const response = await axios.get(URL, {
			headers: { Authorization: `Bearer ${auth?.token}` },
		});
		return response.data;
	} catch (error) {
		console.error("API error:", error);
		throw error; // Re-throw the error to handle it at the calling site if needed
	}
};

const getDynamicSportingData = (id:ID, category:string) => {
	//console.log("URL - "+`${GETDynamicSportingData}/${id}?category=${encodeURIComponent(category)}`);
	const url = `${GETDynamicSportingData}/${id}?category=${encodeURIComponent(category)}`;

	return axios
		.get(url)
		.then((res) => res.data)
		.catch((error) => {
			console.error(error);
		});
};
const getdynamicKeyThemesData = ({ id, category, participant }) => {

	let url = `${GETdynamicKeyThemesData}/${id}?category=${encodeURIComponent(category)}`;

	if (participant != null) {
		url += `&participant=${encodeURIComponent(participant)}`;
	}

	return axios
		.get(url)
		.then((res) => res.data)
		.catch((error) => {
			console.error(error);
		});
};

const getkeyWordCount = (id:ID, search:string, participant:string|undefined, category:string|undefined) => {

	let url = `${GETkeyWordCount}/${id}?search=${encodeURIComponent(search)}`;

	if (participant) {
		url += `&participant=${encodeURIComponent(participant)}`;
	}

	if (category) {
		url += `&category=${encodeURIComponent(category)}`;
	}

	//console.log("URL - " + url);

	return axios
		.get(url)
		.then((res) => res.data)
		.catch((error) => {
			console.error(error);
			// Handle any errors that occurred during the API call
		});
};

export {
	getUsers,
	deleteUser,
	deleteSelectedUsers,
	getUserById,
	createUser,
	updateUser,
	getUsersView,
	getUserRoles,
	AddResponse,
	getCategory,
	AddSource,
	getSourceList,
	getResponse,
	deleteSectionHead,
	deleteSource,
	deleteResponse,
	createParticipant,
	deleteParticipant,
	editParticipant,
	editSource,
	editResponse,
	getAllUsersEmail,
	getDoc,
	getHeadingsList,
	processSource,
	getResponseHeadingsList,
	processResponse,
	editHeading,
	addHeading,
	AddParticipantModal,
	tagSource,
	updateTag,
	getPageData,
	getStaticdata,
	getDynamicSportingData,
	getkeyWordCount,
	getdynamicKeyThemesData,
	getId
};
