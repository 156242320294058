/** @format */

import { ListViewProvider, useListView } from "./core/ListViewProvider";
import {
	QueryRequestProvider,
	useQueryRequest,
	
} from "./core/QueryRequestProvider";
import {
	useQueryResponseHeadingData,
	useQueryResponseHeadingLoading,
	useQueryResponseHeading,
	useQueryResponseHeadingPagination,
	QueryResponseHeadingsProvider
} from "./core/QueryResponseProvider";
import { UsersListHeader } from "./components/header/UsersListHeader";
import { UsersTable } from "./table/UsersTable";
import { UserEditModal } from "./user-edit-modal/UserEditModal";
import { KTCard } from "../../../../../theme/helpers";
import { DeleteModal } from "../../../widgets/components/DeleteModal";
import { headingColumns } from "./table/columns/_heading";
import { deleteResponse, getResponseHeadingsList } from "./core/_requests";
import { useMemo, useEffect, useState } from "react";
import { stringifyRequestQuery } from "../../../../../theme/helpers";
import { Toast } from "./Toast";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
const ResponseHeadingList = () => {
	const queryClient = useQueryClient();
	const {
		setItemIdForDelete,
		setItemIdForUpdate,
		itemIdForUpdate,
		itemIdForDelete,
		toastMessage,
		setToastMessage,
	} = useListView();
	const onDelete = () => {
		setItemIdForDelete(null);
	};
	
	const columns = useMemo(() => headingColumns(onDelete), []);
	const responses = useQueryResponseHeadingData();
	const isLoading = useQueryResponseHeadingLoading();
	const { isFetching } = useQueryResponseHeading();
	const data = useMemo(() => responses, [responses]);
	const pagination = useQueryResponseHeadingPagination();
	const { updateState, state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);
	interface ResponseData {
		data: {
		  participants: string[];
		  sentiments: {
			sectionHead: string;
			sentiment: string;
		  }[];
		};
		payload: {
		  pagination: {
			page: number;
			from: number;
			to: number;
			total: number;
			links: {
			  url: string | null;
			  label: string;
			  active: boolean;
			  page: number | null;
			}[];
			last_page: number;
			first_page_url: string;
			next_page_url: string | null;
			prev_page_url: string | null;
			items_per_page: string;
		  };
		};
	  }
	  const { respid } = useParams();
	  const {
		refetch,
		data: resp,
	  } = useQuery<ResponseData>(
		[`GETRESPONSEHEADINGlIST-${query}`],
		async () => {
		  const response = await getResponseHeadingsList(respid, query, "COMPLETE");
		  return response as unknown as ResponseData;
		},
		{
		cacheTime: 0,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		refetchOnMount: true, // or use refetchOnWindowFocus: true
	  }
	  );

	const updatePage = (page: number | null) => {
		if (!page || isLoading || pagination.page === page) {
			return;
		}
		updateState({ page, items_per_page: pagination.items_per_page || 10 });
	};

	const handleDelete = async () => {
		await deleteResponse(itemIdForDelete?.responseId);
		setItemIdForDelete(undefined);
		setToastMessage("Response Deleted Successfully");
		setTimeout(() => {
			setToastMessage(undefined);
		}, 2000);
		queryClient.invalidateQueries([`responsesectionhead-list-${query}`]);
	};

	return (
		<><div >
			<KTCard className="mt-10">
				<UsersListHeader title="" placeholder="Search" />
				<div className="px-9 pt-3">
					<span className="text-muted fw-bolder  mb-0  fs-6  ">
						{pagination.total !== 0
							? `Displaying ${pagination?.from || 0} to ${pagination?.to || 0
							} of
					${pagination?.total || 0} records`
							: `Displaying 0 to 0 of 0 records`}
					</span>
				</div>
				<UsersTable
					columns={columns}
					users={data}
					isLoading={isLoading}
					isFetching={isFetching}
					onNextPage={updatePage}
					pagination={pagination}
					id={"targetList1"} data={[]}				/>
			</KTCard>
			</div>
			{itemIdForUpdate !== undefined && (
				<UserEditModal
					content={itemIdForUpdate ? "edit_response" : "add_response"}
					modalText={itemIdForUpdate ? "Edit Response" : "Add Response"}
					onCloseEditModal={() => setItemIdForUpdate(undefined)}
					user={itemIdForUpdate}
				/>
			)}
			{itemIdForDelete !== undefined && <DeleteModal onDelete={handleDelete} />}
			{toastMessage !== undefined && <Toast title={toastMessage} />}
		</>
	);
};

const ResponseDetailTableWrapper = () => (
	<QueryRequestProvider>
		<QueryResponseHeadingsProvider>
			<ListViewProvider>
				<ResponseHeadingList  />
			</ListViewProvider>
		</QueryResponseHeadingsProvider>
	</QueryRequestProvider>
);

export { ResponseDetailTableWrapper };
