// DrilledPage.tsx
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LikeDislikeCard from "./LikeDislikeCard";
import KeyThemeCard from "./Keythemes";
import SentimentsCard from "./Sentiments";
import HorizontalBar from "./HorizontalBar";
import EngagementTable from "./EngagementTable";
import EngagementCluster from "./EngagementCluster";
import KeyThemesBarTable from "../KeyThemesBarTable";
import {
  getStaticdata,
  getkeyWordCount,
} from "../../../modules/apps/user-management/users-list/core/_requests";
import { useQueryRequest } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider";
import { stringifyRequestQuery } from "../../../../theme/helpers";
import { useListView } from "../../../modules/apps/user-management/users-list/core/ListViewProvider";
import BarGraph from "./Bargraph";
import { PageLink, PageTitle } from "../../../../theme/layout/core";
import CategoryKeyThemesBubble from "./CategoryKeyThemeBubble";
import { UserEditModal } from "../../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import SentimentBargraph from "./SentimentBargraph";
import CategoryParticipantTable from "./CategoryParticipantTable";
import CategoryPieChart from "./CategoryPiechart";
import "./Inputstyle.css";
import TreeMap from "./TreeMap";
import SentimentBarChart from "../DynamicsentimentBargraph";
import FetchingSpinner from "../FetchingSpinners";

const DrilledPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const CategoryName: any = queryParams.get("x");
  const { id } = useParams();
  const [selectedSource, setSelectedSource] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [fileList, setFileList] = useState<any>([]);
  const [ismoreParticipantModalOpen, setIsmoreParticipantModalOpen] =
    useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isKeyIssueModalOpen, setIsKeyIssueModalOpen] = useState(false);
  useEffect(() => {
    // You can use the CategoryName as needed, e.g., fetch additional data based on CategoryName, etc.
  }, [CategoryName]);
  const [newdata, setNewData] = useState<any>([]);
  const { itemIdForUpdate } = useListView();
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);
  const handleCancelClick = () => {
    // Close the modal by setting modalVisible to false
    setModalVisible(false);
  };
  const openmodel = () => {
    setIsmoreParticipantModalOpen(true);
  };

  // keyword search functionality
  const handleSearchEvent = useCallback(
    async (event) => {
      const searchTerm = event.target.value;
      setSearchQuery(searchTerm);
      // Call the API to get search results based on the search term
      try {
        const response = await getkeyWordCount(
          id,
          searchTerm,
          undefined,
          CategoryName
        );
        const data = response?.data || [];
        setFileList(data);
        setModalVisible(true);
      } catch (error) {
        console.error(error);
      }
    },
    [selectedSource, searchQuery]
  );

  //fetch data from api
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const newData = await getStaticdata(id, null, CategoryName);
        setNewData(newData);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, CategoryName]);
  //open model functionality
  const openAddModal = () => {
    setIsKeyIssueModalOpen(true);
  };

  //filter props data from api
  const topParticipantsAlphabhetically = newdata?.data?.find(
    (item) => item.chartName === "topParticipantsAlphabhetically"
  )?.chartData;
  const topPartcipantByPages = newdata?.data?.find(
    (item) => item.chartName === "topPartcipantByPages"
  )?.chartData;
  const engagementCluster = newdata?.data?.find(
    (item) => item.chartName === "engagementCluster"
  )?.chartData;
  const topKeyThemes = newdata?.data?.find(
    (item) => item.chartName === "topKeyThemes"
  )?.chartData;
  const allKeyThemes = newdata?.data?.find(
    (item) => item.chartName === "allKeyThemes"
  )?.chartData;
  const participantsDropdown = newdata?.data?.find(
    (item) => item.chartName === "participantsDropdown"
  )?.chartData;
  const overAllSentimentsScore = newdata?.data?.find(
    (item) => item.chartName === "overAllSentimentsScore"
  )?.chartData;
  const assessPolarity = newdata?.data?.find(
    (item) => item.chartName === "assessPolarity"
  )?.chartData;
  const sourceDatas = newdata?.data?.find(
    (item) => item.chartName === "sourceDatas"
  )?.chartData;
  const participantsByLicense = newdata?.data?.find(
    (item) => item.chartName === "participantsByLicense"
  )?.chartData;
  const sectionalAnalysisCount = newdata?.data?.find(
    (item) => item.chartName === "sectionalAnalysisCount"
  )?.chartData;
  const topThreeSectionsTreeMap = newdata?.data?.find(
    (item) => item.chartName === "topThreeSectionsTreeMap"
  )?.chartData;
  const firstXValue =
    topThreeSectionsTreeMap && topThreeSectionsTreeMap[0]
      ? topThreeSectionsTreeMap[0].x
      : "";
  //const colors = ["#003F54", "#2330FE", "#2330FE", "#B8583A", "#4E7DB5", "#EF9D11"];
  const color = ["#004053", "#D2FF00", "#56BAB9"];
  const navigate = useNavigate();
  const onView = () => {
    navigate(`/viewDoc/view`, {
      state: {
        data: id,
      },
    });
  };
  const onSearchListView = (docId) => {
    navigate(`/viewDoc/view`, {
      state: {
        data: docId,
      },
    });
  };
  const extremeParticipantsByPages = newdata?.data?.find(
    (item) => item.chartName === "extremeParticipantsByPages"
  )?.chartData;

  const uniqueNames = new Set();
  // Iterate through the data and add unique names to the Set
  allKeyThemes?.forEach((item) => {
    uniqueNames?.add(item.name);
  });
  // Convert the Set to an array to get distinct names
  const distinctNames = Array.from(uniqueNames);
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <FetchingSpinner />
        </div>
      ) : (
        <>
          <div
            className=""
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "end",
              justifyContent: "flex-end",
            }}
          >
            <div className="mb-5" style={{ position: "relative" }}>
              <i
                className="bi bi-search"
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "white",
                  fontSize: "25px",
                }}
              ></i>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-300px text-white placeholder-white"
                style={{
                  backgroundColor: "#6E8E8F",
                  paddingLeft: "50px",
                  color: "white",
                }}
                placeholder="Search With Keyword"
                onKeyUp={handleSearchEvent}
                list="searchResults"
              />
            </div>

            {modalVisible && searchQuery && (
              <>
                <div
                  className="modal fade show d-block"
                  id="kt_modal_add_user"
                  role="dialog"
                  tabIndex={-1}
                  aria-modal="true"
                >
                  <div
                    className="position-absolute top-50 start-50 translate-middle bg-white rounded shadow p-3"
                    style={{
                      zIndex: 1,
                      width: "500px",
                      maxHeight: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <div
                      className="d-flex flex-column scroll-y me-n7 pe-7"
                      id="kt_modal_add_user_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_add_user_header"
                      data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      <div className="d-flex justify-content-end mb-3">
                        <button
                          title="close"
                          onClick={handleCancelClick}
                          className="btn btn-sm "
                          style={{ backgroundColor: "#2C48FF" }}
                        >
                          <i className="fas fa-times text-white"></i>
                        </button>
                      </div>
                      <a href="#">
                        <table className="table table-hover mb-0 fs-6 ms-4">
                          <thead>
                            <tr>
                              <th scope="col">File Name</th>
                              <th scope="col">Participant Name</th>
                              <th scope="col">Count</th>
                            </tr>
                          </thead>
                          {fileList.length > 0 ? (
                            <tbody>
                              {fileList.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    className="text-hover-primary file-name"
                                    style={{
                                      maxWidth: "220px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    <a
                                      onClick={() =>
                                        onSearchListView(item?.responseId)
                                      }
                                    >
                                      {item?.fileName}
                                    </a>
                                  </td>
                                  <td
                                    className="participant-name"
                                    style={{
                                      maxWidth: "150px",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {item?.participantName.join(", ")}
                                  </td>
                                  <td>{item?.count}</td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <td colSpan={3}>
                              <div
                                className="d-flex text-center w-100 justify-content-center"
                                style={{ marginLeft: "-30px" }}
                              >
                                {"No matching records found"}
                              </div>
                            </td>
                          )}
                        </table>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop fade show"></div>
              </>
            )}
            <span className="svg-icon svg-icon-1 position-relative top-50 end-0 translate-middle-y pe-2"></span>
          </div>

          <table
            className="w-100"
            style={{ borderCollapse: "collapse", tableLayout: "fixed" }}
          >
            <tbody>
              <tr>
                <td
                  style={{
                    verticalAlign: "top",
                    padding: "10px",
                    width: "33%",
                  }}
                >
                  <h3 style={{ color: "#333333", margin: "0" }}>TITLE</h3>
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                    padding: "10px",
                    width: "33%",
                    paddingLeft: "25px",
                  }}
                >
                  <h3 style={{ color: "#333333", margin: "0" }}>
                    ISSUING AUTHORITY
                  </h3>
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                    padding: "10px",
                    width: "34%",
                    paddingLeft: "35px",
                  }}
                >
                  <h3 style={{ color: "#333333", margin: "0" }}>
                    CATEGORY NAME
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top", padding: "10px" }}>
                  <div style={{ whiteSpace: "pre-line", maxWidth: "300px" }}>
                    <b
                      className="fs-5"
                      style={{ fontSize: "14px", color: "#6E8E8F", margin: 0 }}
                    >
                      <div
                        style={{ whiteSpace: "pre-line", maxWidth: "330px" }}
                      >
                        <b
                          className="fs-5"
                          style={{
                            fontSize: "14px",
                            color: "#6E8E8F",
                            margin: 0,
                            textTransform: "uppercase",
                          }}
                        >
                          {sourceDatas && sourceDatas[0]?.value}
                        </b>
                      </div>
                      <button
                        type="button"
                        className="btn text-white mt-3"
                        title="View Document"
                        onClick={onView}
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor: "#003F54",
                          padding: "0.25em 1em",
                          lineHeight: "1",
                        }}
                      >
                        View Document
                      </button>
                    </b>
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                    padding: "10px",
                    paddingLeft: "25px",
                  }}
                >
                  <div style={{ whiteSpace: "pre-line", maxWidth: "300px" }}>
                    <b
                      className="fs-5"
                      style={{
                        fontSize: "14px",
                        color: "#6E8E8F",
                        margin: 0,
                        textTransform: "uppercase",
                      }}
                    >
                      {sourceDatas && sourceDatas[1]?.value}
                    </b>
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                    padding: "10px",
                    paddingLeft: "35px",
                  }}
                >
                  <div style={{ whiteSpace: "pre-line", margin: 0 }}>
                    <div
                      className="d-flex fs-5 mb-5 align-items-center justify-content-start"
                      style={{
                        color: "#6E8E8F",
                        fontSize: "20px",
                        textTransform: "uppercase",
                      }}
                    >
                      <b>{CategoryName}</b>
                    </div>
                    <h3 style={{ color: "#333333", marginBottom: "10px" }}>
                    SUBMISSIONS RECEIVED
                    </h3>
                    <div
                      className="d-flex align-items-center fs-5 justify-content-start"
                      style={{
                        color: "#6E8E8F",
                        fontSize: "20px",
                        textTransform: "uppercase",
                      }}
                    >
                      <b>{sourceDatas && sourceDatas[2]?.value}</b>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="row gy-5 gx-lg-8 mb-5">
            <div className="col-lg-4 p-4">
              <LikeDislikeCard data={sourceDatas} chartID={"polarity-one"} />
            </div>
            <div className="col-lg-4 p-4">
              <KeyThemeCard data={sourceDatas} chartID={"keytheme"} />
            </div>
            <div className="col-lg-4 p-4">
              <SentimentsCard data={sourceDatas} chartID={"section1"} />
            </div>
          </div>

          <div className="row mt-7">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ color: "white", fontSize: "22px" }}
            >
              <b
                className="p-2 fs-4"
                style={{ backgroundColor: "#003F54", width: "100%" }}
              >
                Participation Analysis
              </b>
            </div>
            <div
              className=" card col-lg-6 mt-10 ms-4"
              style={{
                backgroundColor: "#004053",
                width: "48%",
                height: "490px",
              }}
            >
              <CategoryParticipantTable
                chartID="table"
                title={"Participants (alphabetical)"}
                data={topParticipantsAlphabhetically}
              />
              <div>
                <div>
                  {topParticipantsAlphabhetically?.length > 10 && (
                    <Link
                      className="align-items-start justify-content-start ms-3"
                      onClick={openmodel}
                      style={{
                        color: "#D8FE25",
                        textDecoration: "none",
                        display: "inline-block",
                        borderBottom: "0.4pt solid #D8FE25",
                      }}
                      to={`/drilled-page/${id}?x=${CategoryName}`}
                    >
                      More Participants
                    </Link>
                  )}
                  {ismoreParticipantModalOpen == true && (
                    <UserEditModal
                      content={
                        itemIdForUpdate
                          ? "edit_response"
                          : "more_participanttable"
                      }
                      modalText={
                        itemIdForUpdate ? "Edit Response" : "Participant Table"
                      }
                      onCloseEditModal={() =>
                        setIsmoreParticipantModalOpen(false)
                      }
                      user={topParticipantsAlphabhetically}
                      data={topParticipantsAlphabhetically}
                      id={id}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="card mt-10 ms-9 col-lg-6 text-primary"
              style={{ width: "47%" }}
            >
              <div className="row">
                <CategoryPieChart
                  chartID="piechart1"
                  data={participantsByLicense}
                  title={"Participation by License Category"}
                />
              </div>
            </div>

            <div className="row mt-7 mb-10">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ color: "white", fontSize: "22px" }}
              >
                <b
                  className="p-2 fs-4"
                  style={{ backgroundColor: "#003F54", width: "100%" }}
                >
                  Engagement Analysis by number of pages
                </b>
              </div>
              <div className="col-lg-6  mt-10" style={{ width: "50%" }}>
                <div className="" style={{ marginTop: "", marginRight: "" }}>
                  <EngagementTable data={topPartcipantByPages} />
                </div>
              </div>
              <div
                className=" mt-10 card col-lg-6 ms-8"
                style={{ width: "47%" }}
              >
                <div
                  className="p-4 mt-4 fs-3 d-flex align-items-center justify-content-start"
                  style={{
                    color: "#769495",
                    fontSize: "18px",
                    marginBottom: "-150px",
                  }}
                >
                  <h4>Engagement By Category</h4>
                </div>
                <BarGraph
                  chartID="engagementBar18"
                  id={id}
                  category={CategoryName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <EngagementCluster
                  data={engagementCluster}
                  className="pt-20"
                  title={""}
                  extremeParticipantsByPages={extremeParticipantsByPages}
                />
              </div>
            </div>

            <div className="row mt-7 mb-5">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ color: "white", fontSize: "22px" }}
              >
                <b
                  className="p-2 fs-4"
                  style={{ backgroundColor: "#003F54", width: "100%" }}
                >
                 Analysis of Participant Concerns
                </b>
              </div>
              <div
                className=" card mt-10 col-lg-6 p-5 ms-4"
                style={{ maxWidth: "48%" }}
              >
                <KeyThemesBarTable
                  chartID="topKeyThemes3"
                  data={topKeyThemes}
                  title={"Top 3 Concerns Raised"}
                />
                <div style={{ marginTop: "160px" }}>
                  {distinctNames?.length > 3 && (
                    <Link
                      className="d-flex align-items-start justify-content-start "
                      onClick={openAddModal}
                      to={`/drilled-page/${id}?x=${CategoryName}`}
                    >
                      More Key Issues
                    </Link>
                  )}
                  {isKeyIssueModalOpen == true && (
                    <UserEditModal
                      content={"add_KeyIssuesTable"}
                      modalText={
                        itemIdForUpdate ? "Edit Response" : "Table of Key Concerns"
                      }
                      onCloseEditModal={() => setIsKeyIssueModalOpen(false)}
                      user={allKeyThemes}
                      chartID="topKeyThemes1"
                      title={"Key Concerns"}
                      data={allKeyThemes}
                    />
                  )}
                </div>
              </div>
              <div
                className="col-lg-6 card ms-10 mt-10"
                style={{ width: "47%" }}
              >
                <div
                  className="p-4 mt-5 fs-3 d-flex align-items-start justify-content-start"
                  style={{ color: "#769495", fontSize: "18px" }}
                >
                  <h4>Key Concerns By Participants</h4>
                </div>
                <CategoryKeyThemesBubble
                  data={topKeyThemes}
                  participants={participantsDropdown}
                  chartID="graph"
                />
              </div>

              <div className="mt-7">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ color: "white", fontSize: "22px" }}
                >
                  <b
                    className="p-2 fs-4"
                    style={{ backgroundColor: "#003F54", width: "100%" }}
                  >
                    Polarity Analysis
                  </b>
                </div>
                <div className="col-lg-12 mt-10 text-primary">
                  <HorizontalBar
                    chartID="drillstack-bar-7"
                    data={assessPolarity}
                    height="200px"
                    colors={color}
                    title={"Assess level of Polarity"}
                  />
                </div>
                <div className=" card col-lg-12 mt-5 p-5">
                  <div
                    className="p-4 fs-3 d-flex align-items-start justify-content-start"
                    style={{ color: "#4E79A7", fontSize: "18px" }}
                  >
                    <h4>Overall Sentiments</h4>
                  </div>
                  <SentimentBargraph
                    title={"Overall Sentiments"}
                    chartID="point-graphnew"
                    data={overAllSentimentsScore}
                  />
                </div>

                <div className=" mt-7">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ color: "white", fontSize: "22px" }}
                  >
                    <b
                      className="p-2 fs-4"
                      style={{ backgroundColor: "#003F54", width: "100%" }}
                    >
                      Sectional Analysis
                    </b>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-6  mt-10 text-primary  pt-5"
                      
                    >
                    <div className="card pb-20" >
                      <TreeMap
                        data={topThreeSectionsTreeMap}
                        title={"Most Mentioned Sections"}
                        className={""}
                        chartID={"categoryTopsentiment"}
                      />
                      </div>
                    </div>
                    

                    <div className="col-lg-6 mt-10 pt-5 text-primary ps-8">
                    <div className="card">
                      <SentimentBarChart
                        defaultsection={firstXValue}
                        data={sectionalAnalysisCount}
                      />
                    </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const CategoryDashboard: FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const ResponseBoardBreadcrumb: Array<PageLink> = [
    {
      title: "Main Dashboard",
      path: `/dashboard/${id}`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Category-DrilledDown",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={ResponseBoardBreadcrumb}>
        Category-DrilledDown
      </PageTitle>
      <DrilledPage />
    </>
  );
};

export default CategoryDashboard;

export { CategoryDashboard };
