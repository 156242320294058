/** @format */

import { useEffect } from "react";
import { UserEditModalHeader } from "./UserEditModalHeader";
import { UserEditModalFormWrapper } from "./UserEditModalFormWrapper";
import { AddUserModalForm } from "./AddUserModalForm";
import AddParticipantModalForm from "./AddParticipantModalForm";
import ParticipantTable from "../../../../profile/ParticipantModel";
import { AddResponseModalForm } from "./AddResponseModalForm";
import { ResponseEditModalFormWrapper } from "./ResponseEditModalFormWrapper";
import { AddSourceModalForm } from "./AddSourceModelForm";
// import { CreateOrganizationModalForm } from "./CreateOrganizationModalForm";
// import { CreateDomainModalForm } from "./CreateDomainModalForm";
import { EditSourceModalForm } from "./EditSourceModalForm";
import { EditResponseModalForm } from "./EditResponseModalForm";
import { AddHeadingModalForm } from "./AddHeadingModalForm";
import { EditHeadingModalForm } from "./EditHeadingModalForm";
import TagSourceModalForm from "./TagSourceModalForm";
import KeyThemesBarTable from "../../../../../pages/dashboard/KeyThemesBarTable";
import KeyThemesBarGraph from "../../../../profile/SentimentModel";
import KeyThemesBarGraph1 from "../../../../profile/SentimentModel";
import { auto } from "@popperjs/core";
import MoreSentimentTable from "../../../../profile/Moresentiment";
import MoreCategoryParticipantTable from "../../../../profile/MoreParticipant";


const UserEditModal = (props: any) => {
	useEffect(() => {
		document.body.classList.add("modal-open");
		return () => {
			document.body.classList.remove("modal-open");
		};
	}, []);
	const chunkArray = (array, size) => {
		return array?.reduce((acc, _, index) => {
			if (index % size === 0) {
				acc?.push(array.slice(index, index + size));
			}
			return acc;
		}, []);
	};
	const groupedData = chunkArray(props.firstSixData, 3);


	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_add_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className={`modal-dialog modal-dialog-centered ${props.content === ("sentimentmodal") ? "ps-20" : "ps-20"} ${props.content === ("sentimentmodal") ? ("mw-1000px") : ("mw-800px")} `} style={{ overflowX: 'hidden' }}>
					{/* begin::Modal content */}
					<div className="modal-content">
						<UserEditModalHeader
							titleText={props.modalText}
							onCloseModal={props.onCloseEditModal}
						/>
						{/* begin::Modal body */}
						<div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
							{props.content === "add_user" ? (
								<AddUserModalForm
									onCancel={props.onCloseEditModal}
									user={props.user}
								/>
							) : props.content === "add_participant" ? (
								<AddParticipantModalForm
									onCancel={props.onCloseEditModal}
									user={props.user}
								/>
							) : props.content === "add_participanttable" ? (
								<ParticipantTable
									onCancel={props.onCloseEditModal}
									user={props.user}
									data={props.data}
									id={props.id}
								/>
							) : props.content === "more_participanttable" ? (
								<MoreCategoryParticipantTable
									onCancel={props.onCloseEditModal}
									user={props.user}
									data={props.data}
									id={"categoryTable"}
								/>
							)
								: props.content === "Sentimentmodal" ? (
									<MoreSentimentTable
										onCancel={props.onCloseEditModal}
										user={props.user}
										data={props.data}
										id={props.id}
									/>
								) : props.content === "sentimentmodal" ? (
									<div>
										{groupedData?.map((row, rowIndex) => (
											<div key={rowIndex} className="row">
												{row.map((chartDataItem, colIndex) => (
													<><div key={colIndex} className="col-xxl-4">
														<KeyThemesBarGraph1
															chartID={`topKeyThemes${rowIndex * 3 + colIndex}`}
															data={chartDataItem.chartData}
															title={chartDataItem.chartName} />

													</div>
													</>
												))}
											</div>
										))}
									</div>


								) : props.content === "add_KeyIssuesTable" ? (
									<KeyThemesBarTable
										onCancel={props.onCloseEditModal}
										user={props.user}
										data={props.data}
										chartID={props.chartID}
										title={"Key concerns"}
									/>
								) : props.content === "tag_source" ? (
									<TagSourceModalForm
										onCancel={props.onCloseEditModal}
										user={props.user}

									/>
								) : props.content === "create_Heading" ? (
									<AddHeadingModalForm
										onCancel={props.onCloseEditModal}
										user={props.user} isUserLoading={false} />
								) : props.content === "edit_Heading" ? (
									<EditHeadingModalForm
										onCancel={props.onCloseEditModal}
										user={props.user}
									/>
								) : props.content === "add_response" ? (
									<AddResponseModalForm
										onCancel={props.onCloseEditModal} user={props.user}
									/>
								) : props.content === "edit_response" ? (
									<EditResponseModalForm
										onCancel={props.onCloseEditModal} user={props.user}
									/>
								) : props.content === "add_source" ? (
									<AddSourceModalForm
										onCancel={props.onCloseEditModal} user={props.user}
									/>
								) : props.content === "edit_source" ? (
									<EditSourceModalForm
										onCancel={props.onCloseEditModal} user={props.user}
									/>
								) : (
									// <ResponseEditModalFormWrapper />
									<UserEditModalFormWrapper />
								)}
						</div>
						{/* end::Modal body */}
					</div>
					{/* end::Modal content */}
				</div>
				{/* end::Modal dialog */}
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop  fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};

export { UserEditModal };
