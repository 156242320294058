import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import KeyThemeCard from "../pages/dashboard/NewDashboardComponents/Keythemes";
import KeyThemesBarTable from "../pages/dashboard/KeyThemesBarTable";
import { getStaticdata, getkeyWordCount } from "../modules/apps/user-management/users-list/core/_requests";
import { stringifyRequestQuery } from "../../theme/helpers";
import { useQueryRequest } from "../modules/apps/user-management/users-list/core/QueryRequestProvider";
import { useListView } from "../modules/apps/user-management/users-list/core/ListViewProvider";
import SentimentTable from "../pages/dashboard/NewDashboardComponents/SentimentTable";
import { PageLink, PageTitle } from "../../theme/layout/core";
import BarChart from "../pages/dashboard/NewDashboardComponents/BarChart";
import CategoryCard from "../pages/dashboard/Category";
import EngagementCard from "../pages/dashboard/NewDashboardComponents/Engagement";
import { UserEditModal } from "../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import OverallSentimentCard from "../pages/dashboard/NewDashboardComponents/OverallSentiment";
import "./../pages/dashboard/NewDashboardComponents/HorizontalBar.css";
import FetchingSpinner from "../pages/dashboard/FetchingSpinners";

const ParticipantDetail = () => {
  const { participantName } = useParams();
  const [newdata, setNewData] = useState<any>([]);
  const { id } = useParams()
  const {
    itemIdForUpdate,
  } = useListView();
  const [isKeyIssueModalOpen, setIsKeyIssueModalOpen] = useState(false);
  const [isSectionalModalOpen, setIsSectionalModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileList, setFileList] = useState<any>([]);
  const Openmodal = () => {
    setIsSectionalModalOpen(true)
  }
  const openAddModal = () => {
    setIsKeyIssueModalOpen(true);
  };
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  // fetch data from api
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const newData = await getStaticdata(id, partcipant = participantName);
        setNewData(newData);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id]);
  //filter props data from api data
  let partcipant = newdata?.data?.length > 0 ? newdata.data[0].chartData : undefined;
  const allKeyThemes = newdata?.data?.find(
    (item) => item.chartName === "allKeyThemes"
  )?.chartData;
  const top4Data = allKeyThemes?.slice(0, 4);
  const participantSentiments = newdata?.data?.find(
    (item) => item.chartName === "participantSentiments"
  )?.chartData;
  const sentimentFrequencies = newdata?.data?.find(
    (item) => item.chartName === "sentimentFrequencies"
  )?.chartData;
  const participantMetaDatas = newdata?.data?.find(
    (item) => item.chartName === "participantMetaDatas"
  )?.chartData;
  const uniqueNames = new Set();
  allKeyThemes?.forEach(item => {
    uniqueNames?.add(item.name);
  });
  // Convert the Set to an array to get distinct names
  const distinctNames = Array.from(uniqueNames);
  const navigate = useNavigate();
  //view document
  const onView = () => {
    navigate(`/viewDoc/view`, {
      state: {
        data: id
      },
    });
  };
  const handleCancelClick = () => {
    setModalVisible(false);
  };

  //search with keywords functionality
  const handleSearchEvent = useCallback(async (event) => {
    const searchTerm = event.target.value;
    setSearchQuery(searchTerm);
    try {
      const response = await getkeyWordCount(id, searchTerm, participantName, undefined);
      const data = response?.data || [];
      setFileList(data);
      setModalVisible(true);
    } catch (error) {
      console.error(error);
    }
  }, [searchQuery]);

  useEffect(() => {
    // Your data-fetching logic here based on the participantName
    // For example, you can use the participantName to filter the data
    // and display the details for the specific participant.
  }, [participantName]);
  const onSearchListView = (docId) => {

    navigate(`/viewDoc/view`, {
      state: {

        data: docId

      },
    });
  };
  return (
    <div>
      {/* Render the details for the participant here */}
      {isLoading ? <div className="d-flex justify-content-center">
        <FetchingSpinner />
      </div> : <>
        <div className="fs-6" style={{ marginLeft: "auto", display: "flex", alignItems: "end", justifyContent: "flex-end" }}>
          <div className="mb-5" style={{ position: "relative" }}>
            <i
              className="bi bi-search"
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "white",
                fontSize: "25px"
              }}
            ></i>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-300px text-white placeholder-white"
              style={{ backgroundColor: "#6E8E8F", paddingLeft: "50px", color: "white" }}
              placeholder="Search With Keyword"
              onKeyUp={handleSearchEvent}
              list="searchResults"
            />
          </div>
          {(modalVisible && searchQuery) && (
            <>
              <div
                className="modal fade show d-block"
                id="kt_modal_add_user"
                role="dialog"
                tabIndex={-1}
                aria-modal="true"
              >
                <div className="position-absolute top-50 start-50 translate-middle bg-white rounded shadow p-5" style={{ zIndex: 1, width: "500px", maxHeight: "400px", overflowY: "auto" }}>
                  <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                    <div className="d-flex justify-content-end mb-3">
                      <button title="close" onClick={handleCancelClick} className="btn btn-sm  " style={{backgroundColor:"#2C48FF"}}>
                        <i className="fas fa-times text-white"></i>
                      </button>
                    </div>
                    <a href="#">
                      <table className="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th scope="col">File Name</th>
                            {/* <th scope="col">Participant Name</th> */}
                            <th scope="col">Count</th>
                          </tr>
                        </thead>
                        {fileList.length > 0 ? (
                          <tbody>
                            {fileList.map((item, index) => (
                              <tr key={index}>
                                <td className="text-hover-primary file-name" style={{ maxWidth: "220px", wordWrap: "break-word" }}>
                                  <a onClick={() => onSearchListView(item?.responseId)}>  {item?.fileName} </a>
                                </td>
                                <td>{item?.count}</td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <td colSpan={3}>
                            <div className="d-flex text-center w-100 justify-content-center" style={{ marginLeft: "-30px" }}>
                              {"No matching records found"}
                            </div>
                          </td>
                        )}
                      </table>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </>
          )}
          <span className="svg-icon svg-icon-1 position-relative top-50 end-0 translate-middle-y pe-2"></span>
        </div>
        <table className="w-100" style={{ borderCollapse: 'collapse', tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: 'top', padding: '10px', width: '25%' }}>
                <h3 style={{ color: "#333333", margin: '0' }}>TITLE</h3>
              </td>
              <td style={{ verticalAlign: 'top', padding: '10px', width: '25%' }}>
                <h3 style={{ color: "#333333", margin: '0' }}>ISSUING AUTHORITY</h3>
              </td>
              <td style={{ verticalAlign: 'top', padding: '10px', width: '25%' }}>
                <h3 style={{ color: "#333333", margin: '0' }}>PARTICIPANT NAME</h3>
              </td>
              <td style={{ verticalAlign: 'top', padding: '10px', width: '25%' }}>
                <h3 style={{ color: "#333333", margin: '0' }}>ADDRESS</h3>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'top', padding: '10px' }}>
                <div style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                  <b className="fs-3" style={{ fontSize: '14px', color: '#6E8E8F', margin: 0 }}>
                    <div style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                      <b className="fs-5" style={{ fontSize: '14px', color: '#6E8E8F', margin: 0, textTransform: "uppercase" }}>
                        {participantMetaDatas && participantMetaDatas[1]?.value}
                      </b>
                    </div>
                    <button
                      type="button"
                      className="btn text-white mt-3"
                      title="View Document"
                      onClick={onView}
                      style={{
                        whiteSpace: 'nowrap',
                        backgroundColor: "#003F54",
                        padding: '0.25em 1em', // Adjust the padding to control the height
                        lineHeight: '1' // Set line-height to 1 for equal height
                      }}>
                      View Document
                    </button>
                  </b>
                </div>
              </td>
              <td style={{ verticalAlign: 'top', padding: '10px' }}>
                <div style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                  <b className="fs-5" style={{ fontSize: '14px', color: '#6E8E8F', margin: 0, textTransform: "uppercase" }}>
                    {participantMetaDatas && participantMetaDatas[0]?.value}
                  </b>
                </div>
              </td>
              <td style={{ verticalAlign: 'top', padding: '10px' }}>
                <div style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                  <div
                    className="d-flex fs-5 align-items-start justify-content-start" // Align items at the start (left)
                    style={{ color: "#6E8E8F", fontSize: "20px", textTransform: "uppercase" }}
                  >
                    <b> {participantName}</b>
                  </div>
                  <button
                    type="button"
                    className="btn text-white mt-3"
                    title="View Document"
                    onClick={() => onSearchListView(participantMetaDatas && participantMetaDatas[10]?.value)}
                    style={{
                      whiteSpace: 'nowrap',
                      backgroundColor: "#003F54",
                      padding: '0.25em 1em', // Adjust the padding to control the height
                      lineHeight: '1' // Set line-height to 1 for equal height
                    }}>
                    View Document
                  </button>
                </div>
              </td>
              <td style={{ verticalAlign: 'top', padding: '10px' }}>
                <div style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                  <div
                    className="d-flex mb-5 fs-5 align-items-start justify-content-start" // Align items at the start (left)
                    style={{ color: "#6E8E8F", fontSize: "20px", textTransform: "uppercase" }}
                  >
                    <b> {participantMetaDatas && participantMetaDatas[8]?.value}</b>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row gy-5 gx-lg-8 mb-5" >
          <div className="col-lg-3">
            <CategoryCard data={participantMetaDatas} />
          </div>
          <div className="col-lg-3">
            <OverallSentimentCard data={participantMetaDatas} chartID={undefined} />
          </div>
          <div className="col-lg-3">
            <KeyThemeCard data={participantMetaDatas} chartID={"keythemes"} />
          </div>
          <div className="col-lg-3">
            <EngagementCard data={participantMetaDatas} />
          </div>
        </div>

        <hr
          className="d-flex align-items-center"
          style={{
            marginTop: "28px",
            border: "1px solid black",
            width: "100%",
          }} />
        <div className="row gy-4 gx-lg-8 mt-7">
          <div className="col-lg-6">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ color: "white", fontSize: "22px" }}
            >
              <b className="p-2 fs-4" style={{ backgroundColor: "#003F54", width: "100%" }}>Analysis of Participant Concerns</b>
            </div>
            <div className=" card  align-items-center justify-content-center" style={{  marginTop: "30px" }}>
              <KeyThemesBarTable chartID="topKeyThemes2" data={top4Data} />


            </div>
            <div style={{ textAlign: "center" }}>
          {distinctNames?.length > 5 && (
            <Link className="d-flex align-items-start justify-content-start me-8" onClick={openAddModal} to={""}>
              More Key Issues
            </Link>
          )}
          {isKeyIssueModalOpen && (
            <UserEditModal
              content={"add_KeyIssuesTable"}
              modalText={itemIdForUpdate ? "Edit Response" : "Key Issues Table"}
              onCloseEditModal={() => setIsKeyIssueModalOpen(false)}
              user={allKeyThemes}
              chartID="topKeyThemes2"
              title={"Key Concerns"}
              data={allKeyThemes}
            />)
          }
        </div>

          </div>


          <div className=" col-lg-6">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ color: "white", fontSize: "22px" }}
            >
              <b className="p-2 fs-4" style={{ backgroundColor: "#003F54", width: "100%" }}>Sentiment Analysis</b>
            </div>
            <div className="card mt-10" style={{height:"440px"}}><BarChart data={sentimentFrequencies} /></div>
            
          </div>
        </div>
        <hr
          className="d-flex align-items-center "
          style={{
            border: "1px solid black",
            width: "100%",
          }} />
        <div className="row gx-lg-8">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ color: "white", fontSize: "22px" }}
          >
            <b className="p-2 fs-4" style={{ backgroundColor: "#003F54", width: "100%" }}>Sectional Analysis</b>
          </div>
          <div className="col-xxl-12" style={{ marginRight: "-40px" }}>
            <div className=" card mt-5 row gx-xxl-0" style={{height:"540px"}}>
              <div className="col-xxl-12 w-100 ms-8">
                <SentimentTable
                  chartID="donutChaart2"
                  title={""}
                  data={participantSentiments}
                />
                <div>
                  {participantSentiments?.length > 10 && (
                    <Link
                      className="  align-items-start justify-content-start"
                      style={{ marginLeft: "80px",textDecoration: "none",
                      display: "inline-block",
                      borderBottom: "0.4pt solid #0095EC" }}
                      onClick={Openmodal}
                      to={""}
                    >
                      More sentiments
                    </Link>
                  )}
                  {isSectionalModalOpen == true && (
                    <UserEditModal
                      content={itemIdForUpdate ? "edit_response" : "Sentimentmodal"}
                      modalText={itemIdForUpdate ? "Edit Response" : "Sentiment Model"}
                      onCloseEditModal={() => setIsSectionalModalOpen(false)}
                      data={participantSentiments}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </div>
  );
};
const ParticipantDashboard: FC = () => {
  const { id } = useParams()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const xValue = queryParams.get("x");
  const { participantName } = useParams();
  const { newword } = useParams();
  const isdash = window.location.href;
  const ResponseBoardBreadcrumb: Array<PageLink> = [
    {
      title: "Main Dashboard",
      path: `/dashboard/${id}`,
      isSeparator: false,
      isActive: true,
    },

    {
      title: "Individual Participant Dashboard",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  const participantBoardBreadcrumb: Array<PageLink> = [
    {
      title: "Main Dashboard",
      path: `/dashboard/${id}`,
      isSeparator: false,
      isActive: true,
    },
    {
      title: "Category-DrilledDown",
      path: `/drilled-page/${id}/?x=${newword}`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: "Individual Participant Dashboard",
      path: "",
      isSeparator: false,
      isActive: false,
    },
  ];
  return (
    <>
      {newword ||
        !isdash.includes(`${participantName}`)
        && xValue ?
        (<PageTitle
          breadcrumbs={participantBoardBreadcrumb}>
          Individual Participant Dashboard

        </PageTitle>) :
        (<PageTitle breadcrumbs={ResponseBoardBreadcrumb}>
          Individual Participant Dashboard
        </PageTitle>)}
      <ParticipantDetail />
    </>
  );
};

export { ParticipantDashboard };
