/** @format */

import { ListViewProvider, useListView } from "./core/ListViewProvider";
import {
	QueryRequestProvider,
	useQueryRequest,
} from "./core/QueryRequestProvider";
import {
	useQueryHeadingData,
	QuerySectionHeadingsProvider,
	useQueryHeadingLoading,
	useQueryHeadingPagination,
	useQueryHeading,
} from "./core/QueryResponseProvider";
import { useQueryClient } from "react-query";
import { UsersListHeader } from "./components/header/UsersListHeader";
import { UsersTable } from "./table/UsersTable";
import { UserEditModal } from "./user-edit-modal/UserEditModal";
import { KTCard } from "../../../../../theme/helpers";
import { DeleteModal } from "../../../widgets/components/DeleteModal";
import { headingColumns } from "./table/columns/_heading";
import { deleteSectionHead } from "./core/_requests";
import { useMemo, useEffect, useState } from "react";
import { stringifyRequestQuery } from "../../../../../theme/helpers";
import { Toast } from "./Toast";

const SectionHeadingList = () => {
	const queryClient = useQueryClient();
	const {
		setItemIdForDelete,
		setItemIdForUpdate,
		itemIdForUpdate,
		itemIdForDelete,
		toastMessage,
		setToastMessage,
	} = useListView();

	const onDelete = () => {
		setItemIdForDelete(null);
	};
	const columns = useMemo(() => headingColumns(onDelete), []);
	const responses = useQueryHeadingData();
	const isLoading = useQueryHeadingLoading();
	const { isFetching } = useQueryHeading();
	const data = useMemo(() => responses, [responses]);
	const pagination = useQueryHeadingPagination();
	const { updateState, state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const { refetch } = useQueryHeading();
	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const updatePage = (page: number | null) => {
		refetch();
		if (!page || isLoading || pagination.page === page) {
			return;
		}
		updateState({ page, items_per_page: pagination.items_per_page || 10 });
		refetch();
	};

	const handleDelete = async () => {
		await deleteSectionHead(itemIdForDelete?.sectionIndex, itemIdForDelete?.draftId);
		await queryClient.invalidateQueries([`GETHEADINGlIST-${query}`])
		setItemIdForDelete(undefined);
		setToastMessage("Section Heading Deleted Successfully");
		setTimeout(() => {
			setToastMessage(undefined);
		}, 2000);
	};

	return (
		<>
			<KTCard className="mt-10">
				<UsersListHeader title="Validate" placeholder="Search" />
				<div className="px-9 pt-3">
					<span className="text-muted fw-bolder  mb-0  fs-6  ">
						{pagination.total !== 0
							? `Displaying ${pagination?.from || 0} to ${pagination?.to || 0
							} of
					${pagination?.total || 0} records`
							: `Displaying 0 to 0 of 0 records`}
					</span>
				</div>
				<UsersTable
					columns={columns}
					users={data}
					isLoading={isLoading}
					isFetching={isFetching}
					onNextPage={updatePage}
					pagination={pagination} id={""} data={[]}				/>
			</KTCard>
			{itemIdForUpdate !== undefined && (
				<UserEditModal
					content={itemIdForUpdate?.action == 'edit' ? "edit_Heading" : "create_Heading"}
					modalText={itemIdForUpdate?.action == 'edit' ? "Edit Section" : "Add Section"}
					onCloseEditModal={() => setItemIdForUpdate(undefined)}
					user={itemIdForUpdate}
				/>
			)}
			{itemIdForDelete !== undefined && <DeleteModal onDelete={handleDelete} />}
			{toastMessage !== undefined && <Toast title={toastMessage} color="alert-success" />}
		</>
	);
};

const SectionHeadingWrapper = () => (
	<QueryRequestProvider>
		<QuerySectionHeadingsProvider >
			<ListViewProvider>
				<SectionHeadingList />
			</ListViewProvider>
		</QuerySectionHeadingsProvider>
	</QueryRequestProvider>
);

export { SectionHeadingWrapper };
