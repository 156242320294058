/** @format */

import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../theme/layout/core";
import ProfileDetails from "../../profile/ProfileDetails";
import ResponseSourceInfoWrapper from "./ResponseSourceInfo";
import ResponseFormat from "./users-list/ResponseFormat";
import { ResponseListWrapper } from "./users-list/ResponseList";
import { SourceListWrapper } from "./users-list/SourceList";
import ViewDoc from "./ViewDoc";
import { SectionHeadingWrapper } from "./users-list/SectionHeadingList";
import TaggingScreen from "../../tagging/TaggingScreen";
import { MainDashboard } from "../../../pages/dashboard/MainDashboard";

const BreadCrumb: Array<PageLink> = [
	{
		title: "",
		path: "",
		isSeparator: false,
		isActive: false,
	},
];

const SourceBoard = () => {
	const ResponseBoardBreadcrumb: Array<PageLink> = [
		{
			title: "Source List",
			path: "/source-management/",
			isSeparator: false,
			isActive: true,
		},
		{
			title: "Response List",
			path: "goBack",
			isSeparator: false,
			isActive: false,
		},
		{
			title: "Response Details",
			path: "",
			isSeparator: false,
			isActive: false,
		},
	];
	const TaggingBoardBreadcrumb: Array<PageLink> = [
		{
			title: "Source List",
			path: "/source-management/",
			isSeparator: false,
			isActive: true,
		},
		{
			title: "Response List",
			path: "goBack",
			isSeparator: false,
			isActive: false,
		},
		{
			title: "Source Tagging",
			path: "",
			isSeparator: false,
			isActive: false,
		},
	];
	const ResponseListBreadcrumb: Array<PageLink> = [
		{
			title: "Source List",
			path: "/source-management/",
			isSeparator: false,
			isActive: true,
		},
		{
			title: "Response List",
			path: "",
			isSeparator: false,
			isActive: false,
		},
	];
	return (
		<Routes>
			<Route element={<Outlet />}>
				<Route
					path="source"
					element={
						<>
							<PageTitle breadcrumbs={BreadCrumb}>Source List</PageTitle>
							<SourceListWrapper />
						</>
					}
				/>

				<Route
					path="source/:id"
					element={
						<>
							<PageTitle breadcrumbs={ResponseListBreadcrumb}>
								Response List
							</PageTitle>

							<ResponseSourceInfoWrapper />
							<ResponseListWrapper />
							<SectionHeadingWrapper />

						</>
					}
				/>
				<Route
					path="source/:id/view-details/:respid"
					element={
						<>
							<PageTitle breadcrumbs={ResponseBoardBreadcrumb}>
								Response Details
							</PageTitle>
							<ProfileDetails currentPath={"ResponseBoard"} />


						</>
					}
				/>

				<Route
					path="source/:id/format-response/:id"
					element={
						<>
							<ResponseFormat />
						</>
					}
				/>
				<Route
					path="source/view"
					element={
						<>
							<ViewDoc />
						</>
					}
				/>
				<Route
					path="source/:id/tagging"
					element={
						<>
							<PageTitle breadcrumbs={TaggingBoardBreadcrumb}>
								Source Tagging
							</PageTitle>
							<TaggingScreen />
						</>
					}
				/>
				
			</Route>
			<Route index element={<Navigate to="/source-management/source" />} />
		</Routes>
	);
};

export default SourceBoard;
