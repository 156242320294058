/** @format */

import {
	FC,
	useState,
	useEffect,
	createContext,
	useContext,
	useRef,
	Dispatch,
	SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../theme/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getUserByToken } from "./_requests";
import { WithChildren } from "../../../../theme/helpers";

type AuthContextProps = {
	auth: AuthModel | undefined;
	saveAuth: (auth: AuthModel | undefined) => void;
	currentUser: UserModel | undefined;
	accept:any;
	setAccept:any;
	username:any;
	setUsername:any;
	draft:any;
	userPassword:any;
	setUserPassword:any;
	show:any;
	setShow:any;
	setDraft:any;
	setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
	logout: () => void;
};

const initAuthContextPropsState = {
	auth: authHelper.getAuth(),
	saveAuth: () => {},
	currentUser: undefined,
	username:null,
	setUsername:() => {},
	userPassword:null,
	setUserPassword:() => {},
	accept:false,
	setAccept:() => {},
	draft:null,
	setDraft:() => {},
	show:false,
	setShow:() => {},
	setCurrentUser: () => {},
	logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
	return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
	const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
	const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
	const [accept, setAccept] = useState<any>(true);
	const [draft, setDraft] = useState<any>(null);
	const [username, setUsername] =  useState(() => {
		const storedValue = localStorage.getItem('username');
		return storedValue ? JSON.parse(storedValue) : "karen_neith@trisysit.com";
	  });
	const [userPassword, setUserPassword] = useState(() => {
		const storedValue = localStorage.getItem('userPassword');
		return storedValue ? JSON.parse(storedValue) : "123";
	  });
	const [show, setShow] = useState(() => {
		const storedValue = localStorage.getItem('show');
		return storedValue ? JSON.parse(storedValue) : false;
	  });
	const saveAuth = (auth: AuthModel | undefined) => {
		setAuth(auth);
		if (auth) {
			authHelper.setAuth(auth);
		} else {
			authHelper.removeAuth();
		}
	};

	const logout = () => {
		saveAuth(undefined);
		setCurrentUser(undefined);
	};
	useEffect(() => {
		// Save the 'show' state to localStorage whenever it changes
		localStorage.setItem('show', JSON.stringify(show));
	  }, [show]);
	  useEffect(() => {
		// Save the 'show' state to localStorage whenever it changes
		localStorage.setItem('username', JSON.stringify(username));
	  }, [username]);
	  useEffect(() => {
		// Save the 'show' state to localStorage whenever it changes
		localStorage.setItem('userPassword', JSON.stringify(userPassword));
	  }, [userPassword]);
	return (
		<AuthContext.Provider
			value={{username,setUsername,userPassword,setUserPassword,show, setShow, draft,setDraft,accept,setAccept,auth, saveAuth, currentUser, setCurrentUser, logout }}
		>
			{children}
		</AuthContext.Provider>
	);
};

const AuthInit: FC<WithChildren> = ({ children }) => {
	const { auth, logout, setCurrentUser, currentUser } = useAuth();
	const didRequest = useRef(false);
	const [showSplashScreen, setShowSplashScreen] = useState(true);
	// We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
	useEffect(() => {
		const requestUser = async (apiToken: string) => {
			try {
				if (!didRequest.current) {
					const { data } = await getUserByToken(apiToken);

					if (data) {
						setCurrentUser(data);
					}
				}
			} catch (error) {
				console.error(error);
				if (!didRequest.current) {
					logout();
				}
			} finally {
				setShowSplashScreen(false);
			}

			return () => (didRequest.current = true);
		};

		if (auth && auth.token) {
			requestUser(auth.token);
		} else {
			logout();
			setShowSplashScreen(false);
		}
		// eslint-disable-next-line
	}, []);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
