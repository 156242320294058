/** @format */

import { title } from "process";
import { SectionHeadingWrapper } from "../../SectionHeadingList";
import { useListView } from "../../core/ListViewProvider";
import { UsersListToolbar } from "./UserListToolbar";
import { UsersListGrouping } from "./UsersListGrouping";
import { UsersListSearchComponent } from "./UsersListSearchComponent";
type props={
	title:string,
	placeholder:string,
}
const UsersListHeader = (props:props) => {
	const { selected } = useListView();
	function SectionHeadingList() {
		throw new Error("Function not implemented.");
	}

	return (
		<div className="card-header border-0 pt-6">
			<UsersListSearchComponent placeholder= {props.placeholder}/>
			{/* begin::Card toolbar */}
			{<div className="card-toolbar">
				{/* begin::Group actions */}
				{selected.length > 0 ? (
					<UsersListGrouping />
				) : (
					 <UsersListToolbar title={props.title} />
				)}
				{/* end::Group actions */}
			</div>}
			{/* end::Card toolbar */}
		</div>
	);
};

export { UsersListHeader };
