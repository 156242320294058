/** @format */

import { Dispatch, SetStateAction } from "react";

export type ID = undefined | null | number | boolean | any;
export type DocumentData = {
	documentType: string;
	subject: string;
	documentName: string;
}
export type Participant = {
	participantName: string;
	address: string;
	country: string;
	sector: string;
	regNo: string;
	date: string;
	title: string;
	categoryGeneral: string;
	licensingCategory: string;
	supportingDocuments: string;
	natureOfService: string;
  };
  

export type TagData = {
	participantName: string;
	address: string;
	country: string;
	sector: string;
	title: string;
	governmentGazetteNumber: string;
	noticenoAndYear: string;
	deadlineForComment: string;
	gazetted: string;
	processType: string;
	supportingDocuments: string;
	keyTerms: string; // You may want to specify a data type other than string if necessary
	empoweringLegislation: string;
	empoweringSections: string;
	draftId: string | undefined; // Use the actual data type of params?.id
	dateOfIssue: string;
	governmentGazetteDate: string;
	issuingAuthority: string;
};
export type ChartData = {
	name: string;
	generalCategory: string;
	licenseCategory: string;
	country: string;
	sector: string;
}

export type CategoryData = {
	color: string;
	x: string;
	y: number;
}

export type SportsBodyData = {
	category: string;
	value: number;
	color: string;
}

export type LicenseData = {
	name: string;
	value: string | number;
	category: string | null;
	color: string | null;
}

export type ClusterData = {
	name: string;
	data: number[][];
	color: string | null;
}
export type SourceData = {
	name: string;
	value: string;
	category: string | null;
	color: string | null;
}

export type AssesPolarityCategory = {
	category: string;
	value: number;
	color: string;
}
export type CategoryDropdownForSport = string[];


export type ExtremeParticipantsByPages = {
	name: string;
	value: string;
	category: null | string;
	color: null | string;
};

export type KeyThemeData = {
	name: string;
	participants: string;
	value: number;
}
export type PolarityChartData = {
	name: string;
	value: string;
	category: null | string;
	color: null | string;
}
export type OverallSentimentsScoreData = {
	sentiment: string;
	participants: string[];
}
export type SectionData = {
	chartName: string;
	categoryName: null;
	chartData: SectionItemData[];
}

export type SectionItemData = {
	name: string;
	value: number;
}
export type SectionalCountData = {
	[sectionName: string]: number[];
}
export type TopThreeSectionData = {
	x: string;
	y: number;
}
export type Staticdata = {
	code: string | number | null;
	data: Array<{
		chartName: string;
		categoryName: string | null;
		chartData: ChartData[] | CategoryData[] | SportsBodyData[] | LicenseData[] | ClusterData[] | CategoryDropdownForSport | ExtremeParticipantsByPages[] | KeyThemeData[] | AssesPolarityCategory[] | PolarityChartData[] | OverallSentimentsScoreData[] | SectionData[] | SectionalCountData[] | TopThreeSectionData[] | [] | any;
		// Add a discriminator property to identify the actual data type
		dataType: 'ChartData' | 'CategoryData' | 'SportsBodyData' | 'LicenseData' | 'ClusterData' | 'CategoryDropdownForSport' | 'ExtremeParticipantsByPages' | 'KeyThemeData' | 'AssesPolarityCategory' | 'PolarityChartData' | 'OverallSentimentsScoreData' | 'SectionData' | 'SectionalCountData' | 'TopThreeSectionData' | 'EmptyData';
	}>;
	payload: null;
};
export type KeywordSearchData = {
	responseId: string;
	responseName: string | null;
	fileName: string;
	participantName: string[]; // Assuming it's an array of strings
	count: number;
};

export type responseData = {
	assignedTo: null | string;
	fileName: string;
	filePath: string;
	header: null | string;
	noOfPagesAnnotated: null | number;
	organisationId: null | string;
	organisationName: null | string;
	pages: string;
	participant: string[];
	participantId: null | string;
	responseId: string;
	status: string;
	uploadedBy: string;
	uploadedOn: string;
};
export type PaginationState = {
	total: number;
	from: number;
	to: number;
	page: number;
	items_per_page: 10 | 30 | 50 | 100;
	links?: Array<{
		label: string;
		active: boolean;
		url: string | null;
		page: number | null;
	}>;
};

export type SortState = {
	sort?: string;
	order?: "asc" | "desc";
};

export type FilterState = {
	filter?: unknown;
};

export type SearchState = {
	search?: string;
};

export type Response<T> = {
	total: boolean;
	limit?: any;
	skip: any;
	users?: any;
	data?: T;
	payload?: {
		message?: string;
		errors?: {
			[key: string]: Array<string>;
		};
		pagination?: PaginationState;
	};
};

export type QueryState = PaginationState &
	SortState &
	FilterState &
	SearchState;

export type QueryRequestContextProps = {
	state: QueryState;
	updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
	page: 1,
	items_per_page: 10,
	total: 0,
	from: 0,
	to: 0
};

export const initialQueryRequest: QueryRequestContextProps = {
	state: initialQueryState,
	updateState: () => { },
};

export type QueryResponseContextProps<T> = {
	response?: Response<Array<T>> | undefined | any;
	refetch: () => void;
	isLoading: boolean;
	isFetching: boolean;
	query: string;
};

export const initialQueryResponse = {
	refetch: () => { },
	isLoading: false,
	isFetching: false,
	query: "",
};
export type sectionName = {
	action: string ; // Use the specific action types you expect
	draftId: string;
	sectionHeadName: string;
	sectionIndex: string;
  };

export type ListViewContextProps = {
	selected: Array<ID>;
	onSelect: (selectedId: ID) => void;
	onSelectAll: () => void;
	clearSelected: () => void;
	// NULL => (CREATION MODE) | MODAL IS OPENED
	// NUMBER => (EDIT MODE) | MODAL IS OPENED
	// UNDEFINED => MODAL IS CLOSED
	show?: boolean;
	setShow?: Dispatch<SetStateAction<boolean | undefined>>;
	//tableShow?: boolean | undefined;
	//setTableShow: Dispatch<SetStateAction<boolean | undefined>>;
	itemIdForUpdate?: ID;
	itemIdForDelete?: ID;
	toastMessage?: ID;
	setToastMessage: Dispatch<SetStateAction<ID>>;
	setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
	setItemIdForDelete: Dispatch<SetStateAction<ID>>;
	setItemIdForAddSubmission?: Dispatch<SetStateAction<ID>>;
	isAllSelected: boolean;
	disabled: boolean;
};

export const initialListView: ListViewContextProps = {
	selected: [],
	onSelect: () => { },
	onSelectAll: () => { },
	clearSelected: () => { },
	setItemIdForUpdate: () => { },
	setToastMessage: () => { },
	setShow: () => { },
	//setTableShow:()=>{},
	setItemIdForDelete: () => { },
	setItemIdForAddSubmission: () => { },
	isAllSelected: false,
	disabled: false,
};
