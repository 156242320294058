import React from "react";

const SentimentTable = (props) => {
  const title = props.title;
  const data = props.data?.slice(0, 10);
  const getSentimentColor = (sentiment) => {
    switch (sentiment) {
      case "NoComment (0)":
        return "#4E7DB5"; // Green color for positive sentiment
      case "Disagree (-3)":
        return "#B54E4F"; // Orange color for neutral sentiment
      case "AgreeWithAmendments (2)":
        return "#A1B82E"; // Red color for negative sentiment
      case "RequestClarification (-1)":
        return "#EF9D11"
      default:
        return "#000000"; // Black color as default
    }
  };

  return (
    <>
      <div className="container p-3" style={{ height: "480px" }}>
        <div
          className="d-flex align-items-center justify-content-center  me-10 mb-2"
          style={{ color: "#4E79A7", fontSize: "20px" }}
        >
          {title}
        </div>
        <div className="table-responsive  p-4 ms-10" style={{ height: "500px" }}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th style={{ whiteSpace: "nowrap" }}>Sections</th>
                <th>Sentiments</th>
              </tr>
              <tr>
                <td style={{ border: "none", padding: "0" }}>
                  <hr style={{ marginTop: "2px", border: "0.5px solid black", width: "130%" }} />
                </td>
              </tr>
            </thead>

            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td>{item.sectionHead}</td>
                  <td style={{ color: getSentimentColor(item.sentiment) }}>
                    {item.sentiment}
                  </td>
                </tr>
              ))}
              <tr>
                <td style={{ border: "none", padding: "0" }}>
                  <hr style={{ marginTop: "2px", border: "0.5px solid black", width: "130%" }} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </>
  );
};

export default SentimentTable;
