import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ParticipantTable = (props) => {
  const data = props.data;
  const id =props.id;
  const navigate = useNavigate();

  const handleParticipantClick = (participantName) => {
    // Navigate to the detail page with the participant's name as a parameter
   {id && navigate(`/dashboard/${id}/${encodeURIComponent(participantName)}`)};
  };

  return (
    <div className="card bg-transparent  px-6 d-flex" style={{ height: "450px",marginTop:"-30px" }}>
      <div>
        <div
          className="p-3"
          style={{
            backgroundSize: "200%",
            backgroundPosition: "0% 0%",
            animation: "gradientAnimation 10s ease infinite",
            height: "500px",
            width: "100%",
          }}
        >
          {data?.map((item, index) => (
            <div className="row gy-4 gx-xl-12 d-flex align-items-center" key={index}>
              {/* Use the handleParticipantClick function to navigate when participant name is clicked */}
              <div
                className="col-xl-6 text-fw-semibold text-hover-primary"
                onClick={() => handleParticipantClick(item.name)}
                style={{ cursor: "pointer" }}
              >
                <b>{item.name}</b>
              </div>
              <div className="col-xl-6" style={{ color: "#909495" }}>
                {item.generalCategory}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ParticipantTable;
