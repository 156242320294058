/** @format */

import React, { Component, useState } from "react";
// import { CKEditor } from "@ckeditor/ckeditor5-react"
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ResponseFormat = () => {
	const [formattedData, setFormattedData] = useState(
		localStorage.getItem("KEY") || "hello ...."
	);

	const SaveInLocal = () => {
		localStorage.setItem("KEY", formattedData);
		alert("Saved Successfully!");
	};
	return (
		<div className="App">
			<CKEditor
				editor={ClassicEditor}
				data={formattedData || "hello i am empty"}
				onReady={(editor: any) => {
					// You can store the "editor" and use when it is needed.
				}}
				onChange={(event: any, editor: any) => {
					const data = editor.getData();
					setFormattedData(data);
				}}
				onBlur={(event: any, editor: any) => {}}
				onFocus={(event: any, editor: any) => {}}
			/>

			<div className="mt-10 d-flex justify-content-center">
				<button className="btn btn-success" onClick={SaveInLocal}>
					Save
				</button>
			</div>
		</div>
	);
};

export default ResponseFormat;
