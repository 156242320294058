/** @format */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDoc } from "./users-list/core/_requests";
import * as authHelper from "../../auth/core/AuthHelpers";
import "./ViewDoc.css";
import FetchingSpinner from "../../../pages/dashboard/FetchingSpinners";

const ViewDoc = () => {
	const auth = authHelper.getAuth();
	const location: any = useLocation();
	const [state,setState]=useState(true)
	// console.log(location?.state?.data,"idddd")
	async function getDocView() {
		try {
		  const response:any= await getDoc(location?.state?.data, auth?.token);
		  console.log("API Response:", response);
	
		} catch (error) {
		  console.error("Error fetching document:", error);
		} finally {
			setState(false);
		}
	  }
	
	  useEffect(() => {
		getDocView();
	  }, [state]); 

	return (
		<>
			{state?<div className="d-flex justify-content-center">
        <FetchingSpinner />
      </div>:<div
				id="view-doc"
				style={{
					height: "100vh",
					marginLeft: "-3rem",
					marginRight: "-2.3rem",
					marginTop: "-4.1rem",
				}}
			>
				<iframe src="" width="100%" height="100%" title="view_doc"></iframe>
			</div>}
		</>
	);
};

export default ViewDoc;
