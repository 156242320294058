import { type } from "os";
import React from "react";
import { LicenseData } from "../../../../theme/helpers";
type props = {
  data: LicenseData[];
}

const EngagementTable = (props: props) => {
  const data = props.data;

  return (
    <div className=" card  p-8" style={{ height: "500px",backgroundColor:"#DCEBEC",width:"100%" }}>
      <div className="fs-3 d-flex align-item-start justify-content-start  " style={{ color: "black", fontSize: "18px" }}>
        <h4 className="">Most Engaged</h4>
      </div>
      <div className=" pt-6">
        <div className="row gx-xl-12 gy-5 d-flex align-items-center">
          <div className="col-lg-12 " style={{ marginLeft: "-50px" }}>
            {/* Array of column titles */}
            <div className="row gy-4 align-items-center mb-2">
              <div className="col align-items-center text-fw-semibold" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginLeft: "60px" }}>
                <b>Participants</b>
              </div>
              <div className="col  d-flex align-item-end justify-content-end text-fw-semibold" >
                <b>Length of Submission</b>
              </div>
            </div>
            <hr className="d-flex ms-20 align-items-center text-fw-semibold" style={{ marginTop: "2px", border: "0.5px solid black", width: "90%" }} />

            {/* Data rendering with titles */}
            {data?.map((item, index) => (
              <div className="row gy-4 align-items-center mb-2 pe-14" key={index}>
                <div className="col text-fw-semibold mb-2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginLeft: "60px" }}>
                  {item.name}
                </div>
                <div className="d-flex col align-item-end justify-content-end me-5 mb-2" style={{ color: "#788FF7", }}>
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngagementTable;
