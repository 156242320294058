/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const loginSchema = Yup.object().shape({
	
        password: Yup.string()
		.matches(/^(?=.*[a-z_A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,15}$/, "Password should be between 6 to 15 characters with a mix of letters, numbers & symbols")
		.label("")
		.max(15, "maximum 15 characters allowed")
		.required("Enter new password"),
	confirmpassword: Yup.string()
		.required("Confirm your password")
		.oneOf([Yup.ref("password")], "Password must be same"),
});

const initialValues = {
	otp: "",
	password: "",
	confirmpassword: "",
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ResetPassword() {
	const [loading, setLoading] = useState(false);
	const { saveAuth, setCurrentUser } = useAuth();
	const [type, settype] = useState("password");

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			console.log(values, "VALLLLL");
			//   setLoading(true)
			//   try {
			//     const {data: auth} = await login(values.email, values.password)
			//     saveAuth(auth)
			//     // const {data: user} = await getUserByToken(auth.api_token)
			//     setCurrentUser(auth)
			//   } catch (error) {
			//     console.error(error)
			//     saveAuth(undefined)
			//     setStatus('The login details are incorrect')
			//     setSubmitting(false)
			//     setLoading(false)
			//   }
		},
	});

	return (
		<div className="card  p-20 cardSize">
			<form
				className="form w-100 "
				onSubmit={formik.handleSubmit}
				noValidate
				id="kt_login_signin_form"
			>
				{/* begin::Heading */}
				<div className="text-center mb-11 mt-1">
					<h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
				</div>

				{formik.status ? (
					<div className="mb-lg-15 alert alert-danger">
						<div className="alert-text font-weight-bold">{formik.status}</div>
					</div>
				) : null}

				{/* begin::Form group */}
				<div className="fv-row mb-8">
					<label className="required fw-bold fs-6 mb-2">
						{" "}
					     New Password
					</label>
					<div className="d-flex flex-row ">
						<input
							placeholder="New Password"
							{...formik.getFieldProps("password")}
							type={type}
							name="password"
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.password && formik.errors.password,
								},
								{
									"is-valid":
										formik.touched.password && !formik.errors.password,
								}
							)}
							autoComplete="off"
						/>
						{/* <i className="far fa-eye fa-3x"  style={{position:'absolute',right:30,marginTop:15}}></i> */}
						<div
							style={{
								position: "absolute",
								right: 60,
								marginTop: 10,
								paddingRight: 15,
                                cursor:'pointer',
							}}
							onClick={() =>
								type == "text" ? settype("password") : settype("text")
							}
						>
							{type == "password" ? (
								< FaEyeSlash size={20} title='Show' />
							) : (
								<FaEye size={20} title='Hide' />
							)}
						</div>
					</div>
					{formik.touched.password && formik.errors.password && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block fs-8">
								<span style={{width:'250px',whiteSpace:'pre-wrap',display:'inline-block'}} role="alert">{formik.errors.password}</span>
							</div>
						</div>
					)}

				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className="fv-row mb-7">
					<label className="required fw-bold fs-6 mb-2">Confirm Password</label>
					<input
						placeholder="Confirm Password"
						{...formik.getFieldProps("confirmpassword")}
						type="password"
						name="confirmpassword"
						className={clsx(
							"form-control form-control-light mb-3 mb-lg-0",
							{
								"is-invalid":
									formik.touched.confirmpassword &&
									formik.errors.confirmpassword,
							},
							{
								"is-valid":
									formik.touched.confirmpassword &&
									!formik.errors.confirmpassword,
							}
						)}
						autoComplete="off"
					/>
					{formik.touched.confirmpassword && formik.errors.confirmpassword && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block fs-8">
								<span role="alert">{formik.errors.confirmpassword}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Wrapper */}
				<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
					<div />

					
					{/* end::Link */}
				</div>
				{/* end::Wrapper */}

				{/* begin::Action */}
				<div className="d-grid">
					<Link to="/auth/login">
					<button
                        title="Submit"
						type="submit"
						id="kt_sign_in_submit"
						className="btn text-white w-100 btn-custom"
						style={{ backgroundColor: "#333E58" }}
						// disabled={formik.isSubmitting || !formik.isValid}
					>
						{!loading && <span className="indicator-label">Submit</span>}
						{loading && (
							<span className="indicator-progress" style={{ display: "block" }}>
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</button>
					</Link>
					
				</div>

				{/* end::Action */}

			
			</form>
		</div>
	);
}
