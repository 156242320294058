import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { ApexOptions } from "apexcharts";
import { getCSSVariableValue } from "../../../../theme/assets/ts/_utils";
type Props = any;

const BarChart: React.FC<Props> = (props) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const data=props.data || [];
  

  useEffect(() => {
    
    const categories = [
      ["Agree"],
      ["Agree With", "Amendments"],
      ["Disagree"],
      ["Disagree with ","Amendments"],
      ["No", "Comments"],
      ["Request", "Clarification"]
    ];
    const colors =  ["#004053", "#D4FC03", "#006D9B", "#56BAB9", "#3247F5", "#19247B"];

    const options: ApexOptions = {
      series: [{ name: 'No of times expressed',data: data }],
      chart: {
        height: 350,
        type: "bar",
        toolbar: { show: false },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors,
      plotOptions: {
        bar: {
          columnWidth: "40%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories,
        labels: {
          style: {
            //colors: colors,
            fontSize: "10px",
          },
        },
      },
      yaxis: {
        tickAmount: 7,
       
        labels: {
          show: true,
          style: {
            colors: getCSSVariableValue("--bs-gray-500"),
            fontSize: "13px",
          },
        },
        title: {
          text: "Counts",
          style: {
            //color: getCSSVariableValue("--bs-dark-500"),
            fontSize: "16px",
            fontWeight: 600,
          },
        },
      },
    };
    

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <div id="chart" ref={chartRef} />;
};

export default BarChart;
