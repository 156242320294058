import React from "react";
import tags from "./../Assets/lightbulb.png";

const KeyThemeCard = ({ data, chartID }) => {
  const inputString = data?.[5]?.value;
  const keyissues = data?.[6]?.value;
  const KeyTheme = inputString
    ? inputString
        .slice(1, -1)
        .split(",")
        .map((str) => str.trim())
    : [];

  // Sort the array in descending order based on the number of occurrences of each element
  const sortedThemes = KeyTheme.sort((a, b) => {
    const occurrencesA = a.split(" ").length;
    const occurrencesB = b.split(" ").length;
    return occurrencesB - occurrencesA;
  });
  const topThreeThemes = sortedThemes.slice(0, 3);

  return (
    <div
      className="card p-5 d-flex rounded"
      style={{
        backgroundColor: "#cfe2e3",

        height: "100%",
        width: chartID === "key" || chartID === "keytheme" ? "100%" : "100%",
      }}
    >
      <div className="ps-5 mb-3 d-flex align-items-center">
        <img
          src={tags}
          height="35px"
          width="35px"
          alt="logo"
          loading="lazy"
          // style={{ transform: "rotate(-90deg)" }}
        />
        <div className="">
          <h2
            className="text-black d-flex align-items-center pt-2 mx-4 text-xxl"
            style={{
              fontWeight: "10px",
              fontSize: "17px",
              whiteSpace: "nowrap", // Prevent line wrapping
            }}
          >
            {chartID === "key" ? "KEY CONCERNS" : "KEY CONCERNS"}
          </h2>
        </div>
      </div>
      <div className="text-white" style={{ marginTop: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 0.2"
          width="100%"
          style={{ display: "block", lineHeight: "0" }}
        >
          <line x1="0" y1="1" x2="36" y2="1" stroke="grey" strokeWidth="2" />
        </svg>
      </div>

      {(chartID === "keytheme" || chartID === "key") && (
        <div className="ps-6 pt-5 ">
          {topThreeThemes?.length > 0 ? (
            topThreeThemes?.map((KeyThemeElement, index) => (
              <div
                key={index}
                className={`${chartID === "key" ? "fs-1 " : "fs-1"} ${
                  chartID === "key" ? " " : " "
                }`}
                style={{ color: "black" }}
              >
                {KeyThemeElement}
              </div>
            ))
          ) : (
            <div className="fs-1" style={{ color: "black" }}>
              No data to display(criteria not matched)
            </div>
          )}
        </div>
      )}
      {chartID === "keythemes" && (
        <div className="ps-6 pt-10  " style={{overflow:"hidden"}}>
          {keyissues ? (
            <span className="fs-1 text-black" style={{ color: "", }}>
              {keyissues?.slice(1, -1)}
            </span>
          ) : (
            <div className="fs-2" style={{ color: "black" }}>
              No data to display(criteria not matched)
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KeyThemeCard;
