import React, { useState } from "react";
import Tagging from "./Tagging";
import TaggingForm from "./TaggingForm";
import { useQuery } from "react-query";
import { getPageData, tagSource } from "../apps/user-management/users-list/core/_requests";
import { useParams } from "react-router-dom";

const TaggingScreen = () => {
  const [selectedText, setSelectedText] = useState<string | undefined>();
  const { id } = useParams();

  const {
    isLoading: pageDataLoading,
    isFetching: pageDataFetching,
    data: pageData,
  } = useQuery(
    ["page-list", id],
    async () => {
      const response:any = await getPageData(id);
      return response.data.data;
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const {
    isLoading: tagDataLoading,
    isFetching: tagDataFetching,
    data: tagData,
  } = useQuery(
    ["page", id],
    async () => {
      const response = await tagSource(id);
      return response;
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: true }
  );

  const handleMouseUp = () => {
    const selectedText = window.getSelection()?.toString();
    setSelectedText(selectedText);
  };

  if (pageDataLoading || tagDataLoading || pageDataFetching || tagDataFetching) {
    // Render loading state or spinner while fetching data
    return <div>Loading...</div>;
  }
  console.log("PageData",pageData);
  if (!pageData || !tagData) {
    return <div>No data available</div>; // You can display a message or handle this case accordingly
  }

  return (
    <div className="d-flex w-100 justify-content-between">
      <div className="d-flex flex-column gap-10">
        {pageData?.map((item, index) => (
          <div
            className=""
            style={{
              overflowY: "scroll",
              height: "70vh",
              width: "21cm",
              overflowX: "hidden",
            }}
            key={index}
          > 
            <Tagging data={JSON.parse(item?.pageParsedRawText)} handleMouseUp={handleMouseUp} />
          </div>
        ))}
      </div>
      <div className="" style={{ height: "70vh" }}>
        <TaggingForm selectedText={selectedText} tagdata={tagData} />
      </div>
    </div>
  );
};

export default TaggingScreen;
