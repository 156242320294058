/** @format */

import { FC, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import clsx from "clsx";
import { AddSource} from "../core/_requests";
import { useListView } from "../core/ListViewProvider";
import { Doc } from "../core/_models";
import { useSourceQueryResponse } from "../core/QueryResponseProvider";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { stringifyRequestQuery } from "../../../../../../theme/helpers";

const validationSchema = Yup.object().shape({
	subject: Yup.string()
		.trim()
		.required("Subject is required")
		.min(3, "Enter at least 3 characters")
		.max(60, "Maximum 60 characters allowed")
		.matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),

	name: Yup.string()
		.trim()
		.required("Name is required")
		.min(3, "Enter at least 3 characters")
		.max(60, "Maximum 60 characters allowed")
		.matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),
	fileName: Yup.mixed()
		.required("File is required")
		.test(
			"fileType",
			"Please upload a file type: pdf",
			(value) => value && value.length > 0 && value[0].type === "application/pdf"
		)
		.test(
			"fileSize",
			"File size is greater than 50 MB",
			(value) => value && value.length > 0 && value[0].size <= 50 * 1024 * 1024
		),

});

const AddSourceModalForm: FC<any> = ({ user, isUserLoading, onCancel }) => {
	const btnRef = useRef<HTMLButtonElement | null>(null);
	const { setItemIdForUpdate } = useListView();
	const { setToastMessage } = useListView();
	const cancel = (withRefresh?: boolean) => {
		setItemIdForUpdate(undefined);
	};

	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const [type, settype] = useState("password");
	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	const { refetch } = useSourceQueryResponse();

	const HandleSubmit = async (values: any, { setSubmitting }) => {
		btnRef.current?.setAttribute("data-kt-indicator", "on");

		const docRequest: Doc = {
			documentType: "DRAFT",
			draftId: "",
			subject: values.subject.trim(),
			documentName: values.name.trim(),
			participantId: null,
			organisationId: null,
		};
		const file = values.fileName[0];

		setSubmitting(true);
		try {
			await AddSource(docRequest, file);
			setToastMessage("Source Added Successfully");
			cancel(true);
			setTimeout(() => {
				setToastMessage(undefined);
			}, 2000);
			refetch(); 
		} catch (error) {
			console.log(error);
		} finally {
			setSubmitting(false); // Set submitting to false
		}
	};


	const formik = useFormik({
		initialValues: {
			name: "",
			subject: "",
			fileName: undefined || "null",
		},

		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			HandleSubmit(values, { setSubmitting });
		},
	});

	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>
					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2"> Subject</label>
						<input
							placeholder="Subject"
							{...formik.getFieldProps("subject")}
							type="text"
							name="subject"
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid": formik.touched.subject && formik.errors.subject,
								},
								{
									"is-valid": formik.touched.subject && !formik.errors.subject,
								}
							)}
							autoComplete="off"
						></input>
						{formik.touched.subject && formik.errors.subject && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.subject}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">Name</label>
						<input
							placeholder="Name"
							{...formik.getFieldProps("name")}
							type="text"
							name="name"
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{ "is-invalid": formik.touched.name && formik.errors.name },
								{
									"is-valid": formik.touched.name && !formik.errors.name,
								}
							)}
							autoComplete="off"
						/>
						{formik.touched.name && formik.errors.name && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.name}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-7">
						<label className="required fw-bold fs-6 mb-2">File</label>
						<input
							{...formik.getFieldProps("fileName")}
							type="file"
							value={undefined}
							onChange={(e) => formik.setFieldValue("fileName", e.target.files)}
							className={clsx(
								"form-control form-control-light  mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.fileName && formik.errors.fileName,
								},
								{
									"is-valid":
										formik.touched.fileName && !formik.errors.fileName,
								}
							)}
							autoComplete="off"
						/>
						{formik.touched.fileName && formik.errors.fileName && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.fileName}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex justify-content-center">
						<button
							type="reset"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
							title="Discard"
						>
							Discard
						</button>

						<button
							ref={btnRef}
							type="submit"
							className="btn  me-10 text-white btn-custom"
							id="kt_button_1"
							data-kt-users-modal-action="submit"
							title="Submit"
						>
							<span className="indicator-label ">Submit</span>
							<span className="indicator-progress  ">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export { AddSourceModalForm };
