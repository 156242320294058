import React, { FC, useState, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useListView } from "../core/ListViewProvider";
import { useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../../theme/helpers";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { useQueryResponse, useQueryResponseHeading, useSourceQueryResponse } from "../core/QueryResponseProvider";
import {
  AddParticipantModal,
  createParticipant,
  editParticipant,
  tagSource,
} from "../core/_requests";


const editUserSchema = Yup.object().shape({
    issuingAuthority: Yup.string()
      .trim()
      .required("Name is required")
      .min(3, "Enter at least 3 characters")
      .max(60, "Maximum 60 characters allowed")
      .matches(/^\S+.*\S+$/, "Leading and trailing spaces are not allowed"),
    // address: Yup.string().trim().required("Address is required"),
    country: Yup.string().trim().required("Country is required"),
    sector: Yup.string().trim().required("Sector is required"),
    gazetted: Yup.boolean().required("Gazetted is required"),
    governmentGazetteNumber: Yup.string().when("gazetted", {
      is: true,
      then: Yup.string().trim().required("Government Gazette Number is required"),
      otherwise: Yup.string().trim(),
    }),
    governmentGazetteDate: Yup.string().when("gazetted", {
      is: true,
      then: Yup.string().trim().required("Government Gazette Date is required"),
      otherwise: Yup.string().trim(),
    }),
    noticeNoAndYear: Yup.string().when("gazetted", {
      is: true,
      then: Yup.string().trim().required("Notice No and Year is required"),
      otherwise: Yup.string().trim(),
    }),
    dateOfIssue: Yup.string().when("gazetted", {
      is: false,
      then: Yup.string().trim().required("Date of Issue is required"),
      otherwise: Yup.string().trim(),
    }),
    deadlineForComment: Yup.string().required("Deadline for Comment is required"),
    processType: Yup.string().required("Process Type is required"),
    supportingDocuments: Yup.string().required("Supporting Documents is required"),
  });
  


const TagSourceModalForm: FC<any> = ({
    user,
    isUserLoading,
    onCancel,
}) => {
    const queryClient = useQueryClient();
    const { setItemIdForUpdate, setToastMessage } = useListView();
    const { state } = useQueryRequest();
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
    const { id } = useParams();

    const cancel = (withRefresh?: boolean) => {
        setItemIdForUpdate(undefined);
    };

    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery);
        }
    }, [updatedQuery]);

    const { refetch } = useQueryResponse();

    const formik = useFormik({
        initialValues: {
            issuingAuthority: user?.issuingAuthority || "",
            //address: user?.address || "",
            country: user?.country || "",
            sector: user?.sector || "",
            genCat: user?.genCat || "",
            license: user?.license || "",
            gazetted: user?.gazetted || false,
            governmentGazetteNumber: user?.governmentGazetteNumber || "",
            governmentGazetteDate: user?.governmentGazetteDate || "",
            noticeNoAndYear: user?.noticeNoAndYear || "",
            dateOfIssue: user?.dateOfIssue || "",
            deadlineForComment: user?.deadlineForComment || "",
            processType: user?.processType || "",
            supportingDocuments: user?.supportingDocuments || "",
        },
        validationSchema: editUserSchema,
        onSubmit: async (values, { setSubmitting }) => {
            console.log("Form submitted");

            setSubmitting(true);

            const temp: any = {
                issuingAuthority: values.issuingAuthority,
                //address: values.address,
                country: values.country,
                sector: values.sector,
                gazetted: values.gazetted,
                governmentGazetteNumber: values.governmentGazetteNumber,
                governmentGazetteDate: values.governmentGazetteDate,
                noticeNoAndYear: values.noticeNoAndYear,
                dateOfIssue: values.dateOfIssue,
                deadlineForComment: values.deadlineForComment,
                processType: values.processType,
                supportingDocuments: values.supportingDocuments,
                draftId: user?.draftId // assuming it's a constant value
            };

            try {
                if (user) {
                    await tagSource(temp);
                    setToastMessage(" Source Taged Successfully");
                    cancel(true);
                    setTimeout(() => {
                        setToastMessage(undefined);
                    }, 2000);
                } else {
                    await tagSource(temp);
                    setToastMessage("Participant Created Successfully");
                    cancel(true);
                    setTimeout(() => {
                        setToastMessage(undefined);
                    }, 2000);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(false);
            }
        },
    });

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
    } = formik;

    return (
        <div>
            <form className="form-horizontal fv-row mb-3" onSubmit={handleSubmit}>
                <div className="modal-body ">
                    {/* Field: Address */}
                    <div className="form-group fw-bold fs-6 mb-7 ">
                        <label htmlFor="address" className="required fw-bold fs-6 mb-2">
                            Name of Issuing Authority
                        </label>
                        <input
                            placeholder="Name"
                            type="text"
                            id="address"
                            name="issuingAuthority"
                            value={values.issuingAuthority}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={clsx("form-control", {
                                "is-invalid": touched.issuingAuthority && errors.issuingAuthority,
                            })}
                        //autoComplete="off"
                        />
                        {touched.issuingAuthority && errors.issuingAuthority && (
                            <div className="invalid-feedback">{String(errors.issuingAuthority)}</div>
                        )}
                    </div>

                    {/* Field: Country */}
                    <div className="form-group fw-bold fs-6 mb-7">
                        <label htmlFor="country" className="required fw-bold fs-6 mb-2">
                            Country
                        </label>
                        <input
                            placeholder="Country"
                            type="text"
                            id="country"
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={clsx("form-control", {
                                "is-invalid": touched.country && errors.country,
                            })}
                            autoComplete="off"
                        />
                        {touched.country && errors.country && (
                            <div className="invalid-feedback">{String(errors.country)}</div>
                        )}
                    </div>

                    {/* Field: Sector */}
                    <div className="form-group fw-bold fs-6 mb-7">
                        <label htmlFor="sector" className="required fw-bold fs-6 mb-2">
                            Sector
                        </label>
                        <input
                            placeholder="Sector"
                            type="text"
                            id="sector"
                            name="sector"
                            value={values.sector}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={clsx("form-control", {
                                "is-invalid": touched.sector && errors.sector,
                            })}
                        //autoComplete="off"
                        />
                        {touched.sector && errors.sector && (
                            <div className="invalid-feedback">{String(errors.sector)}</div>
                        )}
                    </div>

                    {/* Field: General Category */}
                    {/* <div className="form-group fw-bold fs-6 mb-7">
                        <label htmlFor="genCat" className="required fw-bold fs-6 mb-2">
                            General Category
                        </label>
                        <select
                            id="genCat"
                            name="genCat"
                            value={values.genCat}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={clsx("form-select form-select-light mb-3 mb-lg-0", {
                                "is-invalid": touched.genCat && errors.genCat,
                            })}
                            autoComplete="off"
                        >
                            <option hidden={true} value="">
                                General category
                            </option>
                            <option value="Private Company">Private Company</option>
                            <option value="Private Individual">Private Individual</option>
                            <option value="Academic Institution">Academic Institution</option>
                            <option value="Government Department">Government Department</option>
                            <option value="Regulatory Authority">Regulatory Authority</option>
                            <option value="Civil Society Organisation">
                                Civil Society Organisation
                            </option>
                            <option value="Industry Association">Industry Association</option>
                        </select>
                        {touched.genCat && errors.genCat && (
                            <div className="invalid-feedback">{String(errors.genCat)}</div>
                        )}
                    </div> */}

                    {/* Field: Licensing Category */}
                    {/* <div className="form-group fw-bold fs-6 mb-7 ">
                        <label htmlFor="license" className="required fw-bold fs-6 mb-2">
                            Licensing Category
                        </label>
                        <select
                            id="license"
                            name="license"
                            value={values.license}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={clsx("form-select form-select-light mb-3 mb-lg-0", {
                                "is-invalid": touched.license && errors.license,
                            })}
                            autoComplete="off"
                        >
                            <option value="">Licensing category</option>
                            <option value="valueBroadcasting">Broadcasting</option>
                            <option value="Individual ECS and ECNS">
                                Individual ECS and ECNS
                            </option>
                            <option value="Class ECS">Class ECS</option>
                            <option value="Class ECNS">Class ECNS</option>
                            <option value="Not licensed by issuing authority">
                                Not licensed by issuing authority
                            </option>
                        </select>
                        {touched.license && errors.license && (
                            <div className="invalid-feedback">{String(errors.license)}</div>
                        )}
                    </div> */}

                    {/* Field: Gazetted */}
                    <div className="form-group fw-bold fs-6 mb-7">
                        <label htmlFor="gazetted" className="required fw-bold fs-6 mb-2">
                            Gazetted
                        </label>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                id="gazettedYes"
                                name="gazetted"
                                value="true"
                                checked={values.gazetted === true}
                                onChange={() => handleChange({ target: { name: 'gazetted', value: true } })}
                                onBlur={handleBlur}
                                className={clsx("form-check-input", {
                                    "is-invalid": touched.gazetted && errors.gazetted,


                                })}
                            />
                            <label htmlFor="gazettedYes" className="form-check-label fw-bold">
                                Yes
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                id="gazettedNo"
                                name="gazetted"
                                value="false"
                                checked={values.gazetted === false}
                                onChange={() => handleChange({ target: { name: 'gazetted', value: false } })}
                                onBlur={handleBlur}
                                className={clsx("form-check-input", {
                                    "is-invalid": touched.gazetted && errors.gazetted,

                                })}
                            />
                            <label htmlFor="gazettedNo" className="form-check-label fw-bold">
                                No
                            </label>
                        </div>
                    </div>





                    {/* Field: Government Gazette Number */}
                    {values.gazetted && (
                        <div className="form-group fw-bold fs-6 mb-7">
                            <label
                                htmlFor="governmentGazetteNumber"
                                className="required fw-bold fs-6 mb-2"
                            >
                                Government Gazette Number
                            </label>
                            <input
                                type="text"
                                id="governmentGazetteNumber"
                                name="governmentGazetteNumber"
                                value={values.governmentGazetteNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={clsx("form-control", {
                                    "is-invalid":
                                        touched.governmentGazetteNumber &&
                                        errors.governmentGazetteNumber,
                                })}
                            //autoComplete="off"
                            />
                            {touched.governmentGazetteNumber &&
                                errors.governmentGazetteNumber && (
                                    <div className="invalid-feedback">
                                        {String(errors.governmentGazetteNumber)}
                                    </div>
                                )}
                        </div>
                    )}

                    {/* Field: Government Gazette Date */}
                    {values.gazetted && (
                        <div className="form-group fw-bold fs-6 mb-7">
                            <label
                                htmlFor="governmentGazetteDate"
                                className="required fw-bold fs-6 mb-2"
                            >
                                Government Gazette Date
                            </label>
                            <input
                                type="text"
                                id="governmentGazetteDate"
                                name="governmentGazetteDate"
                                value={values.governmentGazetteDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={clsx("form-control", {
                                    "is-invalid":
                                        touched.governmentGazetteDate && errors.governmentGazetteDate,
                                })}
                            //autoComplete="off"
                            />
                            {touched.governmentGazetteDate && errors.governmentGazetteDate && (
                                <div className="invalid-feedback">
                                    {String(errors.governmentGazetteDate)}
                                </div>
                            )}
                        </div>
                    )}

                    {/* Field: Notice No and Year */}
                    {values.gazetted && (
                        <div className="form-group fw-bold fs-6 mb-7">
                            <label htmlFor="noticeNoAndYear" className="required fw-bold fs-6 mb-2">
                                Notice No and Year
                            </label>
                            <input
                                type="text"
                                id="noticeNoAndYear"
                                name="noticeNoAndYear"
                                value={values.noticeNoAndYear}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={clsx("form-control", {
                                    "is-invalid": touched.noticeNoAndYear && errors.noticeNoAndYear,
                                })}
                                autoComplete="off"
                            />
                            {touched.noticeNoAndYear && errors.noticeNoAndYear && (
                                <div className="invalid-feedback">{String(errors.noticeNoAndYear)}</div>
                            )}
                        </div>
                    )}

                    {/* Field: Date of Issue */}
                    {!values.gazetted && (
                        <div className="form-group fw-bold fs-6 mb-7">
                            <label htmlFor="dateOfIssue" className="required fw-bold fs-6 mb-2">
                                Date of Issue
                            </label>
                            <input
                                type="date"
                                id="dateOfIssue"
                                name="dateOfIssue"
                                value={values.dateOfIssue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={clsx("form-control", {
                                    "is-invalid": touched.dateOfIssue && errors.dateOfIssue,
                                })}
                                autoComplete="off"
                            />
                            {touched.dateOfIssue && errors.dateOfIssue && (
                                <div className="invalid-feedback">{String(errors.dateOfIssue)}</div>
                            )}
                        </div>
                    )}

                    {/* Field: Deadline for Comment */}
                    <div className="form-group fw-bold fs-6 mb-7">
                        <label htmlFor="deadlineForComment" className="required fw-bold fs-6 mb-2">
                            Deadline for Comment
                        </label>
                        <input
                            type="date"
                            id="deadlineForComment"
                            name="deadlineForComment"
                            value={values.deadlineForComment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={clsx("form-control", {
                                "is-invalid": touched.deadlineForComment && errors.deadlineForComment,
                            })}
                            autoComplete="off"
                        />
                        {touched.deadlineForComment && errors.deadlineForComment && (
                            <div className="invalid-feedback">{String(errors.deadlineForComment)}</div>
                        )}
                    </div>

                    {/* Field: Process Type */}
                    <div className="form-group fw-bold fs-6 mb-7">
                        <label htmlFor="processType" className="required fw-bold fs-6 mb-2">
                            Process Type
                        </label>
                        <select
                            id="processType"
                            name="processType"
                            value={values.processType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={clsx("form-select form-select-light mb-3 mb-lg-0", {
                                "is-invalid": touched.processType && errors.processType,
                            })}
                            autoComplete="off"
                        >
                            <option value="">Select Process Type</option>
                            <option value="Policy">Policy</option>
                            <option value="Legislation - new">Legislation - new</option>
                            <option value="Legislation - amendment">Legislation - amendment</option>
                            <option value="Regulation - new">Regulation - new</option>
                            <option value="Regulation - amendment">Regulation - amendment</option>
                            <option value="Discussion Document">Discussion Document</option>
                        </select>
                        {touched.processType && errors.processType && (
                            <div className="invalid-feedback">{String(errors.processType)}</div>
                        )}
                    </div>

                    {/* Field: Supporting Documents */}
                    <div className="form-group fw-bold fs-6 mb-7">
                        <label htmlFor="supportingDocuments" className="required fw-bold fs-6 mb-2">
                            Supporting Documents
                        </label>
                        <select
                            id="supportingDocuments"
                            name="supportingDocuments"
                            value={values.supportingDocuments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={clsx("form-select form-select-light mb-3 mb-lg-0", {
                                "is-invalid": touched.supportingDocuments && errors.supportingDocuments,
                            })}
                        //autoComplete="off"
                        >
                            <option value="">Select Supporting Documents</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        {touched.supportingDocuments && errors.supportingDocuments && (
                            <div className="invalid-feedback">{String(errors.supportingDocuments)}</div>
                        )}
                    </div>
                </div>

                <div className="d-flex justify-content-center align-item-center">
                    <button
                        type="button"
                        title="Discard"
                        className="btn btn-secondary mx-10"
                        onClick={onCancel}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        title="Add"
                        className="btn btn-custom text-white mx-10"
                        style={{ width: "92px" }}
                        disabled={isSubmitting}
                    //onClick={handleSubmit}
                    >
                        {isSubmitting ? "Submitting" : "Save"}
                    </button>

                </div>
            </form>
        </div>
    );
};

export default TagSourceModalForm;
