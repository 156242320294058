import * as CryptoJS from "crypto-js";

export const useStorage = {
  put: (key: string, value: any) => {
    const encryptString = CryptoJS.AES.encrypt(value, key).toString();
    return localStorage.setItem(key, encryptString);
  },
  get: (key: string) => {
    const data: any = localStorage.getItem(key);
    const decrypted = data ? CryptoJS.AES.decrypt(data, key) : null ;
    if (decrypted) {
      try {
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return JSON.parse(str);
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    }
  },
};
