import React, { useLayoutEffect, useState, useEffect, useCallback, useMemo } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { KTCard } from "../../../../theme/helpers";
import { useLocation, useParams } from "react-router-dom";
import * as am5xy from "@amcharts/amcharts5/xy";
import { getdynamicKeyThemesData } from "../../../modules/apps/user-management/users-list/core/_requests";
import tags from "../Assets/nodata.png";

function CategoryKeyThemesBubble(props) {
  const chartID = props.chartID;
  const participants = props.participants || [];
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const x = searchParams.get('x');
  const defaultParticipant = participants && participants.length > 0 ? participants[0] : "Select Participant";
  const [selectedParticipant, setSelectedParticipant] = useState(defaultParticipant);

  const [newdata, setNewData] = useState<{ name: string; value: number }[]>([]);
  const maxDataValue = useMemo(() => {
    return Math.max(...newdata.map((item) => item?.value));
  }, [newdata]);
  const fetchDataForParticipant = async (participantToFetch) => {
    try {
      const response = await getdynamicKeyThemesData({ id:id,
      category: x,
      participant:participantToFetch});
      const data = response?.data?.chartData || [];
      setNewData(data);
    } catch (error) {
      console.error(error);
    }
  };
  function NoDataMessage() {
    return (
      <div  className="d-flex flex-column align-items-center justify-content-center  ps-20" style={{ height: "200px", width: "100%", marginLeft: "-40px",marginBottom:"-300px",marginTop:"120px" }}>

        <div style={{ marginBottom: "10px" }}>
          <img
            src={tags}
            height="40px"
            width="40px"
            alt="logo"
          />
        </div>
        <div>
          <p className="fs-3">No data to display(criteria not matched)</p>
        </div>
      </div>
    );
  }
  useEffect(() => {
    // Fetch data for the default category when the component mounts
    fetchDataForParticipant(defaultParticipant);
  }, [defaultParticipant]);

  const handleParticipantSelectChange = useCallback(
    async (event) => {
      //const selectedCategory = event.target.value || defaultCategory;
      const selectedParticipant = event.target.value || defaultParticipant;
      setSelectedParticipant(event.target.value);
      fetchDataForParticipant(selectedParticipant);

      
    },
    [id,newdata,x,defaultParticipant]
  );

  useLayoutEffect(() => {
    // Create root and chart
    let root = am5.Root.new(chartID);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        pinchZoomX: true,
      })
    );

    // Add cursor
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    // Create axes
    let yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.grid.template.set("location", 0.5);
    yRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        //max: 15,
        maxDeviation: 0,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeWidth: 0,
          strokeOpacity: 0.3,
        }),
      })
    );

    xAxis.data.setAll(newdata);

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "name",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });

    yAxis.data.setAll(newdata);

    // Add series

    function createSeries(name, field) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          categoryYField: "name",
          valueXField: "value",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryY}: {valueX}",
          }),
        })
      );
      const colorMapping = {
        "National Association of Broadcasters (NAB)": "#4E79A7",
         "Internet Solutions (IS)": "#F28E2B",
         "Orbicom (Pty) Ltd (Orbicom)": "#E15759",
         participant4: "#76B7B2",
         participant5: "#59A14F",
       };

      series.bullets.push(() => {
        let value = (series.data as am5.ListData<any>).values[0]?.value;
        let categoryName = (series.data as am5.ListData<any>).values[0]?.categoryY;
        let bubbleColor = colorMapping[0] || "#19247B"
         // Get the value from the first data item
        let radius = 15; // Adjust the scaling factor as needed
        //if (isNaN(radius) || radius < 5) radius = 5;
        
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: radius,
            fill: bubbleColor,
          }),
        });
      });

      series.strokes.template.setAll({
        strokeWidth: 3,
        strokeOpacity: 0,
      });

      series.data.setAll(newdata);
      series.appear(1000);
    }

    createSeries("Participant", "value");

    chart.appear(1000, 100);
     // dispose logo
     root._logo?.dispose()
    return () => {
      root.dispose();
    };
  }, [chartID, newdata]);


  return (
    <KTCard>
      <div className="mt-5" style={{ display: "flex", flexDirection: "column" }}>
         
          <div style={{ alignSelf: "flex-end" }}>
            
            <select
              className="form-select"
              onChange={handleParticipantSelectChange}
            >
              {/* <option value="">Select participant</option> */}
              {participants.map((participant) => (
                <option key={participant} value={participant}>
                  {participant}
                </option>
              ))}
            </select>
          </div>
        
          {newdata.length==0 && <div><NoDataMessage/></div>}
        {newdata && <div id={chartID} style={{ height: "500px", width: "100%" }}></div>}
      </div>
    </KTCard>
  );
}

export default CategoryKeyThemesBubble;
