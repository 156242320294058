import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { ApexOptions } from "apexcharts";
import { useNavigate, useParams } from "react-router-dom";
import "./TreeMap.css";
import { CategoryData } from "../../../../theme/helpers";

const maxLabelLength = 10;

const TreeMap: React.FC<{
  className: string;
  title: string;
  data: CategoryData[] | undefined;
  chartID: string;

}> = ({ className, title, data, chartID }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showActualY, setShowActualY] = useState(false);

  const handleDataPointClick = (event: any, dataPoint: any) => {
    if (chartID === "category" && dataPoint.y !== 0) {
      navigate(`/drilled-page/${id}?x=${dataPoint.x}`);
    }
  };

  useEffect(() => {
    let chart;
    if (!chartRef.current || !data || !Array.isArray(data) || data.length === 0) {
      return;
    }
    chart = refreshChart(handleDataPointClick);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data]);

  const refreshChart = (clickHandler: (event: any, dataPoint: any) => void) => {
    if (!chartRef.current || !data || !Array.isArray(data) || data.length === 0) {
      return;
    }

    const height = parseInt(
      window.getComputedStyle(chartRef.current).height.replace("px", "")
    );
    const splitString = (inputString, maxLength) => {
      const substrings:any = [];
      for (let i = 0; i < inputString.length; i += maxLength) {
        substrings.push(inputString.substr(i, maxLength));
      }
      return substrings;
    };
    
    // Example usage
    let name = "dtfyguhj ytuij";
    const maxLabelLength = 8;
    const max = 38;
    
    // Split the name into an array of substrings with a maximum length
    const label = splitString(name, max);
    const options: ApexOptions = {
      series: [
        {
          data: data.map((item) => ({
            x:chartID === 'category'? item.x.slice(0, maxLabelLength - 3) + "..." : splitString(item.x,max),
            y: item.y !== 0 ? item.y : 1,
            color: item.color || "#000000",
            label: {
              text: item.x, // Use the full item.x value here
              position: "center",
              // offsetY: -20,
               style: {
            fontSize: 18,
            fontWeight: "bold",
            color: "#333",
            whiteSpace: 'normal',
            textOverflow: 'ellipsis', // You can experiment with this property
          },
            },
          })),
        },
      ],
      chart: {
        height: 423.5,
        type: "treemap",
        toolbar: { show: false },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedDataPoint = data[config.dataPointIndex];
            clickHandler(event, clickedDataPoint);
          },
        },
      },
      colors: ["#003F54", "#2330FE", "#93BDC1", "#D2FF00","#19247B","#DCD7C2","#006D9B"],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const dataPoint = data[dataPointIndex];
          const xValue = dataPoint.x || "";
          const yValue = dataPoint.y;
          if (showActualY) {
            return `<div class="apexcharts-tooltip-custom p-3">${xValue}<br> <div class="mt-4">No. of participant: ${yValue}</div></div>`;
          } else if (yValue === 0) {
            return `<div class="apexcharts-tooltip-custom p-3 ">${xValue}<br>No participant available</div>`;
          } else {
            return `<div class="apexcharts-tooltip-custom p-3">${xValue}<br> <div class="mt-4">No. of participant: ${yValue}</div></div>`;
          }
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    chartRef.current.addEventListener("mouseenter", () => {
      setShowActualY(true);
    });

    chartRef.current.addEventListener("mouseleave", () => {
      setShowActualY(false);
    });

    return chart;
  };

  return (
    <>
  <div className="card  fs-8 pt-5 " style={{height:"auto",width:"100%"}}>
  <div
    className="mt-4  d-flex fs-8 ms-10 flex-row align-items-start justify-start "
    style={{
      color: "black",
      // fontSize: "14px",
      // marginRight:"175px"
    }}
  >
    <h4>{title}</h4>
  </div>
  <div
    ref={chartRef}
    id={chartID}
    className={`ms-10  ${chartID}-chart `}
    style={{ height: "auto", minHeight: "423.5px",width:"88%" }}
  ></div>
</div>


    </>
  );
};

export default TreeMap;
