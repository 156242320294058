/** @format */

import { useState } from "react";
import { KTSVG } from "../../../../../../../theme/helpers";
import { useListView } from "../../core/ListViewProvider";
import { UsersListFilter } from "./UsersListFilter";
import { FaPlus } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
type props={
	title:string;
}

const UsersListToolbar = (props:props) => {
	const { setItemIdForUpdate } = useListView();
	const openAddUserModal = () => {
		setItemIdForUpdate(null);

	};
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);
	const [annotate, setAnnotate] = useState(false);
	const OCRProcess = () => {
		setLoader(true);
		setAnnotate(true);
		setTimeout(() => {
			setLoader(false);
		}, 3000);
	};
	const navigateFc = () => {
		// navigate("/annotation-management/", response);
	};

	return (
		<div
			className="d-flex justify-content-end"
			data-kt-user-table-toolbar="base"
			title={props.title}
		>
			{/* <UsersListFilter /> */}

			{/* begin::Export */}
			{/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
			{/* end::Export */}

			{/* begin::Add user */}
			{props.title && (props.title !== 'Validate') ? (<button
				type="button"
				className="btn text-white btn-custom"
				onClick={openAddUserModal}
			// style={{backgroundColor:"#333E58"}}

			>
				{/* <KTSVG
					path="/media/icons/duotune/arrows/arr075.svg"
					className="svg-icon-2"
				/> */}
				<FaPlus style={{ marginRight: '5' }} size={15} />
				{props.title || " "}
			</button>) :
				(props.title === 'Validate') && <div className="card-title me-0">
					{loader ? (
						<button
							className="btn text-white"
							style={{ backgroundColor: "#333E58" }}
							onClick={annotate ? navigateFc : OCRProcess}
							disabled={true}
						>
							Validating...
						</button>
					) : (
						<button
							title={annotate ? "Validated" : "Validate"}
							className={`btn text-white ${annotate ? "btn-success" : "btn-custom"}`}
							onClick={annotate ? navigateFc : OCRProcess}
						>
							{annotate ? "Validated" : "Validate"}
						</button>

					)}
				</div>}


			{/* end::Add user */}
		</div>
	);
};

export { UsersListToolbar };
