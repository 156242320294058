import React, { useEffect, useState } from "react";
import tags from "./Assets/nodata.png";

const KeyThemesBarTable = (props) => {
  const chartID = props.chartID;
  let title = props.title;
  let data = props.data;
  const [maxValue, setMaxValue] = useState(0);
  const [filteredData, setFilteredData] = useState<any>();

  // Nodatamessage
  function NoDataMessage() {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center  ps-20"
        style={{
          height: "200px",
          width: "100%",
          marginLeft:chartID=="topKeyThemes2"?"": "-50px",
          marginTop:chartID=="topKeyThemes3"?"170px":""
        }}
      >
        <div
          style={{
            marginBottom: "10px",
          }}
        >
          <img src={tags} height="40px" width="40px" alt="logo" />
        </div>
        <div>
          <p className="fs-3  d-flex inline-block">No data to display(criteria not matched)</p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    let max = 0;
    let groupedJSON: {
      name: string;
      data: { participants: string; value: number }[];
    }[] = [];

    data?.forEach(
      (item: { name: string; participants: string; value: number }) => {
        let existingGroup = groupedJSON.find(
          (group) => group.name === item.name
        );

        if (existingGroup) {
          existingGroup.data.push({
            participants: item.participants,
            value: item.value,
          });
        } else {
          groupedJSON.push({
            name: item.name,
            data: [{ participants: item.participants, value: item.value }],
          });
        }
      }
    );

    setFilteredData(groupedJSON);
    data?.forEach((item) => {
      if (item.value > max) {
        max = item.value;
      }
    });
    setMaxValue(max);
  }, [data, chartID]);

  const WIDTH = 200;

  return (
    <div
      className="card card-lg bg-transparent  px-6  rounded"
      style={{
        height: "440px",
        
        // marginLeft: chartID === "topKeyThemes3" ? "-80px" : chartID === "topKeyThemes1" ? "-20px" : "45px",
      }}
    >
      <div>
        <div
          className="d-flex mt-3 align-items-start justify-content-start"
          style={{
            color: "#769495",
            fontSize: "18px",
          }}
        >
          <h4> {title}</h4>
        </div>

        {data?.length === 0 ? (
          <NoDataMessage />
        ) : (
          <div
            className="p-3 mt-5 "
            style={{
              height: "500px",
              width:"95%",
              overflowY: "auto",
              // marginLeft: chartID === "topKeyThemes2" ? "0" : "0",
            }}
          >
            <table className="w-100">
              <thead>
                <tr className="pb-4">
                  <th className="w-40">Terms</th>
                  <th className="w-30  " >
                    {chartID === "topKeyThemes1" || chartID === "topKeyThemes3"
                      ? "Raised by"
                      : "count"}
                     <span  className="" style={{marginLeft:"207px"}}> {`No of mentions`}</span> 
                  </th>
                </tr>
              </thead>
              <tbody style={{ borderTop: "1px solid #CCCCCC" }}>
                {filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom: "1px solid #CCCCCC",
                    }}
                  >
                    <td
                      style={{
                        paddingRight: "30px",
                        paddingTop: "18px",
                        verticalAlign: "top",
                      
                      }}
                    >
                      {item?.name}
                    </td>
                    <td>
                      {item?.data?.map((subItem, subIndex) => {
                        const isEvenIndex = index % 2 === 0;
                        const barColors = ["#003F54","#D2FF00", "#19247B","#2330FE", "#93BDC1","#006D9B"]
                        const percentage = (subItem?.value / maxValue) * 100;

                        return (
                          <div key={subIndex} className="mb-5">
                            <div className="d-flex text-black align-items-center justify-content-between">
                              <span>
                                {subItem.participants.length > 20
                                  ? `${subItem.participants.substring(
                                      0,
                                      20
                                    )}...`
                                  : subItem.participants}
                              </span>
                              <span>{subItem?.value}</span>
                            </div>
                            <div
                              className="progress mt-2"
                              style={{
                                backgroundColor: "#EAF2F2",
                                height: "15px",
                                width: "370px",
                                borderRadius: "5px",
                              }}
                            >
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${percentage}%`,
                                  backgroundColor: barColors[subIndex % barColors.length],
                                  borderRadius: "5px",
                                }}
                                aria-valuenow={percentage}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default KeyThemesBarTable;
