/** @format */

import { FC, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import clsx from "clsx";

import { useQueryClient } from "react-query";
import {  addHeading,  editHeading } from "../core/_requests";
import { useListView } from "../core/ListViewProvider";
import {  useQueryHeading } from "../core/QueryResponseProvider";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { sectionName, stringifyRequestQuery } from "../../../../../../theme/helpers";
import { User } from "../core/_models";

const editUserSchema = Yup.object().shape({
	create_Heading : Yup.string()
		.trim()
		.required("Section Heading name is required")
		.min(3, "Enter at least 3 characters")
		//.max(60, "Maximum 60 characters allowed")
});
type props ={
	user:sectionName;
	isUserLoading:boolean;
	onCancel:React.MouseEvent<HTMLButtonElement> | any;

}

const AddHeadingModalForm: FC<props> = ({ user, isUserLoading, onCancel }) => {
	console.log(user,"hiiii")
	const btnRef = useRef<HTMLButtonElement | null>(null);
	const queryClient = useQueryClient();
	const { setItemIdForUpdate } = useListView();
	const { setToastMessage } = useListView();
	const cancel = (withRefresh?: boolean) => {
		setItemIdForUpdate(undefined);
	};

	const { state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
	const [type, settype] = useState("password");
	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);
	const { refetch } = useQueryHeading();

	const formik = useFormik({
		initialValues: {
			create_Heading: user?.sectionHeadName ? "" : "",
		},

		validationSchema: editUserSchema,
		// validateOnBlur: false,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			const temp = { draftId: user.draftId,
				sectionIndex: user?.sectionIndex,
				sectionHeadName: values?.create_Heading.trim(), }
			try {
				if (user?.action == 'edit') {
					await editHeading(temp,user?.sectionIndex
						);
					await queryClient.invalidateQueries([`GETHEADINGlIST-${query}`])
					setToastMessage("Section Heading   Updated Successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					//queryClient.invalidateQueries([`GETHEADINGlIST-${query}`]);
					refetch();
				} else {
					await addHeading(temp);
					//window.location.reload()
					refetch();
					 //queryClient.invalidateQueries([`GETHEADINGlIST-${query}`])

					
					
					setToastMessage("Section Heading added successfully");
					cancel(true);
					setTimeout(() => {
						setToastMessage(undefined);
					}, 2000);
					//queryClient.invalidateQueries([`GETHEADINGlIST-${query}`]);
					refetch();
				}
			} catch (ex) {
				console.error(ex);
			} finally {
				setSubmitting(true);
				// cancel(true);
			}
		},
	});
	return (
		<>
			<form
				id="kt_modal_add_user_form"
				className="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<div
					className="d-flex flex-column scroll-y me-n7 pe-7"
					id="kt_modal_add_user_scroll"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-max-height="auto"
					data-kt-scroll-dependencies="#kt_modal_add_user_header"
					data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
					data-kt-scroll-offset="300px"
				>

					<div className="fv-row mb-7">
						<label className="fw-bold fs-6 mb-2"> Section Heading</label>
						<input
							placeholder="Section Heading"
							{...formik.getFieldProps("create_Heading")}
							type="text"
							name="create_Heading"
							// value={undefined}
							//onChange={(e)=>formik.setFieldVAlue("name",e.target.name)}
							className={clsx(
								"form-control form-control-light mb-3 mb-lg-0",
								{
									"is-invalid":
										formik.touched.create_Heading && formik.errors.create_Heading,
								},
								{
									"is-valid":
										formik.touched.create_Heading && !formik.errors.create_Heading,
								}
							)}
							autoComplete="off"
						/>
						{formik.touched.create_Heading && formik.errors.create_Heading && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.create_Heading}</span>
								</div>
							</div>
						)}
					</div>

					<div className="d-flex justify-content-center">
						<button
							title="Discard"
							type="reset"
							className="btn btn-light mx-10"
							data-kt-users-modal-action="cancel"
							onClick={onCancel}
						>
							Discard
						</button>

						<button
							// ref={btnRef}
							title={user?.action == 'edit'? `Update` : `Add`}
							type="submit"
							className="btn  text-white btn-custom"
							id="kt_button_1"
							data-kt-users-modal-action="submit"
							style={{ backgroundColor: "#333E58" ,width:"92px" }}
						>
							<span className="indicator-label ">{user?.action == 'edit'? `Update` : `Add`}</span>
							<span className="indicator-progress  ">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					</div>
				</div>
			</form>
		</>
	);
};


export { AddHeadingModalForm };
