import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import { LicenseData } from "../../../../theme/helpers";

type Props = {
  chartID: string;
  data: LicenseData[];
  title: string;
};

function License(props: Props) {
  const { chartID, data = [], title } = props;
  const total = data.reduce((sum, item) => sum + Number(item.value), 0);

    // Calculate percentages and update data
    const dataWithPercentages = data?.map(item => ({
      ...item,
      percentage: ((Number(item?.value)/ total) * 100).toFixed(2),
    }));

  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);
    root.setThemes([am5themes_Animated.new(root)]);
    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
        radius: 70,
        innerRadius: 50,
      })
    );

    var series: any = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "value",
        categoryField: "name",
        alignLabels: false,
      })
    );
    series
      .get("colors")
      .set("colors", [
        am5.color(0x2330FE),
        am5.color(0xD2FF00),
        am5.color(0x004053),
        am5.color(0x4E79A7),
        am5.color(0xC9DEDE),
      ]);

    let tooltip: any = am5.Tooltip.new(root, {
      getFillFromSprite: true,
      labelText: "License category: {color}{name}\nNo. of Participant: {value}",
      text: am5.color(0x000000),
    });
    series.set("tooltip", tooltip);

    series.data.setAll(dataWithPercentages);
    var labelsTemplate = series.labels.template;

    labelsTemplate.setAll({
      text: `{percentage}%`,
      
    });
    series.states.create("hidden", {
      endAngle: -90,
    });

    // Enable word wrapping for the name in two lines if its length is greater
    labelsTemplate.events.on("sizechanged", function (event) {
      var textElement = event.target;
      var nameText = textElement.dataItem.dataContext.name;

      // Adjust the maximum width based on your requirements
      var maxWidth = 40;

      // Define the type explicitly for the nameLines array
      var nameLines = [] as string[];

      // Check if the text width is greater than the maximum width
      if (textElement.measuredWidth > maxWidth) {
        // Split the name into two lines
        var words = nameText.split(" ");
        var line = "";

        words.forEach(function (word) {
          if ((line + word).length <= maxWidth) {
            line += word + " ";
          } else {
            nameLines.push(line.trim());
            line = word + " ";
          }
        });

        // Add the last line
        nameLines.push(line.trim());

        // Set the name in two lines
        textElement.text = nameLines.join("\n");
      } else {
        // Set the original name if it fits within the maximum width
        textElement.text = nameText;
      }
    });

    // Add label for total value sum
     // Calculate and display the total value sum in the center of the pie chart
var totalValue = data.reduce((sum, item) => sum + Number(item.value), 0);
var totalLabel = chart.seriesContainer.children.push(am5.Label.new(root, {
  text: `${totalValue.toFixed(0)}`,
  centerX: am5.p50,
  centerY: am5.p50,
  fill: am5.color(0x000000),
  fontSize: 20,
}));


    // series.ticks.template.set("forceHidden", true);
    series.appear(1000, 100);
    root._logo?.dispose();

    return () => {
      root.dispose();
    };
  }, [chartID, dataWithPercentages]);

  return (
    <div className="card  ms-3 mt-5" style={{maxHeight:"260px",width:"98%"}} >
      <div
        className="p-2 fs-3 d-flex align-items-start justify-content-start mt-4 ms-4"
        style={{ color: "black", fontSize: "18px" }}
      >
       <h4>{title}</h4> 
      </div>
      <div
        id={chartID}
        style={{
          height: "260px",
          width: "80%",
        //   marginBottom: "60px",
        }}
      ></div>
    </div>
  );
}

export default License;
