/** @format */

/** @format */

import { ID, Response } from "../../../../../../theme/helpers";
export type User = {
	id?: ID;
	name?: string;
	username?: string;
	avatar?: string;
	email?: string;
	position?: string;
	message?:string;
	role?: string;
	last_login?: string;
	two_steps?: boolean;
	joined_day?: string;
	online?: boolean;
	initials?: {
		label: string;
		state: string;
	};
};
export type DataSchema= {
	participantName: string;
	address: string;
	country: string;
	sector: string;
	regNo: string;
	date: string;
	title: string;
	categoryGeneral: string;
	licensingCategory: string;
	supportingDocuments: string;
	natureOfService: string;
  };
export type Auth ={
	token: string;
  };
  export type HeadingData = {
	draftId: string;
	sectionIndex: string;
	sectionHeadName: string;
  }
  
export type Source = {
	    draftId?:string,
        docName?: string,
        fileName?: string,
        noOfResponses?: string | null,
        noOfPages?: string | null,
        createdBy?: string | null,
        createdDate?: string,
	};
export type SourceQueryResponse = Response<Array<Source>>;

export const initialSource: Source = {
	draftId:"4",
	docName: "",
	fileName: "Sample.pdf",
	noOfResponses: null,
	noOfPages: null,
	createdBy: null,
	createdDate: "1/16/23 11:57 ,AM",
};


export type Doc = {
	documentType?: string | null;
	draftId?: string | null;
	subject?: string | null;
	documentName?: string | null;
	participantId?: string | null;
	organisationId?: string | null;
};

export type Responses = {
	fileName: string;
	pages: string | number | null;
	assignedTo: string | null;
	submittedBy: string;
	submittedOn: string;
	status: string;
};
export type Draft ={
  action(draftId: string, query: string, action: any): void;
	draftId: string;
    docName: string;
    draftSubject: string;
    fileName: string;
    filePath: string;
    header: null;
	query:string;
    noOfPages: number | null;
    noOfResponses: number | null;
    status: any |string | undefined;
    tag: string | null;
    uploadedBy: string;
    uploadedOn: string
	
  }
  

export type UsersQueryResponse = Response<Array<User>>;
export type ResponseQuery = Array<Responses>;

export const initialUser: User = {
	avatar: "avatars/300-6.jpg",
	position: "Art Director",
	role: "Administrator",
	name: "",
	email: "",
};

export type AddSubmission = {
	id?: ID;
	participants?: any;
	organisation?: any;
	file?: any;
};

export type AddAnnotation={
	documentPageId?: any ,
        documentId?: any,
        lineNumberFrom?: number,
        charFrom?: number,
        lineNumberTo?: number,
        charTo?: number,
        selectedText?: any,
        fieldName?: any,
        fieldText?: any,
        type?: any
}
