import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";
import { createUser, getId } from "../../apps/user-management/users-list/core/_requests";
import { useAuth } from "../core/Auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import background from "../../../../assets/login.jpg";
import neithlogo from "../../../../assets/neith.png";
import { Navigate, useNavigate } from "react-router-dom";

const registrationSchema = Yup.object().shape({
  firstName: Yup.string().label("First Name").required("First Name is required"),
  lastName: Yup.string().label("Last Name").required("Last Name is required"),
  email: Yup.string().label("Email").email("Invalid Email").required("Email is required"),
  company: Yup.string().label("Company").required("Company is required"),
  sector: Yup.string().label("Sector").required("Sector is required"),
  password: Yup.string().label("Password").required("Password is required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  sector: "",
  password: "",
};

export function Registration() {
    const navigate = useNavigate();
    const {accept,setAccept}=useAuth()
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [type, settype] = useState("password");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        // Get your auth from wherever it is stored
        // const auth = /* get your auth from wherever it is stored */;
        
        // Make the API request to create a user
        const user = await createUser(values);
        // const id = await getId();
        // console.log(id,"draft")
        

        setStatus(user.data.message);
        setAccept(true)
       {user?.data?.data=="Successfull Registration!" && navigate("/login")}

        // Check if the email has already been used
        if (user && user.data.message === "Email is already in use :: first@trisysit.com") {
          formik.setErrors({ email: "Email has already been used" });
          setStatus("Email has already been used")
        } else {
        
        }
      } catch (error) {
        const user = await createUser(values);
        // Handle registration failure
        setStatus("Registration failed. Email is already in use");
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        minHeight: "100vh",
        backgroundImage: `url(${background})`,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <img src={neithlogo} height="120px" width="230px" alt="logo" loading="lazy" />
      </div>
      <div className=" " style={{ width: "460px" }}>
        <form
          className="form w-100"
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_registration_form"
        >
          {/* Form heading */}
          <div className="text-start mb-5">
            <h1 className="fw-bolder mb-1" style={{ color: "#95BEC1" }}>
              Registration
            </h1>
          </div>

          {formik.status && (
            <div className="mb-lg-11 alert alert-danger text-center">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* First Name and Last Name */}
          <div className="d-flex align-item-start justify-content-start gap-5" style={{ width: "500px" }}>
            {/* First Name */}
            <div className="fv-row mb-3">
              <label className="form-label fs-6 fw-bolder" style={{ color: "#95BEC1" }}>
                First Name:
              </label>
              <input
                placeholder="First Name"
                {...formik.getFieldProps("firstName")}
                className={clsx("form-control form-control-light mb-3 mb-lg-0", {
                  "is-invalid": formik.touched.firstName && formik.errors.firstName,
                })}
                type="text"
                name="firstName"
                autoComplete="off"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.firstName}</span>
                  </div>
                </div>
              )}
            </div>

            {/* Last Name */}
            <div className="fv-row mb-3">
              <label className="form-label fs-6 fw-bolder" style={{ color: "#95BEC1" }}>
                Last Name:
              </label>
              <input
                placeholder="Last Name"
                {...formik.getFieldProps("lastName")}
                className={clsx("form-control form-control-light mb-3 mb-lg-0", {
                  "is-invalid": formik.touched.lastName && formik.errors.lastName,
                })}
                type="text"
                name="lastName"
                autoComplete="off"
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.lastName}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Email */}
          <div className="fv-row mb-3">
            <label className="form-label fs-6 fw-bolder" style={{ color: "#95BEC1" }}>
              Email:
            </label>
            <input
              placeholder="Email"
              {...formik.getFieldProps("email")}
              className={clsx("form-control form-control-light mb-3 mb-lg-0", {
                "is-invalid": formik.touched.email && formik.errors.email,
              })}
              type="email"
              name="email"
              autoComplete="off"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          {/* Company and Sector */}
          <div className="d-flex align-item-start justify-content-start gap-5">
            {/* Company */}
            <div className="fv-row mb-3">
              <label className="form-label fs-6 fw-bolder" style={{ color: "#95BEC1" }}>
                Company:
              </label>
              <input
                placeholder="Company"
                {...formik.getFieldProps("company")}
                className={clsx("form-control form-control-light mb-3 mb-lg-0", {
                  "is-invalid": formik.touched.company && formik.errors.company,
                })}
                type="text"
                name="company"
                autoComplete="off"
              />
              {formik.touched.company && formik.errors.company && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.company}</span>
                  </div>
                </div>
              )}
            </div>

            {/* Sector */}
            <div className="fv-row mb-3">
              <label className="form-label fs-6 fw-bolder" style={{ color: "#95BEC1" }}>
                Sector:
              </label>
              <input
                placeholder="Sector"
                {...formik.getFieldProps("sector")}
                className={clsx("form-control form-control-light mb-3 mb-lg-0", {
                  "is-invalid": formik.touched.sector && formik.errors.sector,
                })}
                type="text"
                name="sector"
                autoComplete="off"
              />
              {formik.touched.sector && formik.errors.sector && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.sector}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Password */}
          <div className="fv-row mb-3" style={{ position: 'relative' }}>
            <label className="form-label fw-bolder fs-6 mb-2" style={{ color: "#95BEC1" }}>
              Password:
            </label>
            <input
              style={{ width: "49%" }}
              placeholder="Password"
              type={type}
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx("form-control form-control-light mb-3 mb-lg-0", {
                "is-invalid": formik.touched.password && formik.errors.password,
              })}
            />
            <div
              style={{
                position: "absolute",
                right: -55,
                marginTop: 0,
                top: 39,
                cursor: "pointer",
              }}
              onClick={() => (type === "text" ? settype("password") : settype("text"))}
            >
              {type === "password" ? <FaEyeSlash size={20} title="Show" /> : <FaEye size={20} title="Hide" />}
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <p style={{ color: "#95BEC1", fontSize: "10px", marginTop: "20px" }}>
            We collect and process your personal information mainly to contact you for the purposes of getting your
            feedback and evaluation of the Pilot Service.
          </p>
          
          {/* Submit button */}
          <div className="d-grid mb-2 mt-10">
            <button
              title="Register"
              type="submit"
              id="kt_registration_submit"
              className="btn rounded-pill"
              style={{ backgroundColor: "#D3FD35", color: "#31684D", width: "49%" }}
              disabled={formik.isSubmitting || !formik.isValid || accept}
            >
              {!loading && <span className="indicator-label">Submit</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
