/** @format */

import { Route, Routes } from "react-router-dom";
// import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { AuthLayout } from "./AuthLayout";
import { ResetPassword } from "./components/ResetPassword";
import { Verification } from "./components/Verification";
import WelcomeComponent from "./components/Welcome";

const AuthPage = () => (
	<Routes>
				
		<Route element={<AuthLayout />}>

			<Route path="login" element={<Login />} />
			{/* <Route path='registration' element={<Registration />} /> */}
			<Route path="forgot-password" element={<ForgotPassword />} />
			<Route path="verification" element={<Verification />} />
			<Route path="forgot-password1" element={<ResetPassword />} />
			
			<Route index element={<Login />} />
		</Route>
	</Routes>
);

export { AuthPage };
