/** @format */

import { useEffect } from "react";
import { KTSVG } from "../../../../theme/helpers";
import { useListView } from "../../apps/user-management/users-list/core/ListViewProvider";

const DeleteModal = (props: any) => {
	const { setItemIdForDelete } = useListView();

	useEffect(() => {
		document.body.classList.add("modal-open");
		return () => {
			document.body.classList.remove("modal-open");
		};
	}, []);

	return (
		<>
			<div
				className="modal fade show d-block"
				id="kt_modal_add_user"
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				{/* begin::Modal dialog */}
				<div className="modal-dialog modal-dialog-centered mw-500px">
					{/* begin::Modal content */}
					<div className="modal-content">
						{/* <div className="modal-header"> */}

						{/* begin::Close */}
						{/* <div
                // className="btn btn-sm btn-active-icon-primary"
                // data-kt-users-modal-action="close"
                onClick={() => setItemIdForDelete(undefined)}
                style={{ cursor: "pointer" }}
				className='d-flex justify-content-end btn btn-active-icon-primary'
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
              </div> */}
						{/* end::Close */}
						{/* </div>{" "} */}
						{/* begin::Modal body */}
						<div style={{height:'0px'}}>
							{/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
							<div
								className='btn btn-icon btn-sm btn-active-icon-primary float-end'
								data-kt-users-modal-action='close'
								onClick={props.onCancel ? props.onCancel : () => setItemIdForDelete(undefined)}
								style={{ cursor: 'pointer', paddingTop:'23px', paddingRight:'35px' }}
								title="Close"
							>
								<KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
							</div>

						</div>

						<div className="modal-body scroll-y mx-5 mx-xl-15 mt-7 mb-6 d-flex align-items-center justify-content-center">
							<h3>Are you sure you want to delete ?</h3>
						</div>
						{/* end::Modal body */}
						<div className="modal-footer m-auto py-8">
							<button
								type="button"
								className="btn btn-light me-10"
								data-bs-dismiss="modal"
								onClick={
									props.onCancel
										? props.onCancel
										: () => setItemIdForDelete(undefined)
								}
								title="Close"
							>
								Close
							</button>
							<button
								type="button"
								className="btn btn-delete"
								onClick={props.onDelete}
								style={{ backgroundColor: "#ff0000", color: "#fff" }}
								title="Delete"
							>
								Delete
							</button>
						</div>
					</div>
					{/* end::Modal content */}
				</div>
				{/* end::Modal dialog */}
			</div>
			{/* begin::Modal Backdrop */}
			<div className="modal-backdrop fade show"></div>
			{/* end::Modal Backdrop */}
		</>
	);
};

export { DeleteModal };
