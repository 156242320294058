/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */
import React from "react";
import { useLocation } from "react-router-dom";
import {
	QueryResponseProvider,
} from "../apps/user-management/users-list/core/QueryResponseProvider";
import { ListViewProvider } from "../apps/user-management/users-list/core/ListViewProvider";
import { QueryRequestProvider } from "../apps/user-management/users-list/core/QueryRequestProvider";
import AddSubmission from "./AddSubmission";
import ResponseDetails from "./ResponseDetails";
import { ResponseDetailTableWrapper } from "../apps/user-management/users-list/ResponseDetailTable";
type Props = any;

const ProfileDetails: React.FC<Props> = ({ currentPath }) => {
	const location = useLocation();
	const user: any = location?.state;


	return (
		<>
			<QueryRequestProvider>
				<QueryResponseProvider>
					<ListViewProvider>
						{currentPath === "ResponseBoard" ? (
							<>
								<ResponseDetails response={user} query={""} responseId={""} />
								<ResponseDetailTableWrapper />
							</>
						) : (
							<div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
								<div className="card-header">
									<div className="card-title m-0">
										<h3 className="fw-bolder m-0">Profile Details</h3>
									</div>

								</div>

								<div className="card-body p-9">


									<div className="row mb-7">
										<label className="col-lg-4 fw-bold text-muted">
											Username
										</label>

										<div className="col-lg-8 fv-row">
											<span className="fw-bold fs-6">
												{user?.user?.username || "N/A"}
											</span>
										</div>
									</div>

									<div className="row mb-7">
										<label className="col-lg-4 fw-bold text-muted">Email</label>

										<div className="col-lg-8 fv-row">
											<span className="fw-bold fs-6">
												{user?.user?.email || "N/A"}
											</span>
										</div>
									</div>


									<div className="row mb-7">
										<label className="col-lg-4 fw-bold text-muted">Role</label>

										<div className="col-lg-8">
											<span
												className="fw-bold fs-6 text-dark text-hover-primary"
											>
												{user?.user?.roles || "N/A"}
											</span>
										</div>
									</div>

								</div>
							</div>
						)}
						{currentPath === "DraftBoard" && <AddSubmission />}
					</ListViewProvider>
				</QueryResponseProvider>
			</QueryRequestProvider>
		</>
	);
};

export default ProfileDetails;
