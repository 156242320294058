import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import './BarChart.css';

type Props = any;

const SentimentBargraph: React.FC<Props> = (props) => {
  const data = props.data || [];
  const [chartData, setChartData] = useState<{ labels: string[]; counts: number[] }>({
    labels: [],
    counts: [],
  });

  useEffect(() => {
    if (data) {
      const xLabels = data?.map((item) => `${item.sentiment}`);
      const participantsCount = data?.map((item) => item.participants?.length || 0);
      setChartData({
        labels: xLabels,
        counts: participantsCount,
      });
    }
  }, [data]);

  const [options, setOptions] = useState<ApexOptions>({
    series: [
      {
        name: "Number of Participants",
        data: [],
      },
    ],
    chart: {
      height: 400,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    colors: ["#006D9B", "#56BAB9", "#19247B", "#3247F5", "#D4FC03", "#004053"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: [],
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: "13px",
        },
      },
      title: {
        text: "Counts",
        style: {
          fontSize: "16px",
          fontWeight: 600,
        },
      },
      forceNiceScale: true,
    },
    tooltip: {
      enabled: true,
      custom: ({ seriesIndex, dataPointIndex }: any) => {
        const sentimentData = data[dataPointIndex];
        if (sentimentData && sentimentData?.participants && sentimentData?.participants.length > 0) {
          const participantsList = sentimentData?.participants.map(
            (participant) => `<li><span class="dot"></span>${participant}</li>`
          ).join("");
          const numParticipants = sentimentData.participants.length;

          return (
            '<div class="apexcharts-tooltip-custom" style="padding: 10px; text-align: left;">' +
            '<ul class="tooltip-participants"> <strong> Participants List </strong>:' +
            participantsList +
            '</ul>' +
            '<span class="tooltip-count "><strong>Total Participants </strong>: ' +
            numParticipants +
            '</span></div>'
          );
        } else {
          // Handle the case where 'participants' is empty or undefined
          return (
            '<div class="apexcharts-tooltip-custom" style="padding: 10px; text-align: left;">No participants data available</div>'
          );
        }
      },
    },
  });

  useEffect(() => {
    const innerHTML = data?.map((item) => {
      let dynamicHTML = "";
      if (item.participants && item.participants.length > 0) {
        for (let i = 0; i < item.participants.length; i++) {
          dynamicHTML += item.participants[i] + "<br>"; // Use += to concatenate
        }
      }
      return dynamicHTML;
    });

    // After the map operation, you can join the array of strings into a single string
    const combinedHTML = innerHTML.join('') + "<strong>Total Participants </strong>";
    setOptions({
      ...options,
      xaxis: {
        ...options.xaxis,
        categories: chartData.labels,
      },
      series: [
        {
          name: combinedHTML,
          data: chartData.counts,
        },
      ],
    });
  }, [chartData, options]);

  return (
    <div className="chart-container" style={{ height: "450px" }}>
      {data.length === 0 ? (
        <div>Loading data...</div>
      ) : (
        <ApexCharts options={options} series={options.series} type="bar" height={400} />
      )}
    </div>
  );
};

export default SentimentBargraph;
