/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import tags from "../Assets/nodata.png";
import * as am5percent from "@amcharts/amcharts5/percent";

function Licensees(props) {
	let chartID = props.chartID;
	let finalData = props.data;
	let title = props.title;
	let data = props.data || [];
	function NoDataMessage() {
		return (
			<div id={chartID} className="d-flex flex-column align-items-center justify-content-center mt-20" style={{ height: "200px", width: "80%", marginLeft: "-60px" }}>
				<div style={{ marginBottom: "10px" }}>
					<img
						src={tags}
						height="40px"
						width="40px"
						alt="logo"
					/>
				</div>
				<div>
					<p className="fs-3">No data to display(criteria not matched)</p>
				</div>
			</div>
		);
	}
	useLayoutEffect(() => {
		var root = am5.Root.new(chartID);
		root.setThemes([am5themes_Animated.new(root)]);
		var chart = root.container.children.push(
			am5percent.PieChart.new(root, {
				layout: root.horizontalLayout,
				radius: 60,
				innerRadius: 40,
			})
		);
		var series: any = chart.series.push(
			am5percent.PieSeries.new(root, {
				name: "Series",
				valueField: "value",
				categoryField: "name",
				alignLabels: false,
			})
		);
		var totalValue = data.reduce((sum, item) => sum + Number(item.value), 0);
		var totalLabel = chart.seriesContainer.children.push(am5.Label.new(root, {
			text: `${totalValue.toFixed(0)}`,
			centerX: am5.p50,
			centerY: am5.p50,
			fill: am5.color(0x000000),
			fontSize: 20,
		}));
		const colors = data?.map(dataItem => {
			if (dataItem.name === 'Neutral') {
				return am5.color(0x56BAB9); // Blue color for 'Neutral'
			} else if (dataItem.name === 'Oppose') {
				return am5.color(0x004053); // Yellow color for 'Oppose'
			} else {
				// You can set a default color for other categories here
				return am5.color(0xD2FF00); // Gray color for other categories
			}
		});

		// Now, you can set the colors in your series
		series.get("colors").set("colors", colors);

		let tooltip: any = am5.Tooltip.new(root, {
			getFillFromSprite: true,
			text: am5.color(0x000000),
		});

		tooltip.get("background").setAll({
			fill: am5.color(0x000000),
			fillOpacity: 0.8,
		});

		series.set("tooltip", tooltip);
		series.data.setAll(data);
		series.labels.template.setAll({
			text: "[fontSize: 12px]{name}: {value}%",
			// textType: "circular",
			inside: false,
			oversizedBehavior: "wrap",
			radius: 5,
		});
		// series.ticks.template.set("forceHidden", true);
		series.appear(1000, 100);
		// dispose logo
		root._logo?.dispose()


		return () => {
			root.dispose();
		};
	}, [chartID, finalData]);

	return (
		<div className=" align-items-center justify-content-center px-0"
			style={{ flexWrap: 'wrap', overflow: 'auto' }}>
			{data.length === 0 ? (
				<NoDataMessage />
			) : (
				<div>
					<div
						id={chartID}
						style={{
							height: "200px",
							width: "auto",
						}}
					></div>
					<div className="fs-4  fw-semibold d-flex align-items-center justify-content-center ">{title}
					</div>
				</div>
			)}
		</div>

	);
}
export default Licensees;
