/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { FC, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { MenuComponent } from "../../../../../../../theme/assets/ts/components";
import { ID, KTSVG, QUERIES } from "../../../../../../../theme/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteUser } from "../../core/_requests";
import { FaEye, FaEdit, FaRegTrashAlt, FaAlignJustify, FaPlus,FaTag } from "react-icons/fa";

type Props = {
	cases?: string;
	id: ID;
	onDelete?: () => void;
	onEdit?: () => void;
};

const ActionCellUser: FC<Props> = ({ id, onEdit, cases }) => {
	const { setItemIdForUpdate } = useListView();
	const { setItemIdForDelete } = useListView();
	const { query } = useQueryResponse();
	const queryClient = useQueryClient();
	const location = window.location.pathname.split("/").pop();

	const navigate = useNavigate();
	useEffect(() => {
		MenuComponent.reinitialization();
	}, []);

	const openEditModal = () => {
		//console.log("Obj edit - ", id);
		id.action = "edit";
		//console.log("Obj edit - ", id);
		setItemIdForUpdate(id);
	};
	const openAddUserModal = () => {
		//console.log("Obj add - ", id);
		id.action = "add";
		//console.log("Obj add - ", id);
		setItemIdForUpdate(id);
	};
	const openTagUserModal = () => {
		//console.log("Obj add - ", id);
		id.action = "tag";
		//console.log("Obj add - ", id);
		setItemIdForUpdate(id);
	};

	const onDelete = () => {
		setItemIdForDelete(id);
	};

	const onFormat = () => {
		navigate(`format-response/${id}`);
	};
	const onAssign = () => {
		navigate(`/user-management/users/document/${id.username}/assign`, {
			state: id.username,
		});
	};

	const onView = () => {
		navigate(`/viewDoc/view`, {
			state: {

				data: id.draftId || id.responseId,

			},
		});
	};

	const deleteItem = useMutation(() => deleteUser(id), {
		// 💡 response of the mutation is passed to onSuccess
		onSuccess: () => {
			// ✅ update detail view directly
			queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
		},
	});

	return (
		<>
			{(cases !== 'default') ? (<div
				className="d-flex align-items-center mx-10 justify-content-center"
			>
				{(cases === "response" || cases === "source") && (
					<FaEye
						className="svg-icon1"
						size={13}
						style={{ marginRight: 15, cursor: "pointer" }}
						title="View Document"
						onClick={onView}
					/>
				)}
				

				{cases === `view-detail/${location}` || cases === "response" ? (
					!(
						<FaEdit
							className="svg-icon1"
							size={13}
							style={{ marginRight: 15, cursor: "pointer" }}
							onClick={openEditModal}
							title="Edit"
						/>
					)
				) : (<>
					<FaEdit
						className="svg-icon1"
						size={13}
						style={{ marginRight: 15, cursor: "pointer" }}
						onClick={openEditModal}
						title="Edit"
					/>
					

				</>

				)}

				<FaRegTrashAlt
					className="svg-icon1"
					size={13}
					style={{ cursor: "pointer" }}
					title="Delete"
					onClick={onDelete}
				/>
			</div>) :
				(<div
					className="d-flex align-items-center mx-10 justify-content-center"
				// data-kt-menu-trigger="click"
				// data-kt-menu-placement="bottom-start"
				>
					<FaPlus
						className="svg-icon1"
						size={13}
						style={{ marginRight: 15, cursor: "pointer" }}
						title="Add Heading"
						onClick={openAddUserModal}
					/>
					<FaEdit
						className="svg-icon1"
						size={13}
						style={{ marginRight: 15, cursor: "pointer" }}
						onClick={openEditModal}
						title="Edit"
					/>
					<FaRegTrashAlt
						className="svg-icon1"
						size={13}
						style={{ cursor: "pointer" }}
						title="Delete"
						onClick={onDelete}
					/>


				</div>)}




		</>
	);
};

export { ActionCellUser };
