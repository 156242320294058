import React, { useEffect, useMemo, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { getDynamicSportingData, getSourceList } from "../../../modules/apps/user-management/users-list/core/_requests";
import { useQuery } from "react-query";
import { useQueryRequest } from "../../../modules/apps/user-management/users-list/core/QueryRequestProvider";
import { useParams } from "react-router-dom";
import "./HorizontalBar.css";
import { stringifyRequestQuery } from "../../../../theme/helpers";

interface DataItem {
  name: string;
  value: number;
}

function EngagementBar(props) {
  const chartID = props.chartID;
  const category = props.category;
  const [newdata, setNewData] = useState<DataItem[]>([]);
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const defaultCategory = category && category.length > 0 ? category[0] : "Select Category";
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
  const { id } = useParams();

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const fetchDataForCategory = async (categoryToFetch) => {
    try {
      const response = await getDynamicSportingData(id, categoryToFetch);
      const data = response?.data?.chartData || [];
      setNewData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Fetch data for the default category when the component mounts
    fetchDataForCategory(defaultCategory);
  }, [defaultCategory]);

  const {
    isLoading,
    isFetching,
    refetch,
    data: response,
  } = useQuery([`GET_SOURCE_LIST-${query}`], () => {
    return getSourceList(query);
  }, { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false });

  const sourceList = response?.data;

  useEffect(() => {
    if (response?.data) {
      const data = response.data.chartData || [];
      setNewData(data);
    }
  }, [response]);

  const barColors = [
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
  ];

  const truncateCategory = (name: string, index: number) => {
    const MAX_LABEL_LENGTH = 6; // Change '10' to your desired character limit
    if (typeof name === "string" && name.length > MAX_LABEL_LENGTH) {
      return name.substring(0, MAX_LABEL_LENGTH) + ` (${index + 1})`;
    }
    return name;
  };

  const modifiedData = newdata?.map((item: any, index) => ({
    ...item,
    name: truncateCategory(item.name, index),
    value: parseInt(item.value), // Parse the value to an integer
  }));

  const chartRef = useRef(null as any);
 
  useEffect(() => {
    if (!chartRef.current) {
      const root = am5.Root.new(chartID);
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      }));

      // Add cursor
      const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);

      // Create axes
      const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
      });

      xRenderer.grid.template.setAll({
        location: 1,
      });

      const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "name",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      }));

      const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      }));

      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "name",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{value}",
        }),
      }));

      series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });

      // Add the color adapter
      series.columns.template.adapters.add("fill", function (fill, target) {
        const index = series.columns.indexOf(target);
        return barColors[index];
      });

      // Set data
      xAxis.data.setAll(modifiedData);
      series.data.setAll(modifiedData);

      // Make stuff animate on load
      series.appear(1000, 100);
      chart.appear(1000, 100);
      //dispose logo
      root._logo?.dispose();

      // Cleanup on unmount
      return () => {
        root.dispose();
      };
    }
  }, [chartID, newdata, modifiedData, response, selectedCategory]);

  const handleSelectChange = (event) => {
    const selectedCategory = event.target.value || defaultCategory;
    setSelectedCategory(selectedCategory);

    // Fetch data for the selected category
    fetchDataForCategory(selectedCategory);
  };

  return (
    <>
    <div className="card p-4" style={{height:"500px"}}>
    <div className="p-4 fs-3 d-flex align-items-start justify-content-start" style={{ color: "black", fontSize: "18px" }}>
      <h4>Engagement By Category</h4>
    </div><div className="">
        <div id={chartID} style={{ height: "390px", width: "100%" }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '10px' }}>
            <select
              className="form-select"
              style={{ width: "40%", height: "10%", marginRight: "20px" }}
              onChange={handleSelectChange}
              defaultValue={selectedCategory}
            >
              {category?.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="y-axis-label fs-4">Length of Submission</div>
      </div></div></>
  );
}

export default EngagementBar;
