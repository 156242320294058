import clsx from "clsx";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateTag } from "../apps/user-management/users-list/core/_requests";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Toast } from "../apps/user-management/users-list/Toast";

const TaggingForm = ({ selectedText, tagdata }) => {
  const initialdata = tagdata?.data?.data || {};
  const [issuingAuthority, setIssuingAuthority] = useState(
    initialdata?.issuingAuthority || ""
  );
  const [country, setCountry] = useState(initialdata?.country || "");
  const [gazetted, setGazetted] = useState(initialdata?.gazetted || "");
  const [processType, setProcessType] = useState(initialdata?.processType || "");
  const [supportingDocuments, setSupportingDocuments] = useState(initialdata?.supportingDocuments || "");
  const [dateOfIssue, setDateOfIssue] = useState(initialdata?.dateOfIssue || "");
  const [governmentGazetteDate, setGovernmentGazetteDate] = useState(initialdata?.governmentGazetteDate|| "");
  const [inputFields, setInputFields] = useState<any>([
    { keyTerms: initialdata?.keyTerms || "" },
  ]);
  const [legislation, setLegislation] = useState<any>([
    { empoweringLegislation: initialdata?.empoweringLegislation || "" },
  ]);
  const [sector, setSector] = useState(initialdata?.sector || "");
  const [section, setSection] = useState<any>([
    { empoweringSections: initialdata?.empoweringSections || "" },
  ]);
  const [tags, setTags] = useState({
    keyTerms: initialdata.keyTerms || [""],
    empoweringLegislation: initialdata.empoweringLegislation || [""],
    empoweringSections: initialdata.empoweringSections || [""],
  });
  const [id, setId] = useState(false);
  const [message, setMessage] = useState("")
  const [governmentGazetteNumber, setGovernmentGazetteNumber] = useState(
    initialdata?.governmentGazetteNumber || ""
  );
  const [noticenoAndYear, setNoticenoAndYear] = useState(
    initialdata?.noticenoAndYear || ""
  );
  const [title, setTitle] = useState(initialdata?.title || "");
  const [deadlineForComment, setDeadlineForComment] = useState(
    initialdata?.deadlineForComment || ""
  );

  const handleChange = (e) => {
    setIssuingAuthority(e.target.value);
  };
  const handleChangedate = (e) => {
    const selectedDate = e.target.value;
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    setDateOfIssue(formattedDate);
  };
  const handleChangegaztet = (e) => {
    const selectedDate = e.target.value;
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    setGovernmentGazetteDate(formattedDate);
  };
  
  const handleChangeradio = (e) => {
    setGazetted(e.target.value)
  }

  const handleClick = () => {
    setIssuingAuthority(selectedText);
  }; 
  const handleChangeSector = (e) => {
    setSector(e.target.value);
  };

  const handleClickSector = () => {
    setSector(selectedText);
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setProcessType(selectedValue);
  };
  const handleSelectSupporting = (event) => {
    const selectedValue = event.target.value;
    setSupportingDocuments(selectedValue);
  };
  const handleSelectGazetted = (event) => {
    const selectedValue = event.target.value;
    setGazetted(selectedValue);
  };

  const params = useParams();
  const ProcessTypeData = [
    "Policy",
    "Legislation - new",
    "Legislation - amendment",
    "Regulation - new",
    "Regulation - amendment",
    "Discussion Document",
  ];
  const handleSubmit = async (values) => {
    let temp1: any = [];
    inputFields?.map((item) => {
      //console.log(item?.keyTerms, "kkkk");
      if (Array.isArray(item?.keyTerms)) {
        temp1.push(...item?.keyTerms);
      } else {
        temp1.push(item?.keyTerms);
      }
    });

    let temp2: any = [];
    legislation?.map((item) => {
      if (Array.isArray(item?.empoweringLegislation)) {
        temp2.push(...item?.empoweringLegislation);
      } else {
        temp2.push(item?.empoweringLegislation);
      }
    });

    let temp3: any = [];
    section?.map((item) => {
      if (Array.isArray(item?.empoweringSections)) {
        temp3.push(...item?.empoweringSections);
      } else {
        temp3.push(item?.empoweringSections);
      }
    });

    let tagData = {
      ...values,
      issuingAuthority: issuingAuthority,
      country: country,
      sector: sector,
      title: title,
      governmentGazetteNumber: governmentGazetteNumber,
      noticenoAndYear: noticenoAndYear,
      deadlineForComment: deadlineForComment,
      gazetted: gazetted,
      processType: processType,
      supportingDocuments: supportingDocuments,

      keyTerms: temp1,
      empoweringLegislation: temp2,
      empoweringSections: temp3,
      draftId: params?.id,
      dateOfIssue: dateOfIssue,
      governmentGazetteDate: governmentGazetteDate,
      
    };

    if (tagData?.gazetted === "no") {
      tagData.governmentGazetteDate = governmentGazetteDate;
      tagData.governmentGazetteNumber = governmentGazetteNumber;
      tagData.noticenoAndYear = noticenoAndYear;
    } else {
      tagData.dateOfIssue = dateOfIssue;
    }

    try {
      let response = await updateTag(tagData);
      console.log(response, "neww");
      setId(true);
      setMessage("Tag saved Successfully");
      setTimeout(() => {
        setId(false);
      }, 2000);
    } catch (error) {
      setMessage("Error Tagging source. Please try again later.");
      setId(true);
      setTimeout(() => {
        setId(false);
      }, 2000);
    }
  };
  const formik = useFormik({
    initialValues: {
      country: initialdata?.country || "", // Provide the initial values or leave them empty if you don't have them initially
      issuingAuthority: initialdata?.issuingAuthority || "",
      sector: initialdata?.sector || "",
      gazetted: "no",
      dateOfIssue: initialdata.dateOfIssue || "",
      governmentGazetteDate: initialdata.governmentGazetteDate || "",
      governmentGazetteNumber: initialdata.governmentNumber || "",
      noticenoAndYear: initialdata.noticenoAndYear || "",
      title: initialdata?.title,
      deadlineForComment: initialdata.deadlineForComment || "",
      processType: "",
      supportingDocuments: "",
    },

    onSubmit: handleSubmit,
    onReset: async (values) => {
      values = formik.initialValues;
      setIssuingAuthority(initialdata?.issuingAuthority);
      setInputFields([{ keyTerms: "" }]);
      setLegislation([{ empoweringLegislation: "" }]);
      setSector("");
      setSection([{ empoweringSections: "" }]);
    },
  });

  
  

  
  const handleTagClick = (field, index) => {
    const updatedTags = { ...tags };
    updatedTags[field][index] = selectedText;
    setTags(updatedTags);
  };

  const handleTagChange = (field, index, e) => {
    const updatedTags = { ...tags };
    updatedTags[field][index] = e.target.value;
    setTags(updatedTags);
  };

  const handleAddTagField = (field) => {
    const updatedTags = { ...tags };
    updatedTags[field].push("");
    setTags(updatedTags);
  };

  const handleRemoveTagField = (field, index) => {
    if (tags[field].length > 1) {
      const updatedTags = { ...tags };
      updatedTags[field].splice(index, 1);
      setTags(updatedTags);
    }
  };


  const handleRemoveField = (index, id) => {
    let updatedFields;
    
    switch (id) {
      case "key":
        if (inputFields.length > 1) { // Check if there's more than one input
          updatedFields = [...inputFields];
          updatedFields.splice(index, 1);
          setInputFields(updatedFields);
        }
        break;
        
      case "legislation":
        if (legislation.length > 1) { // Check if there's more than one legislation
          updatedFields = [...legislation];
          updatedFields.splice(index, 1);
          setLegislation(updatedFields);
        }
        break;
  
      case "section":
        if (section.length > 1) { // Check if there's more than one section
          updatedFields = [...section];
          updatedFields.splice(index, 1);
          setSection(updatedFields);
        }
        break;
  
      default:
        break;
    }
  };
  

  const handleInputChange = (index, e, id) => {
    const { name, value } = e.target;
    let updatedFields: any;
    switch (id) {
      case "key":
        updatedFields = [...inputFields];
        updatedFields[index][name] = value;
        //console.log("VALUE OF KEY", updatedFields);
        setInputFields(updatedFields);
        break;
      case "legislation":
        updatedFields = [...legislation];
        updatedFields[index][name] = value;
        //console.log("VALUE OF LEGI", updatedFields);
        setLegislation(updatedFields);

        break;

      case "section":
        updatedFields = [...section];
        updatedFields[index][name] = value;
        //console.log("VALUE OF SECTOR", updatedFields);

        setSection(updatedFields);

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    //console.log("ALL INPUT DATA", { inputFields, sector, legislation });
  }, [inputFields, sector, legislation, section, initialdata]);

  return (

    <div className="card p-5">
      {id && message && <Toast title={message} color={message.startsWith('Error') ? 'alert-danger' : 'alert-success'} />}



      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
        noValidate
      >
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          style={{ height: "66vh" }}
        >
          <div className="fv-row mb-7">
            <label className=" fw-bold fs-6 mb-2">
              {" "}
              Name Of Issuing Authority
            </label>
            <input
              placeholder="Name Of Issuing Authority"

              type="text"
              {...formik.getFieldProps("issuingAuthority")}
              name="issuingAuthority"
              value={issuingAuthority}
              onChange={(e) => handleChange(e)}
              onClick={handleClick}
              className={clsx("form-control form-control-light mb-3 mb-lg-0")}
              autoComplete="off"
            ></input>
          </div>

          <div className="fv-row mb-7">
            <label className=" fw-bold fs-6 mb-2">Country</label>
            <input
              placeholder="Country"
              {...formik.getFieldProps("country")}
              type="text"
              name="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              onClick={(e) => setCountry(selectedText)}
              // value={undefined}
              className={clsx(
                "form-control form-control-light mb-3 mb-lg-0"
              )}
              autoComplete="off"
            />
          </div>

          <div className="fv-row mb-7">
            <label className=" fw-bold fs-6 mb-2">Sector</label>
            <input
              placeholder="Sector"
              {...formik.getFieldProps("sector")}
              type="text"
              name="sector"
              value={sector}
              onChange={handleChangeSector}
              onClick={handleClickSector}
              className={clsx(
                "form-control form-control-light mb-3 mb-lg-0"
              )}
              autoComplete="off"
            />
          </div>

          <div className="fv-row mb-7">
      <label className="fw-bold fs-6 mb-2">Gazetted</label>
      <div className="gap-3 d-flex">
        <input
          className="form-check-input"
          type="radio"
          id="gazettedYes"
          name="gazetted"
          value="yes"
          checked={gazetted === "yes"}
          onChange={handleSelectGazetted}
        />
        <label className="fw-bold fs-7">Yes</label>
        <input
          className="form-check-input"
          type="radio"
          id="gazettedNo"
          name="gazetted"
          value="no"
          checked={gazetted === "no"}
          onChange={handleSelectGazetted}
        />
        <label className="fw-bold fs-7">No</label>
      </div>
    </div>
          {gazetted === "no" ? (
            <div className="fv-row mb-7">
              <label htmlFor="dateOfIssue" className=" fw-bold fs-6 mb-2">
                Date of Issue
              </label>
              <input
                {...formik.getFieldProps("dateOfIssue")}
                type="date"
                id="dateOfIssue"
                name="dateOfIssue"
                
                value={dateOfIssue}
                onChange={handleChangedate}
                //onFocus={(e) => e.target.blur()}
                //onBlur={(e) => e.target.blur()}
                className={clsx("form-control", {
                  "is-invalid":
                    formik.touched.dateOfIssue && formik.errors.dateOfIssue,
                })}
                autoComplete="off"
              />
              {formik.touched.dateOfIssue && formik.errors.dateOfIssue && (
                <div className="invalid-feedback">
                  {String(formik.errors.dateOfIssue)}
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">
                  {" "}
                  Government Gazette Number
                </label>
                <input
                  placeholder="Government Gazette Number"
                  {...formik.getFieldProps("governmentGazetteNumber")}
                  type="text"
                  name="governmentGazetteNumber"
                  value={governmentGazetteNumber}
                  onChange={(e) => setGovernmentGazetteNumber(e.target.value)}
                  onClick={(e) => setGovernmentGazetteNumber(selectedText)}

                  className={clsx(
                    "form-control form-control-light mb-3 mb-lg-0"
                  )}
                  autoComplete="off"
                ></input>
              </div>
              <div className="fv-row mb-7">
                <label
                  htmlFor="governmentGazetteDate"
                  className="fw-bold fs-6 mb-2"
                >
                  Government Gazette Date
                </label>
                <input
                  {...formik.getFieldProps("governmentGazetteDate")}
                  type="date"
                  id="governmentGazetteDate"
                  name="governmentGazetteDate"
                   value={governmentGazetteDate}
                   onChange={handleChangegaztet}
                  // onBlur={handleBlur}
                  className={clsx(
                    "form-control"
                  )}
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2"> Notice No and Year</label>
                <input
                  placeholder="Notice No and Year"

                  {...formik.getFieldProps("noticenoAndYear")}
                  type="text"
                  name="noticenoAndYear"
                  value={noticenoAndYear}
                  onChange={(e) => setNoticenoAndYear(e.target.value)}
                  onClick={(e) => setNoticenoAndYear(selectedText)}

                  className={clsx(
                    "form-control form-control-light mb-3 mb-lg-0"
                  )}
                  autoComplete="off"
                ></input>
              </div>
            </>
          )}
          <div className="fv-row mb-7">
            <label className=" fw-bold fs-6 mb-2">Title</label>
            <input
              placeholder="Title"
              type="text"
              {...formik.getFieldProps("title")}
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onClick={(e) => setTitle(selectedText)}
              className={clsx(
                "form-control form-control-light mb-3 mb-lg-0"
              )}
              autoComplete="off"
            ></input>
            {formik.touched.title && formik.errors.title && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-7 gap-2 d-flex flex-column">
          {tags.keyTerms.map((term, index) => (
            <div key={index} className="fv-row">
              <label className="fw-bold fs-6 mb-2">
                Key Terms {index === 0 ? "" : index + 1}
              </label>
              <input
                placeholder="Key Terms"
                type="text"
                name="keyTerms"
                value={term}
                onChange={(e) => handleTagChange("keyTerms", index, e)}
                onClick={() => handleTagClick("keyTerms", index)}
                className={clsx(
                  "form-control form-control-light mb-3 mb-lg-0"
                )}
                autoComplete="off"
              />
              
            </div>

          ))}
          <div className="gap-5 d-flex mt-2"> <button
                type="button"
                style={{ padding: 0, width: "35px" }}
                className="cursor-pointer"
                onClick={() => handleAddTagField("keyTerms")}
              >
                +
              </button>
              <button
                type="button"
                style={{ padding: 0, width: "35px" }}
                className="cursor-pointer"
                onClick={() => handleRemoveTagField("keyTerms", "")}
              >
                -
              </button></div>
         
        </div>

          <div className="fv-row mb-7">
            <label className=" fw-bold fs-6 mb-2"> Deadline for comment</label>
            <input
              placeholder="Deadline for comment"
              {...formik.getFieldProps("deadlineForComment")}
              type="text"
              name="deadlineForComment"
              value={deadlineForComment}
              onChange={(e) => setDeadlineForComment(e.target.value)}
              onClick={(e) => setDeadlineForComment(selectedText)}
              // value={undefined}
              className={clsx(
                "form-control form-control-light mb-3 mb-lg-0"

              )}
              autoComplete="off"
            ></input>

          </div>
          <div className="fv-row mb-7">
            <label className="fw-bold fs-6 mb-2">Process Type</label>
            <select
              className={clsx("form-select form-select-light mb-3 mb-lg-0")}
              aria-label="Select example"
              {...formik.getFieldProps("processType")}
              value={processType}
              onChange={handleSelectChange} // Add the onChange event handler
            >
              {ProcessTypeData?.map((item, index) => {
                return (
                  <><option hidden={true} value="">Select Process type</option><option selected={formik.values.processType === item} value={item} key={item}>
                    {item}
                  </option></>
                );
              })}
            </select>
          </div>

          <div className="fv-row mb-7">
          {tags.empoweringLegislation.map((legislation, index) => (
            <div key={index} className="fv-row">
              <label className="fw-bold fs-6 mb-2">
                Empowering Legislation {index === 0 ? "" : index + 1}
              </label>
              <input
                placeholder="Empowering Legislation"
                type="text"
                name="empoweringLegislation"
                value={legislation}
                onChange={(e) => handleTagChange("empoweringLegislation", index, e)}
                onClick={() => handleTagClick("empoweringLegislation", index)}
                className={clsx(
                  "form-control form-control-light mb-3 mb-lg-0"
                )}
                autoComplete="off"
              />
              
            </div>
          ))}
          <div  className="gap-5 d-flex mt-3">
          <button
                type="button"
                style={{ padding: 0, width: "35px" }}
                className="cursor-pointer"
                onClick={() => handleAddTagField("empoweringLegislation")}
              >
                +
              </button>
              <button
                type="button"
                style={{ padding: 0, width: "35px" }}
                className="cursor-pointer"
                onClick={() => handleRemoveTagField("empoweringLegislation", "")}
              >
                -
              </button>
              </div>
        </div>

        <div className="fv-row mb-7">
          {tags.empoweringSections.map((section, index) => (
            <div key={index} className="fv-row">
              <label className="fw-bold fs-6 mb-2">
                Empowering Sections {index === 0 ? "" : index + 1}
              </label>
              <input
                placeholder="Empowering Sections"
                type="text"
                name="empoweringSections"
                value={section}
                onChange={(e) => handleTagChange("empoweringSections", index, e)}
                onClick={() => handleTagClick("empoweringSections", index)}
                className={clsx(
                  "form-control form-control-light mb-3 mb-lg-0"
                )}
                autoComplete="off"
              />
              
            </div>
          ))}
          <div className="gap-5 d-flex mt-3">
          <button
                type="button"
                style={{ padding: 0, width: "35px" }}
                className="cursor-pointer"
                onClick={() => handleAddTagField("empoweringSections")}
              >
                +
              </button>
              <button
                type="button"
                style={{ padding: 0, width: "35px" }}
                className="cursor-pointer"
                onClick={() => handleRemoveTagField("empoweringSections", "")}
              >
                -
              </button>
              </div>
        </div>

          <div className="fv-row mb-7">
            <label className="fw-bold fs-6 mb-2">Supporting Documents</label>
            <select
              className="form-select form-select-light mb-3 mb-lg-0"
              aria-label="Select example"
              value={supportingDocuments}
              onChange={handleSelectSupporting}
            >
              <option hidden={true} value="">Select Option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="reset"
              className="btn btn-light mx-10"
              data-kt-users-modal-action="cancel"
              title="Cancel"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn  me-10 text-white btn-custom"
              id="kt_button_1"
              data-kt-users-modal-action="submit"
              title="Save"
            >
              <span className="indicator-label ">Save</span>
              <span className="indicator-progress  ">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TaggingForm;
