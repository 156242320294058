/** @format */

import React from "react";
import tags from "./../Assets/scalenew.png";

const OverallSentimentCard = ({ data, chartID }) => {

  const overallSentiments = data && data[5] && data[5].value;

  return (
    <div
      className="card  p-5 d-flex "
      style={{ backgroundColor: "#a2c4db",height:"100%" }}
    >
      <div className="ps-5 mb-3 d-flex align-items-center">
        <img
          src={tags}
          height="30px"
          width="30px"
          alt="logo"
          loading="lazy"
        />
        <div className="">
          <h2
            className="  text-black d-flex align-items-center pt-2 mx-4"
            style={{ fontWeight: "8px", fontSize: "17px", whiteSpace: "nowrap", }}
          > POLARITY
          </h2>
        </div>
      </div>
      <div className="text-white"
        style={{ marginTop: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 0.2" width="100%"
          style={{ display: "block", lineHeight: "0" }}>
          <line x1="0" y1="1" x2="36" y2="1"
            stroke="grey" strokeWidth="2" />
        </svg>
      </div>
      <div className="ps-6 text-black pt-10">

        <span
          className="fs-1 text-balck "
          style={{  }}>{overallSentiments}
        </span>

      </div>

    </div>
  );
};

export default OverallSentimentCard;
