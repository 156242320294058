import jwt_decode from "jwt-decode";
import { useStorage } from "../../../hooks/useStorage";
import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  // const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  const lsValue: string | null | object = useStorage.get(AUTH_LOCAL_STORAGE_KEY);

  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = lsValue as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    // localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    useStorage.put(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth();
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

const IsExpired = (): boolean => {
  let auth: any | string = getAuth()
  let token : any | string = auth?.token
  let decoded: any | string = jwt_decode(token)
  let tokenExpTime = decoded?.exp
  let currentTime = Date.now() / 1000 
  let t = tokenExpTime - currentTime
  // console.log(t)
  if (t <= 0) {
    return true
  } else {
    return false
  }
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, IsExpired };
