import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const CategoryCard = ({ data }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const category = data && data[3] && data[3].value;
  const country = data && data[4] && data[4].value;
  const STATUS = data && data[9] && data[9].value;

  const handleDataPointClick = () => {
    navigate(`/drilled-page/${id}?x=${category}`);
  };

  return (
    <div className="card  p-5 gap-2" style={{  backgroundColor: "#003F54",height:"100%" }}>
      <span className="P-5 text-white  " style={{ fontWeight: '26px', fontSize: '16px' }}>
        CATEGORY
      </span>
      <span
        onClick={handleDataPointClick} 
        className="fs-2 "
        style={{ fontWeight: '25px', fontSize: '14px', paddingTop: '5px', color: '#C6F404', cursor: 'pointer' }}
      >
        {category}
      </span>

      <span className="P-5 text-white pt-2" style={{ fontWeight: '18px', fontSize: '16px' }}>
        COUNTRY
      </span>
      <span className="fs-2" style={{ fontWeight: '25px', fontSize: '14px', paddingTop: '5px', color: '#C6F404' }}>
        {country}
      </span>
      <span className="P-5 text-white pt-2" style={{ fontWeight: '18px', fontSize: '16px' }}>
        LICENSE CATEGORY
      </span>
      <span className="fs-2" style={{ fontWeight: '25px', fontSize: '15px', paddingTop: '5px', color: '#C6F404' }}>
        {STATUS}
      </span>

    </div>
  );
};

export default CategoryCard;
