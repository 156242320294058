/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ID, KeywordSearchData, SourceData, Staticdata, stringifyRequestQuery } from "../../../theme/helpers";
import { Link, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import "./Dropdown.scss";
import { useListView } from "../../modules/apps/user-management/users-list/core/ListViewProvider";
import LikeDislikeCard from "./NewDashboardComponents/LikeDislikeCard";
import KeyThemeCard from "./NewDashboardComponents/Keythemes";
import SentimentsCard from "./NewDashboardComponents/Sentiments";
import usericon from "./Assets/usericon.png";
import neithlogo from "../../../assets/neithnewlogo.png"
import GradientTable from "./NewDashboardComponents/GradientTable";
import License from "./NewDashboardComponents/License";
import EngagementTable from "./NewDashboardComponents/EngagementTable";
import EngagementBar from "./NewDashboardComponents/EngagementBar";
import HorizontalBar from "./NewDashboardComponents/HorizontalBar";
import EngagementCluster from "./NewDashboardComponents/EngagementCluster";
import Licensees from "./NewDashboardComponents/TopPolarityPiechart";
import SportsPie from "./PolarityPiechart";
import KeyThemesBarTable from "./KeyThemesBarTable";
import TreeMap from "./NewDashboardComponents/TreeMap";
import KeyThemesBubble from "./NewDashboardComponents/KeyThemesBubble";
import { UserEditModal } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModal";
import { getkeyWordCount, getSourceList, getStaticdata } from "../../modules/apps/user-management/users-list/core/_requests";
import { useQuery } from "react-query";
import { useQueryRequest } from "../../modules/apps/user-management/users-list/core/QueryRequestProvider";
import "./NewDashboardComponents/HorizontalBar.css";
import SentimentBargraph from "./NewDashboardComponents/SentimentBargraph";
import SentimentBarChart from "./DynamicsentimentBargraph";
import Loader from '../../..//assets/loading.gif';
import FetchingSpinner from "./FetchingSpinners";
import { useAuth } from "../../modules/auth";


const Dashboard: FC = () => {
  const { id } = useParams();
  const [newdata, setNewData] = useState<Staticdata>();
  const {
    itemIdForUpdate,
  } = useListView();
  const [isKeyIssueModalOpen, setIsKeyIssueModalOpen] = useState(false);
  const [isParticipantModalOpen, setIsParticipantModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileList, setFileList] = useState<KeywordSearchData[]>([]);
  const [modalVisible, setModalVisible] = useState(true);
  const [load, setLoad] = useState(true)
  const [isfetching, setIsfetching] = useState(true)
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);
  const { currentUser } = useAuth();
  const username = currentUser?.username;
  const {
  } = useQuery([`GET_SOURCE_LIST-${query}`], () => {
    return getSourceList(query);
  }, { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false });

  //open model functionality
  const openAddModal = () => {
    setIsKeyIssueModalOpen(true);
  };
  const openAddUserModal = () => {
    setIsParticipantModalOpen(true);
  };
  const handleCancelClick = () => {
    setModalVisible(false);
  };
  const navigate = useNavigate();

  //view Document
  const onView = () => {
    navigate(`/viewDoc/view`, {
      state: {
        data: id
      },
    });
  };
  const onSearchListView = (docId: ID) => {
    navigate(`/viewDoc/view`, {
      state: {
        data: docId
      },
    });
  };

  //handleSearchEvent
  const handleSearchEvent = useCallback(async (event) => {
    const searchTerm = event.target.value;
    setSearchQuery(searchTerm);
    try {
      setLoad(true)
      const response = await getkeyWordCount(id, searchTerm, undefined, undefined);
      const data = response?.data || [];
      setFileList(data);
      setModalVisible(true);
      setLoad(false)
    } catch (error) {
      console.error(error);
    }
  }, [searchQuery]);

  //fetch static data api
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const newData = await getStaticdata(id, null, null);
        setNewData(newData);
        setIsfetching(false)
      }
    };
    fetchData();
  }, [id]);

  //props data from static data api
  const topParticipantsAlphabhetically = newdata?.data?.find(
    (item) => item.chartName === "topParticipantsAlphabhetically"
  )?.chartData;
  const ParticipationByCategory = newdata?.data?.find(
    (item) => item.chartName === "ParticipationByCategory"
  )?.chartData;
  const sportsBodyData = newdata?.data?.find(
    (item) => item.chartName === "sportsBodyData"
  )?.chartData;
  const participantsByLicense = newdata?.data?.find(
    (item) => item.chartName === "participantsByLicense"
  )?.chartData;
  const topPartcipantByPages = newdata?.data?.find(
    (item) => item.chartName === "topPartcipantByPages"
  )?.chartData;
  const extremeParticipantsByPages = newdata?.data?.find(
    (item) => item.chartName === "extremeParticipantsByPages"
  )?.chartData;
  const engagementCluster = newdata?.data?.find(
    (item) => item.chartName === "engagementCluster"
  )?.chartData;
  const topKeyThemes = newdata?.data?.find(
    (item) => item.chartName === "topKeyThemes"
  )?.chartData;
  const allKeyThemes = newdata?.data?.find(
    (item) => item.chartName === "allKeyThemes"
  )?.chartData;
  const categoryDropdownForSport = newdata?.data?.find((item) => item.chartName === "categoryDropdownForSport")?.chartData;
  const categoryDropdownForKeyTheme = newdata?.data?.find((item) => item.chartName === "categoryDropdownForKeyTheme")?.chartData;
  const sourceDatas = newdata?.data?.find((item) => item.chartName === "sourceDatas")?.chartData;
  const assessPolarity = newdata?.data?.find(
    (item) => item.chartName === "assessPolarity"
  )?.chartData;
  const overAllSentimentsScore = newdata?.data?.find(
    (item) => item.chartName === "overAllSentimentsScore"
  )?.chartData;
  const polarityChartOne = newdata?.data?.find(
    (item) => item.chartName === "polarityChartOne"
  )?.chartData;
  const firstIndexName = newdata?.data?.find(
    (item) => item.chartName === "polarityChartOne"
  )?.categoryName;
  const secondIndexName = newdata?.data?.find(
    (item) => item.chartName === "polarityChartTwo"
  )?.categoryName;
  const polarityChartTwo = newdata?.data?.find(
    (item) => item.chartName === "polarityChartTwo"
  )?.chartData;
  const topThreeSectionsTreeMap = newdata?.data?.find(
    (item) => item.chartName === "topThreeSectionsTreeMap"
  )?.chartData;
  const firstXValue = topThreeSectionsTreeMap && topThreeSectionsTreeMap[0] ? topThreeSectionsTreeMap[0].x : "";
  const sectionalAnalysisCount = newdata?.data?.find(
    (item) => item.chartName === "sectionalAnalysisCount"
  )?.chartData;
  const colors = ["#003F54", "#C6F404", "#2330FE", "#B8583A", "#4E7DB5", "#EF9D11"];
  const color = ["#004053", "#D2FF00", "#56BAB9"]
  const docName = sourceDatas && sourceDatas[1] && sourceDatas[1].value;
  const value = sourceDatas && sourceDatas[2] && sourceDatas[2].value;
  const uniqueNames = new Set();
  // Iterate through the data and add unique names to the Set
  allKeyThemes?.forEach(item => {
    uniqueNames?.add(item.name);
  });
  // Convert the Set to an array to get distinct names
  const distinctNames = Array.from(uniqueNames);

  return (
    <>{(isfetching == true) ?
      <div className="d-flex justify-content-center">
        <FetchingSpinner />
      </div> :
      <>
        <div className="row gy-5 gx-lg-8 mb-1 " style={{ marginTop: "-50px" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between",marginBottom:"1px" }}>
            {/* Neith Logo */}
            <div>
              <img
                src={neithlogo}
                height="80px"
                width="200px"
                alt="logo"
                loading="lazy"
              />
            </div>

            {/* Main content */}
            <div className="border-0 mt-5  justify-content-between ms-auto" style={{ marginBottom: "10px" }}>
              <div className="row">
                <div className="col-lg-8 d-flex align-items-center card-toolbar ms-3">
                  <img
                    src={usericon}
                    srcSet={`${usericon} 1x, ${usericon} 2x`}
                    alt="logo"
                    loading="lazy"
                    sizes="(max-width: 600px) 40px, 80px"
                    width="38px"
                    height="38px"
                  />

                  <span className="fs-4 ps-4 mt-2 d-flex align-items-center" style={{ whiteSpace: 'nowrap' }}>
                  Welcome to your Neith dashboard
                  </span>
                </div>
              </div>
            </div>

            {/* Search input and modal */}
            
          </div>
          <div className="col-lg-3 d-flex  mb-15  justify-content-end ms-auto">
              <div style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "end",
                position: "absolute",
                justifyContent: "flex-end"
              }}>
                <i className="bi bi-search" style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "white",
                  fontSize: "25px"
                }}></i>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control form-control-solid w-300px text-white  placeholder-white"
                  style={{
                    backgroundColor: "#6E8E8F",
                    paddingLeft: "50px",
                    color: "white"
                  }}
                  placeholder="Search With Keyword"
                  onKeyUp={handleSearchEvent}
                  list="searchResults"
                />
                {(modalVisible && searchQuery) && (
                  <>
                    <div className="modal fade show d-block" id="kt_modal_add_user" role="dialog" tabIndex={-1} aria-modal="true">
                      <div className="position-absolute top-50 start-50 translate-middle bg-white rounded shadow p-7 px-10"
                        style={{
                          zIndex: 1,
                          width: "500px",
                          maxHeight: "400px",
                          overflowY: "auto"
                        }}>
                        <div className="d-flex flex-column scroll-y me-n7 pe-4"
                          id="kt_modal_add_user_scroll"
                          data-kt-scroll="true"
                          data-kt-scroll-activate="{default: false, lg: true}"
                          data-kt-scroll-max-height="auto"
                          data-kt-scroll-dependencies="#kt_modal_add_user_header"
                          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                          data-kt-scroll-offset="500px"
                          style={{
                            overflowY: "scroll",
                            scrollbarWidth: "thin",
                            scrollbarColor: "black",
                          }}>
                          <div className="d-flex  justify-content-end mb-3">
                            <button title="close" onClick={handleCancelClick} className="btn btn-sm " style={{ backgroundColor: "#2C48FF" }}>
                              <i className="fas fa-times text-white"></i>
                            </button>
                          </div>
                          <a >
                            <table className="table table-hover mb-0 ">
                              <thead>
                                <tr>
                                  <th scope="col">File Name</th>
                                  <th scope="col">Participant Name</th>
                                  <th scope="col">Count</th>
                                </tr>
                              </thead>
                              {fileList.length > 0 ? (
                                <tbody>
                                  {fileList.map((item, index) => (
                                    <tr key={index}>
                                      <td className="text-hover-primary file-name" style={{ maxWidth: "220px", wordWrap: "break-word" }}>
                                        <a onClick={() => onSearchListView(item?.responseId)}>
                                          {item?.fileName}
                                        </a>
                                      </td>
                                      <td className="participant-name" style={{ maxWidth: "150px", wordWrap: "break-word" }}>
                                        {item?.participantName.join(", ")}
                                      </td>
                                      <td>{item?.count}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tr>
                                  <td colSpan={3}>
                                    <div className="d-flex text-center w-100 justify-content-center" style={{ marginLeft: "-30px" }}>
                                      {load == true && (
                                        <>
                                          <span className="me-2">Fetching data...</span>
                                          <img src={Loader} height='20px' alt="logo" />
                                        </>
                                      )}
                                      {(load == false && fileList.length == 0) &&
                                        "No matching records found"}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </table>

                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="modal-backdrop  fade show"></div>
                  </>
                )}
              </div>
            </div>
          
          <table className="w-100 ms-3" style={{ borderCollapse: 'collapse' }}>
            <tbody>
              <tr>
                <td style={{ verticalAlign: 'top', padding: '10px', width: "33%" }}>
                  <h3 style={{ color: "#333333", margin: '0', width: "33%" }}>
                    TITLE
                  </h3>
                </td>
                <td style={{ verticalAlign: 'top', padding: '10px', width: "33%" }}>
                  <h3 style={{ color: "#333333", margin: '0' }}>
                    ISSUING AUTHORITY
                  </h3>
                </td>
                <td style={{ verticalAlign: 'top', padding: '10px', width: "34%" }}>
                  <h3 style={{ color: "#333333", margin: '0' }}>
                    SUBMISSIONS RECEIVED
                  </h3>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: 'top', padding: '10px' }}>
                  <div style={{ whiteSpace: "pre-line", maxWidth: "300px" }}>
                    <b className="fs-5" style={{
                      fontSize: '14px',
                      color: '#6E8E8F',
                      margin: 0,
                      textTransform: "uppercase"
                    }}>
                      {sourceDatas && sourceDatas[0]?.value}
                    </b>
                  </div>
                  <button
                    type="button"
                    className="btn text-white mt-3"
                    title="View Document"
                    onClick={onView}
                    style={{
                      whiteSpace: 'nowrap',
                      backgroundColor: "#003F54",
                      padding: '0.25em 1em', // Adjust the padding to control the height
                      lineHeight: '1',
                    }}>
                    View Document
                  </button>
                </td>
                <td style={{ verticalAlign: 'top', padding: '10px' }}>
                  <div style={{ whiteSpace: "pre-line", maxWidth: "300px" }}>
                    <b className="fs-5" style={{
                      fontSize: '14px',
                      color: '#6E8E8F',
                      margin: 0,
                      textTransform: "uppercase"
                    }}>
                      {docName?.split("\n").map((line, index) => (
                        <div key={index} style={{ whiteSpace: "pre-line", margin: 0 }}>
                          {line}
                        </div>
                      ))}
                    </b>
                  </div>
                </td>
                <td style={{ verticalAlign: 'top', padding: '10px' }}>
                  <div style={{ whiteSpace: "pre-line", margin: 0, textTransform: "uppercase" }}>
                    <b className="fs-5" style={{ fontSize: '25px', color: '#6E8E8F', margin: 0 }}>
                      {value}
                    </b>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
                          
          <div className="col-lg-4">
            <LikeDislikeCard data={sourceDatas} chartID={"polarity"} />
          </div>
          <div className="col-lg-4">
            <KeyThemeCard data={sourceDatas} chartID={"key"} />
          </div>
          <div className="col-lg-4">
            <SentimentsCard data={sourceDatas} chartID={"section"} />
          </div>
        </div>

        <hr
          className="d-flex align-items-center"
          style={{
            border: "1px solid black",
            width: "100%",
          }} />
        <div>
          <div className="row gy-4 gx-xl-8 mt-7">
            <div className="d-flex align-items-center justify-content-center" style={{ color: "white", fontSize: "22px" }}>
              <b className="p-2 fs-4" style={{ backgroundColor: "#003F54", width: "100%" }}>
                Participation Analysis
              </b>
            </div>
            <div className="col-lg-12 mt-8">
              <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                {/* List Of All Participants */}
                <div className="  col-lg-4 mb-4">
                  <div className="card pt-5 pb-8" style={{ backgroundColor: "#004053", width: "100%", height: "auto", }}>
                    <GradientTable chartID="donutChaart2" title={"Participants (alphabetical)"} data={topParticipantsAlphabhetically} />
                    <div>
                      {topParticipantsAlphabhetically?.length > 10 && (
                        <Link
                          className="align-items-start justify-content-start ms-3"
                          style={{
                            color: "#F8F770",
                            textDecoration: "none",
                            display: "inline-block",
                            borderBottom: "0.4pt solid #F8F770",
                          }}
                          onClick={openAddUserModal}
                          to={""}
                        >
                          More Participants
                        </Link>
                      )}
                      {isParticipantModalOpen == true && (
                        <UserEditModal
                          content={itemIdForUpdate ? "edit_response" : "add_participanttable"}
                          modalText={itemIdForUpdate ? "Edit Response" : "Participant Table"}
                          onCloseEditModal={() => setIsParticipantModalOpen(false)}
                          user={topParticipantsAlphabhetically}
                          data={topParticipantsAlphabhetically}
                          id={id}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* Participation By Category */}
                <div className="  col-lg-4 text-primary ps-5 "  >
                  <TreeMap data={ParticipationByCategory} title={"Participation By Category"} className={""} chartID={"category"} />
                </div>

                {/* Participation By Territory */}
                <div className="col-lg-4">
                  <div className="col-lg-12  text-primary">
                    <div className="">
                      <HorizontalBar chartID="drillstack-bar-3" data={sportsBodyData} height="200px" colors={colors} title={"Participation By Territory"} />
                    </div>
                  </div>

                  {/* Participation By Licensees */}
                  <div className="col-lg-12 mt-3 text-primary">
                    <div className="">
                      <License title={"Participation By Licensees"} chartID="pie-chart" data={participantsByLicense} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr
          className="d-flex align-items-center"
          style={{
            border: "1px solid black",
            width: "100%",
          }} />
        <div className="row gy-4 gx-xl-8 ">
          <div className="d-flex align-items-center justify-content-center" style={{ color: "white", fontSize: "22px" }}>
            <b className="p-2 fs-4" style={{ backgroundColor: "#003F54", width: "100%" }}>
              Engagement Analysis by number of pages
            </b>
          </div>

          <div className="col-lg-6 mt-10">

            <EngagementTable data={topPartcipantByPages} />
          </div>

          <div className="col-lg-6 mt-10">

            <EngagementBar chartID="engagementBar1" category={categoryDropdownForSport} title={"Engagement By Category"} />
          </div>
        </div>

        <div className="row gy-4 gx-xl-8" style={{ marginTop: "30px" }}>
          <div className="col-lg-12">
            <EngagementCluster
              data={engagementCluster}
              className="pt-20"
              title={""}
              extremeParticipantsByPages={extremeParticipantsByPages} />
          </div>
        </div>
        <hr
          className="d-flex align-items-center"
          style={{
            border: "1px solid black",
            width: "100%",
          }} />
        <div className="row gy-4 gx-xl-8 mt-0 mb-10">
          <div className="d-flex align-items-center justify-content-center" style={{ color: "white", fontSize: "22px" }}>
            <b className="p-2 fs-4" style={{ backgroundColor: "#003F54", width: "100%" }}>
              Analysis of Participant Concerns
            </b>
          </div>
          <div className="  col-lg-6 ">
            <div className=" card   mt-10   " style={{ backgroundColor: "white", height: "auto" }}>
              <KeyThemesBarTable chartID="topKeyThemes1" data={topKeyThemes} title={"Top 3 Concerns Raised"} />
              <div className="mb-12 ms-3" style={{ marginTop: "159px" }}>
                {distinctNames?.length > 3 && (
                  <Link className=" align-items-start justify-content-start ms-3 " onClick={openAddModal} to={""}
                    style={{
                      textDecoration: "none",
                      display: "inline-block",
                      borderBottom: "0.4pt solid #0095EC",
                    }}>
                    More Key Issues
                  </Link>
                )}
                {isKeyIssueModalOpen == true && (
                  <UserEditModal
                    content={"add_KeyIssuesTable"}
                    modalText={itemIdForUpdate ? "Edit Response" : "Table of Key Concerns"}
                    onCloseEditModal={() => setIsKeyIssueModalOpen(false)}
                    user={allKeyThemes}
                    chartID="topKeyThemes1"
                    title={"Key Concerns"}
                    data={allKeyThemes}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="  col-lg-6 mb-4">
            <div className="mt-10 " style={{}}>

              <KeyThemesBubble category={categoryDropdownForKeyTheme} chartID={"keyissueBubblegraph"} data={[]} title={""} />
            </div>
          </div>
        </div>

        <hr
          className="d-flex align-items-center"
          style={{
            border: "1px solid black",
            width: "100%",
          }} />
        <div className="row gy-4 gx-xl-8 mt-7">
          <div className="d-flex align-items-center justify-content-center mb-5" style={{ color: "white", fontSize: "22px" }}>
            <b className="p-2 fs-4" style={{ backgroundColor: "#003F54", width: "100%" }}>
              Polarity Analysis
            </b>
          </div>
          <div className="col-lg-6 text-primary">
            <HorizontalBar chartID="drillstack-bar-7" data={assessPolarity} height="200px" colors={color} title={"Assess level of Polarity"} />
          </div>
          <div className=" col-lg-6  " style={{ width: "" }}>
            <div className="card pb-6">
              <div className="p-4  mt-7 fs-3 d-flex align-items-start justify-content-start" style={{ color: "#769495", fontSize: "18px" }}>
                <h4>Polarity by Category</h4>
              </div>
              <div className="row gx-xxl-0" style={{ marginTop: "-18px", width: "" }}>
                <div className="col-lg-6 ">
                  <Licensees title={firstIndexName} chartID="pieoneesd" data={polarityChartOne} />
                </div>
                <div className="col-lg-6">
                  <SportsPie title={secondIndexName} chartID="piepolarity" data={polarityChartTwo} />
                </div>
              </div>
            </div>
          </div>
          <div className=" card mt-10 ms-4 col-lg-12" style={{ width: "99%" }}>
            <div className="p-4 fs-3 mt-4 d-flex align-items-start justify-content-start" style={{ color: "#769495", fontSize: "18px" }}>
              <h4>Overall Sentiments</h4>
            </div>
            <SentimentBargraph title={"Overall Sentiments"} chartID="point-graph" data={overAllSentimentsScore} />
          </div>
        </div>

        <hr
          className="d-flex align-items-center"
          style={{
            border: "1px solid black",
            width: "100%",
          }} />

        <div className="  mt-7">
          <div className="d-flex align-items-center justify-content-center" style={{ color: "white", fontSize: "22px" }}>
            <b className="p-2 fs-4" style={{ backgroundColor: "#003F54", width: "100%" }}>
              Sectional Analysis
            </b>
          </div>
          <div className="row">
            <div className="col-lg-6    mt-10 pt-5 text-primary " style={{}}>
              <div className="card pb-20" >
                <TreeMap data={topThreeSectionsTreeMap} title={"Most Mentioned Sections"} className={""} chartID={"topsentiment"} />
              </div>
            </div>
            <div className=" col-lg-6 mt-10 pt-5 text-primary " >
              <div className="card">
                <SentimentBarChart defaultsection={firstXValue} data={sectionalAnalysisCount} />
              </div>
            </div>
          </div>
        </div>

      </>
    }
    </>
  );
};

const MainDashboard: FC = () => {

  return (
    <>
      <Dashboard />
    </>
  );
};

export { MainDashboard };
