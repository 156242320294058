import React, { useEffect, useState } from "react";

const KeyThemesBarGraph1 = (props) => {
  const title = props.title
  const data = props.data
  const [filteredData, setFilteredData] = useState<any>();
  const barColors = ["#2330FE", "#93BDC1", "#93BDC1", "#D4FE19", "#D4FE19", "#003F54"]; // Add more colors if needed

  useEffect(() => {
    const totalValue = data?.reduce((acc, item) => acc + item.value, 0);
    const adjustedData = data?.map((item, index) => ({
      ...item,
      percentage: (item.value / totalValue) * 100,
      color: barColors[index % barColors.length] // Assign color based on index
    }));
    setFilteredData(adjustedData);
  }, [data]);

  return (
    <div className="card bg-transparent py-4 px-6 d-flex" style={{ height: "100%", overflow: "auto" }}>
      <div>
        <div className="d-flex align-items-center justify-content-center text-hover-primary me-10" style={{ color: "#769495", fontSize: "20px", height: "76px", width: "100%", overflow: "hidden" }} title={title}>
          <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {title}
          </span>
        </div>

        <div className="p-1 mt-5 " style={{ height: "200px", width: "400px", }}>
          {filteredData?.map((item, index) => (
            <div key={index} style={{ marginBottom: "10px", backgroundColor: "#EAF2F2" }}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                <div style={{ width: "200px", textAlign: "right", marginRight: "10px" }}>{item?.name}</div>
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                  <div style={{ width: `${item?.percentage * 1.5}%`, height: "15px", backgroundColor: item.color, borderRadius: "2px" }} />
                  <div style={{ marginLeft: "5px" }}>{item?.percentage?.toFixed(2)}%</div>
                </div>
              </div>

            </div>

          ))}
          <hr
            className="d-flex align-items-center"
            style={{
              marginTop: "2px",
              border: "1.5px solid black",
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default KeyThemesBarGraph1;
