import React from 'react';
import background from "../../../../assets/NeithRegistration.jpg";
import neithlogo from "../../../../assets/neith.png";
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../core/Auth';


const TermsAndConditions = () => {
    const { accept, setAccept } = useAuth()
    const navigate = useNavigate();
    const handleAccept = () => {
        setAccept(false)
        navigate("/welcome")
    }
    return (
        <><div
            style={{
                display: "flex",
                flex: 1,
                minHeight: "120vh",
                backgroundImage: `url(${background})`,
                justifyContent: "center",
                alignItems: "center",
                color: "white"
            }}
        >
            <div style={{ position: "absolute", top: 20, left: 20, }}>
                <img
                    src={neithlogo}
                    height="120px"
                    width="230px"
                    alt="logo"
                    loading="lazy"
                /></div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', maxWidth: '600px', margin: '0 auto',marginTop:"145px" }}>
                <h2 style={{ color: "#95BEC1" }}>Terms and Conditions</h2>
                <h4 className='text-white mt-4'>
                    Here are the terms and conditions governing your participation in the pilot.
                    By clicking “accept” below, you agree to participate in the pilot and to adhere
                    to these terms and conditions.
                </h4>
                <ol className='mt-4' style={{ fontSize: "13px" }}>
                    <li>
                        The “Pilot Service” means the service that Neith has designated as a pilot, limited release, developer preview, or similar
                        description that will be made available to you for a limited period.
                    </li>
                    <li>
                        Neith will make the Pilot Service available to you for internal testing and evaluation purposes only. Rights of use and
                        access will be granted to you for a limited period, the duration of which will be determined by Neith (“the testing period”).
                        These rights are granted subject to the condition that you do not copy, modify, adapt, tamper with, reverse engineer,
                        disassemble, decompile, translate the pilot service, in whole or in part, or otherwise attempt to discover any source code,
                        algorithms, tags, specifications, architecture, structure, or other elements of the pilot service and do not allow any affiliate
                        or third party to do the same. Neith may cancel your access to the Pilot Service at any time in its sole discretion.
                    </li>
                    <li>You agree to provide timely feedback to Neith regarding the pilot service, as reasonably requested. You will provide
                        Neith with any suggestions, enhancement requests, recommendations or other feedback relating to the operation of the
                        pilot service and Neith shall be entitled to use and to incorporate such suggestions, requests or recommendations.</li>
                    <li>Neith owns all Intellectual Property Rights in and to any feedback and may use pilot feedback for any purpose related
                        to the pilot service without further approval or acknowledgement. To the extent necessary, you hereby assign to Neith
                        any and all rights, including without limitation all Intellectual Property Rights, in Pilot Feedback throughout the universe
                        in perpetuity.</li>
                    <li>All rights in and to the Pilot Service remain with Neith and, subject to the limited rights of access expressly granted in
                        these terms and conditions, Neith reserve all rights, title, and interest in and to the Pilot Service including without
                        limitation all related Intellectual Property Rights.</li>
                    <li>The Pilot Service is provided “as-is” and “as available”, Neith makes no warranties or representations whatsoever about
                        any content or information made accessible by the Pilot Service or about the fitness of the Pilot Service for any particular
                        purpose. You acknowledge that the Pilot Service is a prototype, which may contain bugs or errors. Any use of the Pilot
                        Service is at your own risk.</li>
                    <li>The Pilot Service is provided “as-is” and “as available”, Neith makes no warranties or representations whatsoever about
                        any content or information made accessible by the Pilot Service or about the fitness of the Pilot Service for any particular
                        purpose. You acknowledge that the Pilot Service is a prototype, which may contain bugs or errors. Any use of the Pilot
                        Service is at your own risk.</li>
                    <li>We collect and process your personal information mainly to contact you for the purposes of getting your feedback
                        and evaluation of the Pilot Service. For this purpose we will collect contact details including your name and organisation.
                        We will use your personal information only for the purposes for which it was collected and agreed with you. In addition,
                        where necessary your information may be retained for legal or research purposes. We may disclose your personal
                        information to our service providers who are involved in research and development. We have agreements in place
                        to ensure that they comply with the privacy requirements as required by the Protection of Personal Information Act.</li>
                    {/* ... Add more terms here */}
                </ol>
                <p>
                    Please read the full terms and conditions document for a comprehensive understanding of your obligations and Neith's rights.
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
                    <button className="btn rounded-pill" style={{ backgroundColor: "#D3FD35", color: "#31684D" }} onClick={handleAccept}>Accept</button>
                    {/* <button className="btn rounded-pill" style={{ backgroundColor: "#D3FD35", color: "#31684D", marginLeft: '10px' }}>Login</button> */}
                </div>
                {/* Add an "Accept" button here */}
            </div>


        </div></>



    );
};

export default TermsAndConditions;
