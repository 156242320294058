/**
 * /* eslint-disable react/jsx-no-target-blank
 *
 * @format
 */

import { useEffect } from "react";
import { ILayout, useLayout } from "../../core";

const Footer = () => {
	const { config } = useLayout();
	useEffect(() => {
		updateDOM(config);
	}, [config]);
	return (
		<>
			<div className="text-dark order-2 order-md-1 text-align-center ">
				<span className="text-muted fw-semibold me-1">
					{new Date().getFullYear().toString()}&copy;
				</span>
				{/* <a
					href="https://trisysit.com/"
					target="_blank"
					className="text-gray-800 text-hover-primary"
				> */}
				<span className="text-muted fw-semibold me-1">
					NeithCo (Pty) Ltd. South Africa
				</span>
				<span className=" text-muted font-weight-bold" >
					v1.0
				</span>
				{/* </a> */}
			</div>

			{/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
				<li className="menu-item">
					<a
						href="https://trisysit.com/"
						target="_blank"
						className="menu-link px-2"
					>
						About
					</a>
				</li>

				<li className="menu-item">
					<a
						href="https://www.trisysit.com/"
						target="_blank"
						className="menu-link px-2"
					>
						Support
					</a>
				</li>

				<li className="menu-item">
					<a
						href="https://mydomain.com/terms-and-conditions"
						target="_blank"
						className="menu-link px-2"
					>
						Terms & Conditions
					</a>
				</li>
			</ul> */}
		</>
	);
};

const updateDOM = (config: ILayout) => {
	if (config.app?.footer?.fixed?.desktop) {
		document.body.classList.add("data-kt-app-footer-fixed", "true");
	}

	if (config.app?.footer?.fixed?.mobile) {
		document.body.classList.add("data-kt-app-footer-fixed-mobile", "true");
	}
};

export { Footer };
