/**
 * /* eslint-disable react/jsx-no-target-blank
 *
 * @format
 */

import React from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import masterlogo from "../../../../../assets/Mastermngmt.png";
import sourcelogo from "../../../../../assets/Sourcemanagement.png";
import { SidebarMenuItemsWithSub } from "./SidebarMenuItems";
import SidebariconMenuitem  from "./SidebariconMenuitem";

import { useAuth } from "../../../../../app/modules/auth/core/Auth";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const { currentUser,draft,setDraft } = useAuth();
  const ROLE: any | undefined = currentUser?.roles;
  return (
    <>

<>
        {ROLE[0] == "PILOT_USER" && (
          <>
            <SidebariconMenuitem
              to={`/dashboard/${currentUser?.documentId}`}
              title="Dashboard"
              icon={sourcelogo}
            />
          </>
        )}
      </>
      <>
        {ROLE[0] == "DASHBOARD" && (
          <>
            <SidebariconMenuitem
              to={`/dashboard/${currentUser?.sourceId}`}
              title="Dashboard"
              icon={sourcelogo}
            />
          </>
        )}
      </>
      <>
        {ROLE[0] == "ADMIN" && (
          <>
            <SidebariconMenuitem
              to={`/dashboard/${draft? draft:currentUser?.sourceId}`}
              title="Dashboard"
              icon={sourcelogo}
            />
          </>
        )}
      </>
      <>
        {ROLE[0] == "ADMIN" && (
          <>
            <SidebariconMenuitem
              to="/source-management/source"
              icon={sourcelogo}
              title="Source Management"
            />
            <SidebariconMenuitem
              to="/user-management/users"
              icon={sourcelogo}
              title="User Management"
            />
          </>
        )}
      </>
    </>
  );
};

export { SidebarMenuMain };
