import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { getDynamicSportingData } from "../../../modules/apps/user-management/users-list/core/_requests";
import "./HorizontalBar.css";

const BarGraph = ({ category, chartID, id }) => {
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDynamicSportingData(id, category);
        const chartData = response.data.chartData || []; // Extract chartData from the response

        setGraphData(chartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, category]);
  const barColors = [
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
    am5.color("#003F54"),
  ];
  

  useEffect(() => {
    if (graphData.length > 0) {
      const truncateCategory = (name: string, index: number) => {
        const MAX_LABEL_LENGTH =6; // Change '10' to your desired character limit
        if (typeof name === "string" && name.length > MAX_LABEL_LENGTH) {
          return name.substring(0, MAX_LABEL_LENGTH ) + ` (${index + 1})`;
        }
        return name;
      };
      const modifiedData = graphData?.map((item: any, index) => ({
        ...item,
        name: truncateCategory(item.name, index),
        value: parseInt(item.value), // Parse the value to an integer
      }));
      const root = am5.Root.new(chartID);
      root.setThemes([am5themes_Animated.new(root)]);

      const chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true
      }));

      const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);

      const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15
      });

      xRenderer.grid.template.setAll({
        location: 1
      });

      const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "name",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        //maxDeviation: 0.3,
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }));

      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "name",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueY}"
        })
      }));

      series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
      series.columns.template.adapters.add("fill", function (fill, target) {
        const index = series.columns.indexOf(target);
        return barColors[index];
      });

      xAxis.data.setAll(modifiedData);
      series.data.setAll(modifiedData);

      series.appear(1000);
      chart.appear(1000, 100);
      
      // dispose logo
      root._logo?.dispose()

      // Clean up when component unmounts
      return () => {
        root.dispose();
      };
    }
  }, [graphData]);

  return <div>
    <div className="y-axis-label1 fs-4 ms-7 ">Length of Submission</div>
    <div className="ms-10" id={chartID} style={{ width: "95%", height: "400px" }}>
      {/* <div className="y-axis-label fs-4 mt-25">Length of Submission</div> */}
    </div>
  </div>

};

export default BarGraph;
