/**
 * /* eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { FC, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { MenuComponent } from "../../../../../../../theme/assets/ts/components";
import { ID, KTSVG, QUERIES } from "../../../../../../../theme/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteUser } from "../../core/_requests";

type Props = {
	cases?: string;
	id: ID;
	onDelete?: () => void;
	onEdit?: () => void;
	className?: string;
};

const UserActionsCell: FC<Props> = ({ id, onEdit, cases, className }) => {
	const { setItemIdForUpdate } = useListView();
	const { setItemIdForDelete } = useListView();
	const { query } = useQueryResponse();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	useEffect(() => {
		MenuComponent.reinitialization();
	}, []);

	const openEditModal = () => {
		setItemIdForUpdate(id);
	};
	

	const onDelete = () => {
		setItemIdForDelete(id);
	};
	const onFormat = () => {
		navigate(`format-response/${id}`);
	};
	const onAssign = () => {
		navigate(`/user-management/users/document/${id.username}/assign`, {
			state: id.username,
		});
	};

	const deleteItem = useMutation(() => deleteUser(id), {
		// 💡 response of the mutation is passed to onSuccess
		onSuccess: () => {
			// ✅ update detail view directly
			queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
		},
	});

	return (
		<div className={className}>
			<a
				href="#"
				className="btn btn-light btn-active-light-primary btn-sm"
				data-kt-menu-trigger="click"
				data-kt-menu-placement="bottom-end"
			>
				Actions
				<KTSVG
					path="/media/icons/duotune/arrows/arr072.svg"
					className="svg-icon-5 m-0"
				/>
			</a>
			{/* begin::Menu */}
			<div
				className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
				data-kt-menu="true"
			>
				{/* begin::Menu item */}
				<div className="menu-item px-3">
					<a
						className="menu-link px-3"
						onClick={onEdit ? onEdit : openEditModal}
					>
						Edit
					</a>
				</div>
				{/* end::Menu item */}

				{/* begin::Menu item */}
				<div className="menu-item px-3">
					<a
						className="menu-link px-3"
						data-kt-users-table-filter="delete_row"
						onClick={onDelete}
					>
						Delete
					</a>
				</div>

				{cases === "response" && (
					<div className="menu-item px-3">
						<a className="menu-link px-3" onClick={onFormat}>
							Format
						</a>
					</div>
				)}
				{cases === "users" && (
					<div className="menu-item px-3">
						<a className="menu-link px-3" onClick={onAssign}>
							Assign
						</a>
					</div>
				)}
			</div>
			{/* end::Menu */}
		</div>
	);
};

export { UserActionsCell };
