/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { useState, useEffect } from "react";
import {
	initialQueryState,
	KTSVG,
	useDebounce,
} from "../../../../../../../theme/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
type props={
	placeholder:string;
}
const UsersListSearchComponent = (props:props) => {
	const { updateState } = useQueryRequest();
	const [searchTerm, setSearchTerm] = useState<string>("");
	// Debounce search term so that it only gives us latest value ...
	// ... if searchTerm has not been updated within last 500ms.
	// The goal is to only have the API call fire when user stops typing ...useQueryRequest
	// ... so that we aren't hitting our API rapidly.
	const debouncedSearchTerm = useDebounce(searchTerm, 150);
	// Effect for API call

	let { show } = useListView();
	let { setShow } = useListView();

	useEffect(
		() => {
			if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
				updateState({ search: debouncedSearchTerm, ...initialQueryState });
			}
		},
		[debouncedSearchTerm] // Only call effect if debounced search term changes
		// More details about useDebounce: https://usehooks.com/useDebounce/
	);

	const handleChange = (e:any) => {
		if (setShow) {
			setSearchTerm(e.target.value);
			let delayedAction = () => {
				return setShow ? (setShow(false)) : null
			}
			if (e.target.value.length > 0) {
				setShow(true);
			} else {
				setTimeout(() => {
					delayedAction()
				}, 200);

			}
		}
	};

	return (
		<div className="card-title">
			{/* begin::Search */}
			<div className="d-flex align-items-center position-relative my-1">
				<KTSVG
					path="/media/icons/duotune/general/gen021.svg"
					className="svg-icon-1 position-absolute ms-6"
				/>
				<input
					type="text"
					data-kt-user-table-filter="search"
					className="form-control form-control-solid w-250px ps-14"
					placeholder={props.placeholder}
					value={searchTerm}
					onChange={(e) => handleChange(e)}
				/>
			</div>
			{/* end::Search */}
		</div>
	);
};

export { UsersListSearchComponent };
