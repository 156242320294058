/** @format */

import { ListViewProvider, useListView } from "./core/ListViewProvider";
import {
	QueryRequestProvider,
	useQueryRequest,
} from "./core/QueryRequestProvider";
import {
	useQuerySourceData,
	SourceQueryResponseProvider,
	useSourceResponsePagination,
	useQuerySourceResponseLoading,
	useSourceQueryResponse,
	useQueryResponse,
} from "./core/QueryResponseProvider";
import { useQueryClient } from "react-query";
import { UsersListHeader } from "./components/header/UsersListHeader";
import { UsersTable } from "./table/UsersTable";
import { UserEditModal } from "./user-edit-modal/UserEditModal";
import { KTCard } from "../../../../../theme/helpers";
import { DeleteModal } from "../../../widgets/components/DeleteModal";
import { usersColumns } from "./table/columns/_columns";
import { useMemo, useEffect, useState } from "react";
import { stringifyRequestQuery } from "../../../../../theme/helpers";
import { deleteSource } from "./core/_requests";
import { Toast } from "./Toast";
const SourceList = () => {
	const queryClient = useQueryClient();
	const {
		setItemIdForDelete,
		itemIdForUpdate,
		setItemIdForUpdate,
		itemIdForDelete,
		toastMessage,
		setToastMessage,
	} = useListView();
	const { refetch } = useQueryResponse();
	const columns = useMemo(() => usersColumns(), []);
	const source = useQuerySourceData();
	const isLoading = useQuerySourceResponseLoading();
	const { isFetching } = useSourceQueryResponse();
	const data = useMemo(() => source, [source]);
	const pagination = useSourceResponsePagination();
	const { updateState, state } = useQueryRequest();
	const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
	const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

	useEffect(() => {
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery, query]);

	const updatePage = (page: number | null) => {
		refetch();
		if (!page || isLoading || pagination.page === page) {
			return;
		}
		updateState({ page, items_per_page: pagination.items_per_page || 10 });
		refetch();
	};
	const handleDelete = async () => {
		try {
			await deleteSource(itemIdForDelete?.draftId);
			queryClient.invalidateQueries([`GET_SOURCE_LIST-${query}`]);
			setItemIdForDelete(undefined);
			setToastMessage("Source Deleted Successfully");
			setTimeout(() => {
				setToastMessage(undefined);
			}, 2000);
		} catch (error) {
			console.error("Error deleting source:", error);
		}
	};

	return (
		<>
			<KTCard>
				<UsersListHeader title="Add Source" placeholder="Search by Name" />
				<div className="px-9 pt-3">
					<span className="text-muted fw-bolder  mb-0  fs-6  ">
						{pagination.total !== 0
							? `Displaying ${pagination?.from || 0} to ${pagination?.to || 0
							} of
					${pagination?.total || 0} records`
							: `Displaying 0 to 0 of 0 records`}
					</span>
				</div>
				<UsersTable
					columns={columns}
					users={data}
					isLoading={isLoading}
					isFetching={isFetching}
					onNextPage={updatePage}
					pagination={pagination} id={""} data={[]} />
			</KTCard>
			{itemIdForUpdate !== undefined && (
				<UserEditModal
					content={itemIdForUpdate?.action === 'tag' ? "tag_source" : (itemIdForUpdate?.draftId ? "edit_source" : "add_source")}
					modalText={itemIdForUpdate?.action === 'tag' ? "Tag Source" : (itemIdForUpdate?.draftId ? "Edit Source" : "Add Source")}
					onCloseEditModal={() => setItemIdForUpdate(undefined)}
					user={itemIdForUpdate}
				/>
			)}
			{itemIdForDelete !== undefined && <DeleteModal onDelete={handleDelete} />}
			{toastMessage !== undefined && <Toast title={toastMessage} color="alert-success" />}
		</>
	);
};

const SourceListWrapper = () => (
	<QueryRequestProvider>
		<SourceQueryResponseProvider>
			<ListViewProvider>
				<SourceList />
			</ListViewProvider>
		</SourceQueryResponseProvider>
	</QueryRequestProvider>
);

export { SourceListWrapper };
