/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 *
 * @format
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth, IsExpired } from "../modules/auth";
import { App } from "../App";
import WelcomeComponent from "../modules/auth/components/Welcome";
import TermsAndConditions from "../modules/auth/components/Termsandcondition";
import { Registration } from "../modules/auth/components/Resistration";
import { Login } from "../modules/auth/components/Login";
import { AuthLayout } from "../modules/auth/AuthLayout";
// import { Registration } from "../modules/auth/components/Registration";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
	const { currentUser } = useAuth();
	let expired: boolean | undefined;
	if (currentUser !== undefined) {
		expired = IsExpired();
	}

	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Routes>
			
				
				<Route element={<App />}>
				
				<Route path="welcome" element={<WelcomeComponent />} />
				<Route path="terms" element={<TermsAndConditions />} />
				<Route path="register" element={<Registration />} />
					<Route path="error/*" element={<ErrorsPage />} />
					<Route path="logout" element={<Logout />} />
					{currentUser && !expired ? (
						<>
							<Route path="/*" element={<PrivateRoutes />} />
							<Route index element={<Navigate to="/dashboard" />} />
							
						</>
					) : (
						<>
							<Route path="auth/*" element={<AuthPage />} />
							<Route path="*" element={<Navigate to="/auth" />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export { AppRoutes };
