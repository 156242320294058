import React, { useEffect, useState, useCallback } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { KTCard } from "../../../../theme/helpers";
import { useParams } from "react-router-dom";
import * as am5xy from "@amcharts/amcharts5/xy";
import { getdynamicKeyThemesData } from "../../../modules/apps/user-management/users-list/core/_requests";
import tags from "../Assets/nodata.png";
interface CategoryPieChartProps {
  chartID: string;
  data: Array<{ name: string; value: number }>;
  category: string[];
  title: string;
}
function KeyThemesBubble(props: CategoryPieChartProps) {
  const chartID = props?.chartID;
  const category = props?.category || [];
  const { id } = useParams();
  const defaultCategory = category.length > 0 ? category[0] : "Select Category";
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
  const [newdata, setNewData] = useState([]);
  const noDataMessage = (
    <div
      id={chartID}
      className="d-flex flex-column align-items-center justify-content-center  ps-20"
      style={{
        height: "200px",
        width: "100%",
        marginLeft: "-40px",
        marginBottom: "-300px",
        marginTop: "120px",
      }}
    >
      <div style={{ marginBottom: "10px" }}>
        <img src={tags} height="40px" width="40px" alt="logo" />
      </div>
      <div>
        <p className="fs-3">No data to display(criteria not matched)</p>
      </div>
    </div>
  );
  const fetchDataForCategory = async (categoryToFetch) => {
    try {
      const response = await getdynamicKeyThemesData({
        id: id,
        category: categoryToFetch,
        participant: null,
      });
      const data = response?.data?.chartData || [];
      setNewData(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataForCategory(defaultCategory);
  }, [id, defaultCategory]);

  const handleSelectChange = useCallback(
    async (event) => {
      const selectedCategory = event.target.value || defaultCategory;
      setSelectedCategory(selectedCategory);
      fetchDataForCategory(selectedCategory);
    },
    [id, defaultCategory]
  );

  useEffect(() => {
    let root = am5.Root.new(chartID);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        pinchZoomX: true,
      })
    );

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    let yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.grid.template.set("location", 0.5);
    yRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        maxDeviation: 0,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeWidth: 0,
          strokeOpacity: 0.3,
        }),
      })
    );

    xAxis.data.setAll(newdata);

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "name",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yAxis.get("renderer").grid.template.setAll({
      strokeWidth: 0,
      visible: false,
    });

    yAxis.data.setAll(newdata);

    function createSeries(name, field) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          categoryYField: "name",
          valueXField: field,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: `[bold]{name}[/]\n{categoryY}: {${field}}`,
          }),
        })
      );

      const bubbleColors = ["#19247B"];

      for (let i = 0; i < newdata.length; i++) {
        let dataItem = newdata[i];
        const bubbleColor = bubbleColors[i % bubbleColors.length];
        let radius = 15;

        // Adjust the color assignment logic based on a property in the data item (e.g., field)
        series.bullets.push(() =>
          am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: radius,
              fill: am5.color(bubbleColor),
            }),
          })
        );
      }

      series.strokes.template.setAll({
        strokeWidth: 3,
        strokeOpacity: 0,
      });

      series.data.setAll(newdata);
      series.appear(1000);
    }

    createSeries("Participant", "value");

    chart.appear(1000, 100);
    //dispose logo
    root._logo?.dispose();

    return () => {
      root.dispose();
    };
  }, [chartID, newdata]);

  return (
    <KTCard className="rounded">
      <div
        className="p-4 mt-3 fs-3 d-flex align-items-start justify-content-start"
        style={{ color: "#769495", fontSize: "18px" }}
      >
        <h4>Key Concerns By Category</h4>
      </div>
      <div className="" style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ alignSelf: "flex-end" }}>
          <select
            className="form-select mb-7"
            value={selectedCategory}
            onChange={handleSelectChange}
          >
            {category?.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        {newdata?.length === 0 ? (
          <div id={chartID} style={{ height: "530px", width: "100%" }}>
            {noDataMessage}
          </div>
        ) : (
          <div id={chartID} style={{ height: "530px", width: "100%" }}></div>
        )}
      </div>
    </KTCard>
  );
}

export default KeyThemesBubble;
