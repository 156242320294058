import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";

export default function PaginatedItems({ pagination, onNextPage,id }) {
  const handlePageClick = (selectedItem) => {
    onNextPage(selectedItem.selected + 1);

    // Scroll to the list when a pagination link is clicked
    const targetList = document.getElementById(id);
    if (targetList) {
      targetList.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="d-flex ustify-content-end text-white mt-5 mb-5">
        <ReactPaginate
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pagination.last_page}
          previousLabel="< Previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={() => null}
        />
      </div>
    </>
  );
}
