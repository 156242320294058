/** @format */

import React from "react";
import dollar from "./../Assets/forum.png";

const SentimentsCard = ({ data, chartID }) => {
  const mostCommentedSection = data && data[6] && data[6].value;
  return (
    <div
      className="card card-header card-title  p-5 d-flex rounded"
      style={{
        backgroundColor: "#bac1fb",
        height: "100%",
        width:
          chartID === "section" || chartID === "section1" ? "auto" : "100%",
      }}
    >
      <div className="ps-5 mb-3 d-flex ">
        <img
          src={dollar}
          height="33px"
          width="33px"
          alt="logo"
          loading="lazy"
        />
        <div className="d-flex ">
          <h2
            className=" text-black ps-4  pt-3"
            style={{
              fontWeight: "10px",
              fontSize: chartID === "section" ? "17px" : "17px",
              whiteSpace: "nowrap",
            }}
          >
            MOST COMMENTED SECTION
          </h2>
        </div>
      </div>
      <div className="text-grey " style={{ marginTop: "" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 0.2"
          width="100%"
        >
          <line x1="0" y1="1" x2="36" y2="1" stroke="grey" strokeWidth="2" />
        </svg>
      </div>

      <span className=" fs-1 ms-5  mt-4  " style={{ color: "black" }}>
        {mostCommentedSection}
      </span>
    </div>
  );
};

export default SentimentsCard;
