import React from 'react';
import './FetchingSpinner.css'; // Import your CSS styles

const FetchingSpinner = () => {
  return (
    <div className="spinner-container ">
          <div className="spinner"></div>
          <p className="spinner-text">Loading data...</p>


      </div>
  );
};

export default FetchingSpinner;
