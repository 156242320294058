import React from "react";
import polarity from "./../Assets/analytics.png";

const LikeDislikeCard = ({ data, chartID }) => {
  const support = data && data[3] && data[3].value;
  const oppose = data && data[4] && data[4].value;

  // Use support and oppose values to calculate the progress width
  const supportProgress = support + "%";
  const opposeProgress = oppose + "%";

  return (
    <div
      className="card card-header card-title p-5 rounded"
      style={{
        backgroundColor: "#a2c4db",
        height: "100%",
        width: chartID === "polarity" ? "100%" : "100%",
      }}
    >
      <div className="ps-5 mb-3 d-flex align-items-center">
        <img
          src={polarity}
          height="38px"
          width="38px"
          alt="logo"
          loading="lazy"
        />
        <div className="ps-3">
          <h1
            className="text-black py-0 ms-4"
            style={{ fontWeight: "10px", fontSize: "17px" }}
          >
            POLARITY
          </h1>
        </div>
      </div>
      <div className="text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 0.2"
          width="100%"
        >
          <line x1="0" y1="1" x2="36" y2="1" stroke="grey" strokeWidth="2" />
        </svg>
      </div>
      <div className="d-flex flex-column mt-3">
        {/* Support Progress Bar */}
        <div className="d-flex align-items-center rounded-pill mb-2">
          <div
            className="text-black fs-5"
            style={{ marginRight: "10px", width: "50%" }}
          >
            Support
          </div>
          <div className=" ms-auto text-black fs-5" >
            {support}%
          </div>
        </div>
        <div className="progress mb-4" style={{ width: "100%" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: supportProgress, backgroundColor: "#C6F404" }}
            aria-valuenow={support}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>

        {/* Oppose Progress Bar */}
        <div className="d-flex align-items-center rounded-pill mt-2">
          <div
            className="text-black fs-5"
            style={{ marginRight: "10px", width: "50%" }}
          >
            Oppose
          </div>
          <div className="ms-auto text-black fs-5">
            {oppose}%
          </div>
        </div>
        <div className="progress" style={{ width: "100%" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: opposeProgress, backgroundColor: "#004053" }}
            aria-valuenow={oppose}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LikeDislikeCard;
