import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiFile } from 'react-icons/fi';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { MdOutlineDashboard } from 'react-icons/md';
import clsx from 'clsx'; // Import clsx for conditional classes
import { useLocation } from 'react-router'; // Import useLocation
import { checkIsActive, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebariconMenuitem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation(); // Get current pathname
  const isActive = checkIsActive(pathname, to); // Check if the link is active
  const { config } = useLayout(); // Get layout config
  const { app } = config; // Get app configuration

  useEffect(() => {
    // Initialize Bootstrap tooltips
    // &('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
    <div className="menu-item">
      
        <Link
          className={clsx('menu-link without-sub', { active: isActive })}
          to={to}
          data-toggle="tooltip" // Add this attribute to enable Bootstrap tooltip
          title={title} // Set the tooltip text
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className="menu-icon">
              {title === 'Source Management' ? (
                <FiFile size={20} />
              ) : title === 'Dashboard' ? (
                <MdOutlineDashboard size={22} />
              ) : title === 'User Management' ? (
                <AiOutlineUsergroupAdd size={25} />
              ) : null}
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className="menu-title">{title}</span>
        </Link>
      
      {children}
    </div>
  );
}

export default SidebariconMenuitem;
